import React from 'react'

import * as s from '../../styles'
import { useTheme } from 'styled-components'
import { get, baseURLAnybankFees } from '../../../../services/api'

interface IProps {
  isFrom: string
}

const AnybankItauWelcomeStep: React.FC<IProps> = ({ isFrom }) => {
  const [taxes, setTaxes] = React.useState<any>('')
  const theme = useTheme()

  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_anybank_${isFrom}_creation_welcome_step`,
      })
    }
    getTaxes()
  }, [])

  const getTaxes = async () => {
    await get(`${baseURLAnybankFees}`).then((response: any) => {
      let taxes = []

      response.data.pix_any_bank_fees.forEach((e) => {
        e.can_publish
          ? (taxes[e.psp_provider] = e.fee_value)
          : (taxes[e.psp_provider] = null)
      })
      setTaxes(taxes)
    })
  }

  return (
    <>
      <s.Text type="headline" bold color="graytheme6">
        Receba pagamentos por Pix em qualquer instituição bancária através de
        nosso parceiro
      </s.Text>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="monitoroutline" />
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Processo simplificado</s.Text>
          <s.Text>
            O processo é feito diretamente no Painel, sem burocracia, e leva
            poucos minutos.
          </s.Text>
        </s.TextWrapper>
      </s.InfoBox>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="payments" />
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>{`Pague apenas por Pix pago`}</s.Text>
          <s.Text>
            {`Uma taxa fixa do valor pago será cobrada automaticamente, apenas pelas transações liquidadas, sem taxas extras.`}
          </s.Text>
        </s.TextWrapper>
      </s.InfoBox>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="bank" />
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Receba em qualquer banco</s.Text>
          <s.Text>
            Receba o total dos valores pagos no mesmo dia às 7:00 e às 19:00 em
            uma conta bancária de sua preferência. Caso a venda seja realizada
            após as 19:00, o valor será repassado no dia seguinte.
          </s.Text>
          <ul>
            <li>
              <s.Text>
                Essa conta de destino precisa ser uma conta Pessoa Jurídica de
                um CNPJ já cadastrado{' '}
                {theme.domain === 'ConexãoItaú'
                  ? 'no Conexão Itaú.'
                  : 'na Shipay.'}
              </s.Text>
            </li>
            <li>
              <s.Text></s.Text>
              <s.Text>
                Essa conta de destino deve pertencer a uma instituição
                financeira que faça parte do
              </s.Text>
              <s.ButtonLink
                href="https://www.bcb.gov.br/estabilidadefinanceira/participantespix"
                color="maincolor"
                target="_blank"
              >
                Sistema de Pagamentos Instantâneos do Banco Central.
              </s.ButtonLink>
            </li>
          </ul>
        </s.TextWrapper>
      </s.InfoBox>
      <s.InfoBox>
        <s.IconBox>
          <s.Icon fill="maincolor" name="pixiconoutline" />
        </s.IconBox>
        <s.TextWrapper>
          <s.Text bold>Conta de Passagem</s.Text>
          <s.Text>
            Para aproveitar essas vantagens e receber Pix em qualquer banco, é
            necessário abrir uma conta de passagem com o Banco Itaú.
          </s.Text>
          <br />
          <s.Text>
            A conta de passagem servirá como intermediadora para as transações,
            recebendo pagamentos por pix e transferindo-os para uma conta
            bancária de sua preferência no mesmo dia.
          </s.Text>
          <ul>
            <li>
              <s.Text>
                Sem custo de abertura ou manutenção da conta, a taxa fixa é
                apenas por transação, e é descontada automaticamente.
              </s.Text>
            </li>
            <li>
              <s.Text>
                Esta não é uma conta corrente, ela serve apenas como uma conta
                de portabilidade para os pagamentos.
              </s.Text>
            </li>
          </ul>
        </s.TextWrapper>
      </s.InfoBox>
    </>
  )
}

export default AnybankItauWelcomeStep
