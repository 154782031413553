import styled from 'styled-components'
import TextCmp from 'components/Text'


export const RecordDisplayList = styled.dl`
margin: 32px 0;
padding: 0 4px;
display: block;
`
export const RecordDisplayListGroup = styled.div`
display: block;
margin-bottom: 8px;
`
export const RecordDisplayListLine = styled.div`
display: flex;
`
export const RecordDisplayListTitle = styled.dt`
width: 32%;
`
export const RecordDisplayListDescription = styled.dd`
font-weight: normal;
flex-grow: 1;
`
export const Text = styled(TextCmp)`
  > svg {
    vertical-align: middle;
    display: inline-block;
  }
  > em {
    font-style: normal;
    color: ${({ theme }) => theme.colors.maincolor};
  }
`