import React from 'react'

import paitausuccess from 'assets/images/pa-itau-success2.png'

import * as s from './styles'

const Success: React.FC<{
  pspProvider: string
}> = ({ pspProvider }) => {
  return (
    <s.Sections data-testid="content-on-success">
      <s.SucessWrapper>
        <s.Text color="graytheme6" bold type="heading4">
          Cadastro efetuado com sucesso!
        </s.Text>
        <s.Text margin="32px 0 0 0" color="graytheme6" type="headline">
          {`Sua conta Pix ${pspProvider} foi cadastrada, agora é possível utilizá-la em sua loja para receber pagamentos via Pix!`}
        </s.Text>
        <img src={paitausuccess} />
      </s.SucessWrapper>
    </s.Sections>
  )
}

export default Success
