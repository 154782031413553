import { showToast } from 'components/Toast'
import { isHTML } from 'helpers'
import React from 'react'
import { get } from 'services/api'

interface Payer {
  numero_documento: string
  nome: string
  codigo_ispb: string
  nome_instituicao_financeira: string
  tipo_conta: string
  agencia: string
  conta: string
  digito_verificador: string
}

interface Debtor {
  cpf: string
  nome: string
  dados_conta: Object
}
export interface PixPayerReceipt {
  payer: Payer
  debtor: Debtor
}

interface UseGetPixPayerReceipt {
  pixPayerReceipt: PixPayerReceipt
  setPixPayerRecept: React.Dispatch<React.SetStateAction<any>>
  isLoading: boolean
  hasError: boolean
  hasSuccess: boolean
  loadPixPayerReceipt: (id: string) => void
}

const useGetPixPayerReceipt = (): UseGetPixPayerReceipt => {
  const [pixPayerReceipt, setPixPayerRecept] = React.useState<PixPayerReceipt>({
    payer: {
      numero_documento: '',
      nome: '',
      codigo_ispb: '',
      nome_instituicao_financeira: '',
      tipo_conta: '',
      agencia: '',
      conta: '',
      digito_verificador: '',
    },
    debtor: {
      cpf: '',
      nome: '',
      dados_conta: {},
    },
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [hasSuccess, setHasSuccess] = React.useState(false)

  const loadPixPayerReceipt = async (orderUuid: string) => {
    setIsLoading(true)
    await get(`/reconciliation/v1/pix/receipt/payer?order_uuid=${orderUuid}`)
      .then((response) => {
        setPixPayerRecept(response.data)
        setHasSuccess(true)
      })
      .catch((e) => {
        if (!isHTML(e.request.response) && e.request.status < 500) {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).message,
          })
        } else {
          showToast({
            type: 'error',
            message: `Erro no servidor, tente novamente mais tarde.`,
          })
        }
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    pixPayerReceipt,
    setPixPayerRecept,
    hasError,
    isLoading,
    loadPixPayerReceipt,
    hasSuccess,
  }
}

export default useGetPixPayerReceipt
