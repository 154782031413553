import {
  ActionInterface,
  Types,
  RegistrationStatusState,
  RegistrationStatusInterface,
} from './types'

const INITIAL_STATE: RegistrationStatusState = {
  registrationStatus: { count: -1, offset: -1, total: -1, data: [] },
  isLoading: false,
  hasError: false,
}

const registrationStatusReducer = (
  state = INITIAL_STATE,
  action: ActionInterface
): RegistrationStatusState => {
  switch (action.type) {
    case Types.GET_REGISTRATION_STATUS:
      return {
        ...state,
        registrationStatus: action.payload as RegistrationStatusInterface,
        isLoading: false,
        hasError: false,
      }
    case Types.GET_REGISTRATION_STATUS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    case Types.GET_REGISTRATION_STATUS_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }
    default:
      return state
  }
}

export default registrationStatusReducer
