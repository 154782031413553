import React from 'react'

import tbanksBank from 'assets/images/tbanks-bank.svg'
import conexaoItauBank from 'assets/images/conexao-itau-bank.svg'
import picpayBank from 'assets/images/picpay-itau-bank.svg'

import { useTheme } from 'styled-components'
import * as s from './styles'

interface IProps {
  step?: number
  isOpen: string
  handleClose: () => void
  isFrom: string
  children: React.ReactNode | React.ReactNode[]
}

const PixSimplesModalContainer: React.FC<IProps> = ({
  step,
  isOpen,
  handleClose,
  isFrom,
  children,
}) => {
  const theme = useTheme()

  const headerImage = () => {
    switch (isFrom) {
      case 'itau_anybank_v2':
        return conexaoItauBank
      case 'tbanks':
        return tbanksBank
      case 'picpay_anybank':
        return picpayBank
    }
  }

  const renderModalByPsp = () => {
    if (step > 0) {
      return (
        <s.Modal
          isHeaderComponent={true}
          modalSubtitle="Adicionar meio de pagamento"
          noChildrenPadding={false}
          step={step}
          isOpen={!!isOpen}
          handleClose={() => handleClose()}
        >
          <s.PixSimplesModalContainer provider={isFrom} step={step}>
            <s.Wrapper>{children}</s.Wrapper>
          </s.PixSimplesModalContainer>
        </s.Modal>
      )
    }
    return (
      <s.Modal
        isHeaderComponent={false}
        noChildrenPadding={true}
        isOpen={!!isOpen}
        handleClose={() => handleClose()}
      >
        <s.PixSimplesModalContainer provider={isFrom} step={step}>
          <s.PixAnyBankHeader provider={isFrom}>
            <img src={headerImage()} />
            <s.Text color="white" bold type="headline">
              Receba Pix em Qualquer Banco
            </s.Text>
            <s.CloseButton
              data-gtm-subname={`modal anybank`}
              data-gtm-name="botao fechar"
              data-gtm-type="click"
              data-gtm-clicktype="button"
              onClick={handleClose}
              name="closesquare"
              fill="white"
              width={28}
              height={28}
            />
          </s.PixAnyBankHeader>
          <s.Wrapper>{children}</s.Wrapper>
        </s.PixSimplesModalContainer>
      </s.Modal>
    )
  }

  return <>{renderModalByPsp()}</>
}

export default PixSimplesModalContainer
