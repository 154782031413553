import React from 'react'

import erroranybank from 'assets/images/anybank-register-error.png'
import erroridwallanybank from 'assets/images/anybank-idwall-validation-error.png'

import * as s from '../../styles'

const AnybankItauError: React.FC<{ errorOnProofOfLife: boolean }> = ({
  errorOnProofOfLife = false,
}) => {
  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_anybank_itau_anybank_v2_creation_error`,
      })
    }
  }, [])
  if (errorOnProofOfLife) {
    return (
      <s.AnybankItauErrorWrapper>
        <s.Text margin="32px 0 0 0" type="heading4" bold color="graytheme6">
          Sua identidade não foi validada
        </s.Text>
        <s.Text type="headline" color="graytheme6">
          As informações submetidas não foram aprovadas pelo sistema de
          validação.
        </s.Text>
        <s.Text type="headline" color="graytheme6">
          Em caso de dúvidas entre em contato com o suporte.
        </s.Text>
        <s.ImageErrorWrapper width={416} height={196}>
          <img
            src={erroridwallanybank}
            alt="Imagem de Erro Pix AnyBank IDWall"
          />
        </s.ImageErrorWrapper>
      </s.AnybankItauErrorWrapper>
    )
  }
  return (
    <s.AnybankItauErrorWrapper>
      <s.Text margin="32px 0 0 0" type="heading4" bold color="graytheme6">
        Erro no cadastro da conta
      </s.Text>
      <s.Text type="headline" color="graytheme6">
        Um erro aconteceu, tente novamente mais tarde. Caso o problema persista,
        entre em contato com o suporte.
      </s.Text>
      <s.ImageErrorWrapper>
        <img src={erroranybank} alt="Imagem de Erro Pix AnyBank" />
      </s.ImageErrorWrapper>
    </s.AnybankItauErrorWrapper>
  )
}

export default AnybankItauError
