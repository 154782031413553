import { showToast } from 'components/Toast'
import { useState } from 'react'

import geoService, { Address } from 'services/geo'

interface UseGetGeoAddress {
  loadGeoAddress: (zipcode: string) => Promise<void>
  isLoading: boolean
  address: Address
}

const handleErrorMessage = (messsage: string): string => {
  if (messsage) {
    switch (messsage) {
      case 'No Postal found':
        return 'CEP não encontrado'
      default:
        return 'Erro desconhecido'
    }
  }
  return 'Erro ao carregar o endereço'
}

export const useGetGeoAddress = (): UseGetGeoAddress => {
  const [address, setAddress] = useState<Address>({} as Address)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadGeoAddress = async (zipcode: string) => {
    setIsLoading(true)
    try {
      const response = await geoService.getAddressByZipcode(zipcode)
      setAddress(response.data)
    } catch (e) {
      showToast({
        type: 'error',
        message: handleErrorMessage(JSON.parse(e.request.response)?.detail),
      })
    }
    setIsLoading(false)
  }

  return { address, isLoading, loadGeoAddress }
}
