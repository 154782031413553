import React from 'react'

export const useErrors = () => {
  const [errors, setErrors] = React.useState<any[]>([])
  function dismissAll() {
    setErrors([])
  }
  function dismiss(err: any) {
    setErrors(errors.filter((e) => e != err))
  }
  function push (err: any) {
    setErrors([...errors, err])
  }
  return {
    errors,
    dismissAll,
    dismiss,
    push
  }
}