import React, { useMemo } from 'react'
import * as s from './styles'
import { useHistory } from 'react-router-dom'

interface IProps {
  testId?: string
  text?: string
  externalUrl?: string
  internalPath?: string
  handleClose?: () => void
}

const extractTextAndUrl = (input: string) => {
  // the input must be [text](url) format
  const hrefText = input.match(/\[([^\]]+)\]/)?.[1] || ''
  const hrefUrl = input.match(/\(([^)]+)\)/)?.[1] || ''

  return { hrefText, hrefUrl }
}

export const HelperMessage: React.FC<IProps> = ({
  testId,
  text,
  externalUrl,
  internalPath,
  handleClose,
}) => {
  const history = useHistory()
  const currentUrl = window.location.href

  const { hrefText, hrefUrl } = useMemo(
    () =>
      externalUrl
        ? extractTextAndUrl(externalUrl)
        : { hrefText: '', hrefUrl: '' },
    [externalUrl]
  )

  if (!externalUrl) {
    return (
      <>
        <s.Text data-testid={`${testId}-text`} type="tag">
          {text.trim().split(' ').slice(0, -1).join(' ')}{' '}
          <s.ExternalLink
            data-testid={`${testId}-link`}
            onClick={() => {
              if (currentUrl.includes(internalPath)) {
                return handleClose()
              }
              history.push(internalPath)
            }}
          >
            {text.trim().split(' ').slice(-1)}
          </s.ExternalLink>
        </s.Text>
      </>
    )
  }

  return (
    <>
      <s.Text data-testid={`${testId}-text`} margin="16px 0 0 0">
        {text}{' '}
        <s.ExternalLink
          data-testid={`${testId}-link`}
          href={hrefUrl}
          target="_blank"
        >
          {hrefText} ↗
        </s.ExternalLink>
      </s.Text>
    </>
  )
}
