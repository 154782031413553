import React from 'react'

import paitausuccess from 'assets/images/pa-itau-success2.png'
import paitaurejected from 'assets/images/pa-itau-rejected.png'

import * as s from '../../styles'
import { ItauAnyBankOnboardStatusType } from '..'

interface AnyBankItauEndOnboardModalProps {
  errorMessage?: any
  status: ItauAnyBankOnboardStatusType
  handleClose: () => void
}

const AnyBankItauEndOnboardModal: React.FC<AnyBankItauEndOnboardModalProps> = ({
  errorMessage = null,
  status,
  handleClose,
}) => {
  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_anybank_itau_anybank_v2_creation_end_onboard_${status}`,
      })
    }
  }, [])

  const errorMessageResolver = (errorMessage) => {
    if (!errorMessage || Object.keys(errorMessage).length === 0) {
      return 'Infelizmente não foi possível abrir sua conta bancária com o Itaú. É possível realizar o processo novamente com outro CNPJ ou outro banco parceiro.'
    }
    if (errorMessage.message) {
      try {
        const parsedMessage = JSON.parse(errorMessage.message)
        if (Array.isArray(parsedMessage)) {
          let str = ''
          parsedMessage.forEach(
            (error) => (str += `${error.complement}. ${error.details} \n`)
          )
          return str
        }
      } catch (error) {
        return errorMessage.message
      }
    }
    if (
      Array.isArray(errorMessage.errors) ||
      Array.isArray(errorMessage.error)
    ) {
      let str = ''
      errorMessage?.errors?.forEach((error) => (str += `${error.message} \n`))
      errorMessage?.error?.forEach((error) => (str += `${error.reason} \n`))
      return str
    } else {
      console.log('error message: ', errorMessage)
    }
  }

  if (status === 'rejected') {
    return (
      <s.AnybankItauSuccessWrapper>
        <s.Text margin="32px 0 0 0" type="headline" bold color="graytheme6">
          Pedido de abertura de conta negado
        </s.Text>
        <s.Text margin="16px 0 0 0" type="headline" color="graytheme6">
          {errorMessageResolver(errorMessage)}
        </s.Text>
        <s.ImageWrapper>
          <img src={paitaurejected} alt="Imagem de conta negada Pix AnyBank" />
        </s.ImageWrapper>
      </s.AnybankItauSuccessWrapper>
    )
  }
  if (status === 'finished') {
    return (
      <s.AnybankItauSuccessWrapper>
        <s.Text margin="32px 0 0 0" type="headline" bold color="graytheme6">
          Pedido de abertura de conta aprovado!
        </s.Text>

        <s.Text margin="16px 0 0 0" type="headline" color="graytheme6">
          Sua conta Pix Itaú foi cadastrada na Shipay, agora é possível
          utilizá-la em sua loja para receber pagamentos via Pix!
        </s.Text>
        <s.ImageWrapper>
          <img src={paitausuccess} alt="Imagem de Sucesso Pix AnyBank" />
        </s.ImageWrapper>
      </s.AnybankItauSuccessWrapper>
    )
  }

  if (status === 'in-progress' || status === 'pending-onboarding') {
    return (
      <s.AnybankItauSuccessWrapper>
        <s.Text margin="32px 0 0 0" type="headline" bold color="graytheme6">
          Conta em análise
        </s.Text>
        <s.InfoBox>
          <s.IconBox>
            <s.Icon fill="maincolor" name="clock" />
          </s.IconBox>
          <s.TextWrapper>
            <s.Text bold>Abertura de conta em análise</s.Text>
            <s.Text>
              Suas informações serão analisadas pelo Banco Itaú.
              <br />O resultado da analise leva até 3 dias úteis.
            </s.Text>
          </s.TextWrapper>
        </s.InfoBox>
        <s.InfoBox>
          <s.IconBox>
            <s.Icon fill="maincolor" name="eyeon" />
          </s.IconBox>
          <s.TextWrapper>
            <s.Text bold>Acompanhe seu cadastro</s.Text>
            <s.AnyBankSuccessPixText>
              Você pode acompanhar esse processo na aba{' '}
              <b onClick={() => handleClose()}>Pix↗</b> do Painel,
              <br />
              na seção <u>Contas em análise</u>.
            </s.AnyBankSuccessPixText>
          </s.TextWrapper>
        </s.InfoBox>
        <s.ImageWrapper width={343} height={160}>
          <img
            src={paitausuccess}
            alt="Imagem de Sucesso de Conta em Análise Pix AnyBank"
          />
        </s.ImageWrapper>
      </s.AnybankItauSuccessWrapper>
    )
  }

  return <></>
}

export default AnyBankItauEndOnboardModal
