import React, { SetStateAction } from 'react'
import { v4 as uuid } from 'uuid'

import useGetSystemWallets from 'hooks/useGetSystemWallets'
import useWalletsSettings from 'hooks/useWalletsSettings'

import Toast from 'components/Toast'
import ConfigWalletModal from '../../Wallets/ConfigWalletModal'
import WalletSettingsFormPixSimples from 'components/PixWalletForms/WalletSettingsFormPixSimples'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { checkUserClaim } from 'helpers/claims'

import originalIcon from 'assets/pix/original-card-icon.svg'
import tbanksIcon from 'assets/pix/tbanks-card-icon.svg'
import redeIcon from 'assets/pix/rede-card-icon.svg'
import redeIconWhite from 'assets/pix/rede-card-icon-white.svg'
import conexaoIcon from 'assets/pix/itau-icon.svg'

import { SystemWalletsInterface } from 'store/modules/systemWallets/types'

import {
  WalletSettingsFormMP,
  WalletSettingsFormPagSeg,
  WalletSettingsFormBradesco,
} from 'components/PixWalletForms'

import PixRegisteredWarningModal from './PixRegisteredWarningModal'

import * as s from './styles'
import { useTheme } from 'styled-components'
import { isConexaoItau } from 'helpers'
import { UserSessionInfo } from 'store/modules/auth/types'
import { WalletSettingsFormDefault } from 'components/PixWalletForms/PixWalletSettingsFormDefault'

interface IFilteredWallet extends SystemWalletsInterface {
  check?: boolean
}

interface IRegisterWallets {
  secondStepIsValid?: boolean
  setSecondStepIsValid?: React.Dispatch<SetStateAction<boolean>>
  nextStep?: React.Dispatch<React.SetStateAction<number>>
  previousStep?: React.Dispatch<React.SetStateAction<number>>
}

const RegisterWallets: React.FC<IRegisterWallets> = ({
  nextStep,
  previousStep,
}) => {
  const theme = useTheme()

  const { systemWallets, isLoading, hasError, loadSystemWallets } =
    useGetSystemWallets()

  const {
    walletSettings,
    isLoading: isLoadingWalletSettings,
    hasError: hasErrorWalletSettings,
    loadWalletsSettings,
  } = useWalletsSettings()

  const [pixWallets, setPixWallets] = React.useState([])
  const [eWallets, setEWallets] = React.useState([])
  const [pixWalletSettingsModal, setPixWalletSettingsModal] =
    React.useState<string>('')
  const [isConfigModalOpen, setIsConfigModalOpen] =
    React.useState<boolean>(false)
  const [selectedEWallet, setSelectedEWallet] = React.useState<string>('')
  const [
    isPixAlreadyRegisteredModalIsOpen,
    setIsPixAlreadyRegisteredModalIsOpen,
  ] = React.useState<boolean>(false)

  React.useEffect(() => {
    loadSystemWallets()
    loadWalletsSettings({
      limit: '500',
      offset: '0',
      filters: window.btoa(
        unescape(
          encodeURIComponent(
            JSON.stringify({ wallet_type: ['pix', 'c-wallet', 'e-wallet'] })
          )
        )
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (systemWallets.length) {
      let filteredPixWallets: IFilteredWallet[] = systemWallets.filter(
        (wallet) =>
          wallet.wallet_type === 'pix' && wallet.wallet_name !== 'pagseguro'
      )
      filteredPixWallets.forEach((pixWallet) => {
        pixWallet.check =
          walletSettings.data.filter(
            (walletSetting) =>
              walletSetting.wallet_name === 'pix' &&
              walletSetting.psp_provider === pixWallet.wallet_name
          ).length > 0
            ? true
            : false
      })
      while ((filteredPixWallets.length + 1) % 3 !== 0) {
        filteredPixWallets.push({
          active: false,
          id: null,
          single_certificate: null,
          single_credentials: null,
          wallet_friendly_name: null,
          wallet_logo: null,
          wallet_name: 'empty',
          wallet_type: 'pix',
          cashout_active: false,
        })
      }
      let filteredEWallets: IFilteredWallet[] = systemWallets.filter(
        (wallet) =>
          (wallet.wallet_type === 'e-wallet' ||
            wallet.wallet_type === 'c-wallet') &&
          wallet.wallet_name !== 'coinpayments'
      )
      filteredEWallets.forEach((eWallet) => {
        eWallet.check =
          walletSettings.data.filter(
            (walletSetting) => walletSetting.wallet_name === eWallet.wallet_name
          ).length > 0
            ? true
            : false
      })
      setEWallets([...filteredEWallets])
      setPixWallets([...filteredPixWallets])
    }
  }, [systemWallets, walletSettings])

  const pixWalletCards = React.useMemo(() => {
    const originalCardClaim = 'panel_pages_pix@card_original'
    const tbanksCardClaim = 'panel_pages_pix@card_tbanks'
    const itauCardClaim = 'panel_pages_pix@card_itau_anybank'

    const itauAnybankSystemWallet = pixWallets.find(
      (wallet) => wallet.wallet_name === 'itau_anybank_v2'
    )

    const originalAnybankSystemWallet = pixWallets.find(
      (wallet) => wallet.wallet_name === 'original'
    )
    const tbanksAnybankSystemWallet = pixWallets.find(
      (wallet) => wallet.wallet_name === 'tbanks'
    )

    const currentUserSession: UserSessionInfo = JSON.parse(
      getValueFromLocalStorage('currentUserSession')
    )
    return (
      <>
        {checkUserClaim(currentUserSession?.user_claims, itauCardClaim) ||
        false ? (
          ''
        ) : theme.domain === 'ConexãoItaú' ? (
          <s.RedeCard
            disabled={!itauAnybankSystemWallet?.active}
            onClick={() => {
              if (itauAnybankSystemWallet?.active) {
                setPixWalletSettingsModal('itau_anybank_v2')
              }
            }}
          >
            <img
              src={itauAnybankSystemWallet?.active ? redeIcon : redeIconWhite}
              alt="imagem pix Conexao Rede"
            />
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" color="white" />
                cobrança
              </s.TextCardFooter>
              <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
            </s.FooterWrapper>
          </s.RedeCard>
        ) : (
          <s.ItauCard
            disabled={!itauAnybankSystemWallet?.active}
            onClick={() => {
              if (itauAnybankSystemWallet?.active) {
                setPixWalletSettingsModal('itau_anybank_v2')
              }
            }}
          >
            <img src={conexaoIcon} alt="imagem pix Conexao" />
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" color="white" />
                cobrança
              </s.TextCardFooter>
              <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
            </s.FooterWrapper>
          </s.ItauCard>
        )}
        {checkUserClaim(currentUserSession?.user_claims, originalCardClaim) ? (
          ''
        ) : theme.domain === 'Shipay' ? (
          <s.OriginalCard
            disabled={!originalAnybankSystemWallet?.active}
            onClick={() => {
              if (originalAnybankSystemWallet?.active) {
                if (pixWallets.some((wallet) => wallet.check === true)) {
                  setIsPixAlreadyRegisteredModalIsOpen(true)
                } else {
                  setPixWalletSettingsModal('original')
                }
              }
            }}
          >
            {pixWallets.some((wallet) => wallet.check === true) ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img src={originalIcon} alt="imagem pix Original" />
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" color="white" />
                cobrança
              </s.TextCardFooter>
              <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
            </s.FooterWrapper>
          </s.OriginalCard>
        ) : (
          <s.RedeCard
            disabled={!itauAnybankSystemWallet?.active}
            onClick={() => {
              if (originalAnybankSystemWallet?.active) {
                if (pixWallets.some((wallet) => wallet.check === true)) {
                  setIsPixAlreadyRegisteredModalIsOpen(true)
                } else {
                  setPixWalletSettingsModal('original')
                }
              }
            }}
          >
            {pixWallets.some((wallet) => wallet.check === true) ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img
              src={
                originalAnybankSystemWallet?.active ? redeIcon : redeIconWhite
              }
              alt="imagem pix Rede"
            />
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" color="white" />
                cobrança
              </s.TextCardFooter>
              <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
            </s.FooterWrapper>
          </s.RedeCard>
        )}

        {checkUserClaim(currentUserSession?.user_claims, tbanksCardClaim) ? (
          ''
        ) : isConexaoItau() ? (
          <s.RedeCard
            disabled={!tbanksAnybankSystemWallet?.active}
            onClick={() => {
              if (tbanksAnybankSystemWallet?.active) {
                if (pixWallets.some((wallet) => wallet.check === true)) {
                  setIsPixAlreadyRegisteredModalIsOpen(true)
                } else {
                  setPixWalletSettingsModal('tbanks')
                }
              }
            }}
          >
            {pixWallets.some((wallet) => wallet.check === true) ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img
              src={tbanksAnybankSystemWallet?.active ? redeIcon : redeIconWhite}
              alt="imagem pix TBankS"
            />
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" color="white" />
                cobrança
              </s.TextCardFooter>
              <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
            </s.FooterWrapper>
          </s.RedeCard>
        ) : (
          <s.TBanksCard
            data-testid="walletcard-pix-tbanks"
            disabled={!tbanksAnybankSystemWallet?.active}
            onClick={() => {
              if (tbanksAnybankSystemWallet?.active) {
                if (pixWallets.some((wallet) => wallet.check === true)) {
                  setIsPixAlreadyRegisteredModalIsOpen(true)
                } else {
                  setPixWalletSettingsModal('tbanks')
                }
              }
            }}
          >
            {pixWallets.some((wallet) => wallet.check === true) ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img src={tbanksIcon} alt="imagem pix TBankS" />
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" color="white" />
                cobrança
                <s.Icon name="cashoutarrowup" color="white" />
                envio
              </s.TextCardFooter>
              <s.TextCardFooter>receba em qualquer banco</s.TextCardFooter>
            </s.FooterWrapper>
          </s.TBanksCard>
        )}

        {pixWallets.map((wallet) => {
          return (
            <React.Fragment key={wallet.id}>
              <s.WalletCard
                clickCallBack={() => {
                  if (pixWallets.some((wallet) => wallet.check === true)) {
                    setIsPixAlreadyRegisteredModalIsOpen(true)
                  } else {
                    setPixWalletSettingsModal(wallet.wallet_name)
                  }
                }}
                key={uuid()}
                name={wallet.wallet_name}
                color={wallet.wallet_name}
                disabled={!wallet.active}
                check={pixWallets.some((wallet) => wallet.check === true)}
                type="pix"
                height={128}
                width={220}
              />
            </React.Fragment>
          )
        })}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pixWallets, setPixWalletSettingsModal])

  const eWalletCards = React.useMemo(
    () => (
      <>
        {eWallets
          .sort((x, y) => {
            if (x.wallet_name > y.wallet_name) return 1
            if (x.wallet_name < y.wallet_name) return -1
            return 0
          })
          .map((wallet) => {
            return (
              <s.WalletCard
                key={uuid()}
                name={wallet.wallet_name}
                color={wallet.wallet_name}
                disabled={!wallet.active}
                check={wallet.check}
                type="ewallet"
                clickCallBack={() => {
                  if (!wallet.check) {
                    setIsConfigModalOpen(true)
                    setSelectedEWallet(wallet.wallet_name)
                  }
                }}
                height={128}
                width={140}
              />
            )
          })}
      </>
    ),
    [eWallets, setIsConfigModalOpen, setSelectedEWallet]
  )

  const renderCards = (type) => {
    if (hasError || hasErrorWalletSettings) {
      return (
        <s.Text color="maincolor" data-testid="error-text" type="heading3">
          Erro na API, tente novamente mais tarde
        </s.Text>
      )
    }
    if (isLoading || isLoadingWalletSettings) {
      return (
        <s.LoadingWrapper>
          <s.Text
            data-testid="loading-text-wallets"
            color="maincolor"
            type="heading3"
          >
            Carregando...
          </s.Text>
          <s.Loading
            type="spinner"
            data-testid="loading-spinner1"
            color="maincolor"
          />
        </s.LoadingWrapper>
      )
    }
    if (pixWallets.length && type === 'pix') {
      return <>{pixWalletCards}</>
    }

    if (eWallets.length && type === 'ewallet') {
      return <>{eWalletCards}</>
    }
  }

  const renderPixWalletSettingsModal = (pspProvider: string) => {
    if (
      pspProvider.match(
        /^(bb|bnb|itau|santander|bs2|sicredi|portobank|bradesco)$/
      )
    ) {
      const walletInfo = pixWallets
        .filter((wallet) => wallet.wallet_name === pspProvider)
        .reduce(
          (acc, item) => ({
            ...acc,
            ...item,
          }),
          {}
        ) as Partial<SystemWalletsInterface>
      return (
        <WalletSettingsFormDefault
          isOpen={pixWalletSettingsModal}
          handleClose={() => setPixWalletSettingsModal('')}
          wallet={walletInfo}
        />
      )
    }
    if (pspProvider === 'itau_anybank_v2') {
      return (
        <WalletSettingsFormPixSimples
          isOpen={pixWalletSettingsModal}
          isFrom="itau_anybank_v2"
          handleClose={() => setPixWalletSettingsModal('')}
        />
      )
    }
    if (pspProvider === 'mercadopago') {
      return (
        <WalletSettingsFormMP
          isOpen={pixWalletSettingsModal}
          handleClose={() => setPixWalletSettingsModal('')}
        />
      )
    }
    if (pspProvider === 'pagseguro') {
      return (
        <WalletSettingsFormPagSeg
          isOpen={pixWalletSettingsModal}
          handleClose={() => setPixWalletSettingsModal('')}
        />
      )
    }
    if (pspProvider === 'bradesco') {
      return (
        <WalletSettingsFormBradesco
          isOpen={pixWalletSettingsModal}
          handleClose={() => setPixWalletSettingsModal('')}
        />
      )
    }
    if (pspProvider === 'original') {
      return (
        <WalletSettingsFormPixSimples
          isOpen={pixWalletSettingsModal}
          handleClose={() => setPixWalletSettingsModal('')}
          isFrom="original"
        />
      )
    }

    if (pspProvider === 'tbanks') {
      return (
        <WalletSettingsFormPixSimples
          isOpen={pixWalletSettingsModal}
          isFrom="tbanks"
          handleClose={() => setPixWalletSettingsModal('')}
        />
      )
    }
  }

  return (
    <s.Background>
      <Toast />
      <s.BackgroundFilter>
        <PixRegisteredWarningModal
          isOpen={isPixAlreadyRegisteredModalIsOpen}
          handleClose={() => setIsPixAlreadyRegisteredModalIsOpen(false)}
        />
        <ConfigWalletModal
          isModalOpen={isConfigModalOpen}
          handleClose={() => setIsConfigModalOpen(!isConfigModalOpen)}
          wallet={selectedEWallet}
        />
        <s.Container>
          {renderPixWalletSettingsModal(pixWalletSettingsModal)}
          <s.TextWrapper>
            <s.Text bold type="heading3" color="graytheme6">
              Cadastre as Contas Digitais da sua primeira loja
            </s.Text>
            <s.Text color="maincolor">
              Clique na opção desejada e siga os passos para o cadastro.
              Cadastre ao menos uma conta para prosseguir.
            </s.Text>
          </s.TextWrapper>
          <s.Card width={1056} height={555}>
            <s.Header>
              <s.Icon name="pixlogowithlettering" width={182} height={64} />
            </s.Header>
            <s.Bottom>{renderCards('pix')}</s.Bottom>
          </s.Card>
          <s.Card width={1056} height={320}>
            <s.Header>
              <s.Text type="heading2" bold color="graytheme6">
                Carteiras Digitais
              </s.Text>
            </s.Header>
            <s.Bottom>{renderCards('ewallet')}</s.Bottom>
          </s.Card>
          <s.Footer>
            <s.Button color="transparent" onClick={() => previousStep(1)}>
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="maincolor">Anterior</s.Text>
            </s.Button>
            <s.Button onClick={() => nextStep(3)}>
              Próximo
              <s.Icon name="arrowright" fill="lightmaincolor3" />
            </s.Button>
          </s.Footer>
        </s.Container>
      </s.BackgroundFilter>
    </s.Background>
  )
}

export default RegisterWallets
