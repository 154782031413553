import React from 'react'
import { v4 as uuid } from 'uuid'
import * as s from '../styles'
import BeautifyJson from 'components/BeautifyJson'
import useGetCashoutPayment from 'hooks/useGetCashoutPayment'
import useGetCashoutTransactions from 'hooks/useGetCashoutTransactions'
import { formatDateTime } from 'helpers/date'
import { useTranslation } from 'react-i18next'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  detailsId: string
}

const SummaryDetailsModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  detailsId,
}) => {
  const { t } = useTranslation()

  const {
    cashoutPayment,
    cashoutPaymentError,
    cashoutPaymentIsLoading,
    getCashoutPayment,
  } = useGetCashoutPayment()

  const [offset, setOffset] = React.useState('0')
  const {
    transactions,
    transactionsIsLoading,
    transactionsHasError,
    loadTransactions,
  } = useGetCashoutTransactions()

  React.useEffect(() => {
    if (isOpen && detailsId) {
      loadTransactions(detailsId, { limit: '20', offset: offset })

      if (window.hj) {
        window.hj('stateChange', 'modal_summary_detail_cashout')
      }

      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_path: `modal_summary_detail_cashout`,
        })
      }
    }
  }, [isOpen, offset])

  React.useEffect(() => {
    getCashoutPayment(detailsId)
  }, [])

  const renderTransactionLogs = () => {
    if (transactionsIsLoading) {
      return (
        <>
          <s.Text color="maincolor" type="headline">
            Carregando...
          </s.Text>
          <s.Loading type="spinner" color="maincolor" />
        </>
      )
    }

    if (transactionsHasError) {
      return <></>
    }

    return (
      <>
        {transactions.count > 0 ? (
          <s.ModalTransitionLog>
            <s.ModalTransitionLogHeader>
              <s.Text type="headline" bold color="darkmaincolor1">
                Log de Transações
              </s.Text>
            </s.ModalTransitionLogHeader>
            <s.ModalTransitionWrapper>
              {transactions.data.map((transaction) => {
                return (
                  <React.Fragment key={uuid()}>
                    <s.ModalTransitionFormatter>
                      <BeautifyJson
                        header={`${transaction.event_type} — ${formatDateTime(
                          transaction.created_at
                        )}`}
                        headerInfo={transaction.info}
                        json={transaction.message}
                      />
                    </s.ModalTransitionFormatter>
                  </React.Fragment>
                )
              })}
            </s.ModalTransitionWrapper>
          </s.ModalTransitionLog>
        ) : null}
      </>
    )
  }

  const renderModal = () => {
    return (
      <>
        <s.ModalDetails>
          <s.CashoutModalTransactionDetailsLabel>
            <s.Text bold color="graytheme6">
              Status
            </s.Text>
            <s.Text bold color="graytheme6">
              Data de atualização
            </s.Text>
            <s.Text bold color="graytheme6">
              Conta Digital
            </s.Text>
            <s.Text bold color="graytheme6">
              ID externo do pedido
            </s.Text>
            <s.Text bold color="graytheme6">
              Valor solicitado
            </s.Text>
            <s.Text bold color="graytheme6">
              Data da criação
            </s.Text>
            <s.Text bold color="graytheme6">
              ID do pedido
            </s.Text>
            <s.Text bold color="graytheme6">
              Valor pago
            </s.Text>
            <s.Text bold color="graytheme6">
              Data do pagamento
            </s.Text>
            <s.Text bold color="graytheme6">
              ID do pagamento
            </s.Text>
          </s.CashoutModalTransactionDetailsLabel>
          <s.CashoutModalTransactionDetailsValue>
            {cashoutPaymentIsLoading || cashoutPaymentError ? (
              <></>
            ) : (
              <>
                <s.Text color="graytheme6">{t(cashoutPayment.status)}</s.Text>
                <s.Text color="graytheme6">
                  {formatDateTime(cashoutPayment.updated_at)}
                </s.Text>
                <s.Text color="graytheme6">
                  {cashoutPayment.system_wallet_name}
                </s.Text>
                <s.Text color="graytheme6">{cashoutPayment.external_id}</s.Text>
                <s.Text color="graytheme6">{cashoutPayment.amount}</s.Text>
                <s.Text color="graytheme6">
                  {formatDateTime(cashoutPayment.created_at)}
                </s.Text>
                <s.Text color="graytheme6">{cashoutPayment.payment_id}</s.Text>
                <s.Text color="graytheme6">
                  {cashoutPayment.status === 'finished' ? (
                    cashoutPayment.amount
                  ) : (
                    <>&nbsp;</>
                  )}
                </s.Text>
                <s.Text color="graytheme6">
                  {cashoutPayment.payment_date ?? <>&nbsp;</>}
                </s.Text>
                <s.Text color="graytheme6">
                  {cashoutPayment.psp_payment_id ?? <>&nbsp;</>}
                </s.Text>
              </>
            )}
          </s.CashoutModalTransactionDetailsValue>
        </s.ModalDetails>
        <s.DottedLine />
        {renderTransactionLogs()}
        {transactions.count > 0 ? (
          <s.ModalPagination
            count={20}
            offset={offset}
            total={transactions.count}
            handleChange={(offset) => {
              setOffset(offset)
            }}
          />
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      <s.Modal
        noChildrenPadding
        modalSubtitle="Detalhes do Envio"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <s.ModalContainer width={632} height={484}>
          {renderModal()}
        </s.ModalContainer>
      </s.Modal>
    </>
  )
}

export default SummaryDetailsModal
