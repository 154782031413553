import React from 'react'

import RecordDisplay from 'components/RecordDisplay'
import * as s from '../styles'
import { useApiGet } from 'hooks/useApiCall'
import useLocale from 'hooks/useLocale'
import { bffv2URLReconciliation } from 'services/api'
import { useGraphQLCall, GET_SYSTEM_WALLET } from 'graphql'
import type { GetSystemWalletData, GetSystemWalletDataItem as SystemWallet, GetSystemWalletVariables } from 'graphql'


const reconciliationTypeMapping = {
  tbanks: 'RECONCILIATION_PIX_TBANKS',
  itau_anybank: 'RECONCILIATION_PIX_ITAU_ANY_BANK',
  itau_anybank_v2: 'RECONCILIATION_PIX_ITAU_ANY_BANK_V2',
  picpay_anybank: 'RECONCILIATION_PIX_PICPAY_ANY_BANK',
  original: 'RECONCILIATION_PIX_ORIGINAL'
}

export class ReconciliationIntention {
  wallet_setting_id?: string
  active: boolean
  id: number
  accepted_at: Date
  type: string
  store_id: number
}

export class ReconciliationOrder {
  order_id: string
  order_status: string
  pix_txid: string
  order_ref: string
  order_type: string
  customer_id: string
  store_cnpj_cpf: string
  wallet: string
  pix_psp: string
  payment_date: string
  wallet_payment_id: string
  total_order: number
  fee: number
  transfer_amount?: number
  transfer_date?: string
  transfer_e2eid?: string
  reconciliation_status: 'opened' | 'closed'
  executed_at: string
}

export enum ReconciliationAnyBankOrderState {
  ReconciliationUnavailable, // For psps or wallets that do not have reconciliation
  Loading, // LoadingState
  NoIntention, // Store does not have reconciliation intention or it is inactive
  NoContent,  // Final. Api COULD NOT fetch reconciliation order
  ReconciliationOrderOpened, // Final. Api COULD fetch reconciliation order and it is opened (Not Reconciliated yet)
  ReconciliationOrderClosed, // Final. Api COULD fetch reconciliation order and it is closed (Reconciliated)
  Error, // Final. Any error that occurred in the processing of the request
}

interface ReconciliationOrderProps {
  pixPsp: string
  storePosId: string
  orderUUID: string
  paymentDate?: Date
  isShipayPagador?: boolean
}

interface ReconciliationOrderContextType {
  reconciliationOrder?: ReconciliationOrder,
  reconciliationIntention?: ReconciliationIntention,
  reconciliationAvailable: boolean,
  loading: boolean,
  errors: any[],
  state: ReconciliationAnyBankOrderState,
  systemWallet?: SystemWallet
  dismissAll: () => void,
  dismiss: (error: any) => void
}


const reconciliationOrderContext = React.createContext<ReconciliationOrderContextType & ReconciliationOrderProps>({
  reconciliationAvailable: false,
  loading: false,
  errors: [],
  state: ReconciliationAnyBankOrderState.NoIntention,
  dismissAll: () => { },
  dismiss: (_error: any) => { },
  pixPsp: '',
  storePosId: '',
  orderUUID: ''
})

export const ReconciliationOrderProvider: React.FC<React.PropsWithChildren<ReconciliationOrderProps>> = ({
  pixPsp, storePosId, orderUUID, isShipayPagador, paymentDate, children
}) => {
  const reconciliationAvailable = React.useMemo(() => isShipayPagador || (pixPsp in reconciliationTypeMapping), [pixPsp, isShipayPagador])
  const reconciliationType = React.useMemo(() => isShipayPagador ? undefined : reconciliationTypeMapping[pixPsp], [pixPsp, isShipayPagador])
  const reconciliationIntentionApi = useApiGet<ReconciliationIntention>(`${bffv2URLReconciliation}/reconciliation/intention`, {
    params: {
      type: reconciliationType,
      store_pos_id: storePosId
    }
  }, [reconciliationType, storePosId])

  const reconciliationOrderApi = useApiGet<ReconciliationOrder>(`${bffv2URLReconciliation}/reconciliation/summary`, {
    params: {
      order_id: orderUUID,
      store_pos_id: storePosId,
      payment_date: paymentDate.toISOString()
    }
  })

  const systemWalletGraphql = useGraphQLCall<GetSystemWalletData, GetSystemWalletVariables>({
    variables: {
      walletName: isShipayPagador ? ['shipay-pagador'] : [pixPsp],
      active: true,
      withReconciliationSchedule: true
    },
    query: GET_SYSTEM_WALLET,
    operationName: 'SystemWalletsQuery'
  }, [pixPsp, isShipayPagador])

  const errors = React.useMemo(() => [...reconciliationIntentionApi.errors, ...reconciliationOrderApi.errors], [reconciliationIntentionApi.errors, reconciliationOrderApi.errors])
  const loading = React.useMemo(() => reconciliationIntentionApi.loading || reconciliationOrderApi.loading, [reconciliationIntentionApi.loading, reconciliationOrderApi.loading])
  const systemWallet = React.useMemo(() => systemWalletGraphql.data?.systemWallets?.[0], [systemWalletGraphql.data])

  const dismiss = React.useCallback((error: any) => {
    reconciliationIntentionApi.dismiss(error)
    reconciliationOrderApi.dismiss(error)
  }, [reconciliationIntentionApi.dismiss, reconciliationOrderApi.dismiss])

  const dismissAll = React.useCallback(() => {
    reconciliationIntentionApi.dismissAll()
    reconciliationOrderApi.dismissAll()
  }, [reconciliationIntentionApi.dismissAll, reconciliationOrderApi.dismissAll])

  const state = React.useMemo(() => {
    if (!reconciliationAvailable) return ReconciliationAnyBankOrderState.ReconciliationUnavailable
    if (loading) return ReconciliationAnyBankOrderState.Loading
    if (errors.length > 0) return ReconciliationAnyBankOrderState.Error
    if (!reconciliationIntentionApi.data) return ReconciliationAnyBankOrderState.NoIntention
    if (!reconciliationOrderApi.data || !reconciliationOrderApi.data.executed_at) return ReconciliationAnyBankOrderState.NoContent
    if (reconciliationOrderApi.data?.reconciliation_status == 'opened') return ReconciliationAnyBankOrderState.ReconciliationOrderOpened
    if (reconciliationOrderApi.data?.reconciliation_status == 'closed') return ReconciliationAnyBankOrderState.ReconciliationOrderClosed
  }, [loading, reconciliationAvailable, reconciliationIntentionApi.data, reconciliationOrderApi.data, errors])

  React.useEffect(() => {
    if (reconciliationAvailable) {
      reconciliationIntentionApi.apiCall()
    }
  }, [reconciliationAvailable])

  React.useEffect(() => {
    if (reconciliationIntentionApi.data) {
      reconciliationOrderApi.apiCall()
    }
  }, [reconciliationIntentionApi.data])

  React.useEffect(() => {
    if (systemWalletGraphql.data) return
    switch (state) {
      case ReconciliationAnyBankOrderState.NoContent:
      case ReconciliationAnyBankOrderState.ReconciliationOrderOpened:
        systemWalletGraphql.apiCall()
    }
  }, [state])

  return <reconciliationOrderContext.Provider value={{
    reconciliationOrder: reconciliationOrderApi.data,
    reconciliationIntention: reconciliationIntentionApi.data,
    reconciliationAvailable,
    loading,
    errors,
    state,
    dismissAll,
    dismiss,
    pixPsp,
    storePosId,
    orderUUID,
    isShipayPagador,
    systemWallet
  }}>
    {children}
  </reconciliationOrderContext.Provider>
}

const ReconciliationSectionWarning: React.FC<{
  noContent?: boolean,
  nextExpectedExcecution?: string
}> = ({ noContent = false, nextExpectedExcecution: nextExpectedExcecutionProp }) => {
  const locale = useLocale('pt-Br')
  const nextExpectedExcecution = React.useMemo(() => {
    return nextExpectedExcecutionProp ? (new Date(nextExpectedExcecutionProp)).toLocaleString(locale) : undefined
  }, [nextExpectedExcecutionProp])
  return <s.MessageBoxOutline borderColor='graytheme1' margin='8px' role='alert'>
    {
      noContent &&
      <s.Text><s.Icon name='warning' width={'15px'} />No momento não há informaçãs sobre o repasse desse pedido.</s.Text>
    }
    {
      nextExpectedExcecution
        ?
        <s.Text data-testid='next-excecution'>
          <s.Icon name='clock' width={'15px'} />
          A Próxima atualização deve acontecer às {nextExpectedExcecution}.
        </s.Text>
        :
        <s.Text>Tente novamente mais tarde.</s.Text>
    }
  </s.MessageBoxOutline>
}

export const ReconciliationSectionComponent: React.FC<{
  pixPsp: string,
  state: ReconciliationAnyBankOrderState,
  reconciliationOrder?: ReconciliationOrder,
  systemWallet?: SystemWallet
}> = ({ state, reconciliationOrder, systemWallet }) => {
  switch (state) {
    case ReconciliationAnyBankOrderState.Error:
      return (<s.MessageBoxOutline borderColor='graytheme1' margin='8px' role='alert'>
        <s.Text >
          <s.Icon name='warning' width={'15px'} /> Não foi possível consultar informações sobre o repasse, tente novamente mais tarde.
        </s.Text>
        <s.Text>
          Em caso de dúvida entre em contato com o administrador.
        </s.Text>
      </s.MessageBoxOutline>
      )
    case ReconciliationAnyBankOrderState.NoContent:
      return <ReconciliationSectionWarning noContent nextExpectedExcecution={systemWallet?.nextReconciliationExcecution} />
    case ReconciliationAnyBankOrderState.ReconciliationOrderOpened:
      return <div role='region'>
        <RecordDisplay
          data={[{
            'Status do Repasse': 'Em repasse',
            'Última Atualização': new Date(reconciliationOrder?.executed_at).toLocaleString()
          }]}
        />
        <ReconciliationSectionWarning nextExpectedExcecution={systemWallet?.nextReconciliationExcecution} />
      </div>
    case ReconciliationAnyBankOrderState.ReconciliationOrderClosed:
      return <div role='region'>
        <RecordDisplay
          data={[
            {
              'Status do Repasse': 'Depositado',
              'Última Atualização': new Date(reconciliationOrder?.executed_at).toLocaleString()
            },
            { 'Tarifa': reconciliationOrder.fee.toFixed(2) },
            {
              'Valor depositado': reconciliationOrder.transfer_amount.toFixed(2),
              'Data do deposito': new Date(reconciliationOrder.transfer_date).toLocaleString(),
              'ID do deposito': reconciliationOrder.transfer_e2eid
            }
          ]}
        />
        <s.DottedLine />
      </div>
    case ReconciliationAnyBankOrderState.Loading:
    case ReconciliationAnyBankOrderState.NoIntention:
    case ReconciliationAnyBankOrderState.ReconciliationUnavailable:
    default:
      return null
  }
}

export const ReconciliationSection: React.FC<{}> = () => {
  return <reconciliationOrderContext.Consumer>
    {
      (context) => <ReconciliationSectionComponent
        pixPsp={context.pixPsp}
        state={context.state}
        reconciliationOrder={context.reconciliationOrder}
        systemWallet={context.systemWallet}
      />
    }
  </reconciliationOrderContext.Consumer>
}

