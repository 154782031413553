import React from 'react'
import { useLocation } from 'react-router-dom'

export default function useHotjarTracking() {
  const location = useLocation()

  React.useEffect(() => {
    if (location !== undefined) {
      if (window.hj) {
        window.hj('stateChange', location.pathname)
      }
    }
  }, [location])
}
