import styled, { css, keyframes } from 'styled-components'

import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import IconExporter from 'components/IconExporter'
import CloseIcon from 'components/IconExporter'
import ButtonLinkCmp from 'components/Button/ButtonLink'
import ModalCmp from 'components/Modal'
import SearchSelectCmp from 'components/Inputs/SearchSelect'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import { ModalContainer, ChildrenWrapper } from 'components/Modal/styles'
import LoadingCmp from 'components/Loading'
import CheckboxCmp from 'components/Inputs/Checkbox'

const handleHeaderColor = (provider: string) => {
  switch (provider) {
    case 'original':
      return css`
        background: linear-gradient(180deg, #3cdd6f 0%, #18ad47 100%);
      `
    case 'itau_anybank_v2':
      return css`
        background: linear-gradient(180deg, #f98f53 0%, #e55f13 100%);
      `
    case 'tbanks':
      return css`
        background: linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
      `
    case 'picpay_anybank':
      return css`
        background: linear-gradient(180deg, #60a969 0%, #3f9349 100%);
      `
  }
}

export const PixAnyBankHeader = styled.div<{ provider: string }>`
  height: 128px;
  ${({ provider }) => handleHeaderColor(provider)}
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  justify-content: space-evenly;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  margin: 16px 16px;
  flex-grow: 1;
  overflow: auto;
`

export const WelcomeWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
  padding-right: 16px;
`

export const Button = styled(ButtonCmp)`
  > span {
    text-transform: none;
  }
`

export const TokenButton = styled(ButtonCmp)`
  svg {
    margin-right: 8px;
  }
  > span {
    text-transform: none;
  }
`

export const CheckBoxContainer = styled.div`
  height: 250px;

  margin-top: 32px;
  > p {
    margin-bottom: 16px;
  }
`

export const CheckboxWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > div {
    :first-child {
      width: min-content;
    }
  }

  > p > a {
    display: inline;
    margin-left: 4px;
  }
`

export const Checkbox = styled(CheckboxCmp)``

interface LineProps {
  margin?: string
}
export const Line = styled.div<LineProps>`
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  margin: ${({ margin }) => margin};
`

export const ButtonAnybank = styled(ButtonCmp)`
  position: absolute;
  height: 54px;
  width: 250px;

  > span {
    text-transform: none;
    font-size: 14px;
  }
`

export const PreviousNextButtonContainer = styled.div<{ margin?: string }>`
  min-height: 72px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: stretch;
  align-content: space-between;
  justify-content: space-between;

  ::before {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const ButtonHiddenContainer = styled.div<{ margin?: string }>`
  margin-top: auto;
  ::before {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const PreviousNextButtonWrapper = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: ${({ margin }) => margin};

  :has(:only-child) {
    justify-content: flex-end;
  }
`

export const LeftButtonAnybank = styled(ButtonCmp)`
  margin-top: 32px;
  height: 40px;
  width: 250px;
`

export const RightButtonAnybank = styled(ButtonCmp)`
  margin-top: 32px;
  height: 40px;
  width: 250px;
`

interface PixSimplesModalContainerProps {
  provider?: string
  step?: number
}

function handleModalContainerWidth(provider: string, step: number): number {
  if (step > 0) {
    return 622
  }
  return 890
}

function handleModalContainerHeight(provider: string, step: number): string {
  if (step > 0) {
    return '75vh'
  }
  return '95vh'
}

export const PixSimplesModalContainer = styled.div<PixSimplesModalContainerProps>`
  width: ${({ provider, step }) =>
    `${handleModalContainerWidth(provider, step)}px`};
  height: ${({ provider, step }) =>
    `${handleModalContainerHeight(provider, step)}`};
  display: flex;
  flex-direction: column;
`

export const InfoBox = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 24px;
`

export const IconBox = styled.div`
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 0;
  > ul {
    color: ${({ theme }) => theme.colors.graytheme6};
    margin: 16px 0 0 24px;

    > li {
      margin-bottom: 16px;
    }
  }
`

export const Loading = styled(LoadingCmp)``

export const LoadingWrapper = styled.div`
  margin-top: 64px;
  overflow-y: hidden;
`

export const LoadingContainerModal = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const LoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 114px;
  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media screen and (max-width: 1440px) {
    margin-top: 84px;
  }
`

export const Text = styled(TextCmp)``

export const Icon = styled(IconExporter)``

export const resendTokenCounter = styled.span`
  display: inline-block;
  width: 12px;
  margin-right: 4px;
`

export const ButtonLink = styled(ButtonLinkCmp)`
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 19px;
  /* margin-left: 32px; */
`

export const Modal = styled(ModalCmp)<{ step?: number }>`
  ${({ step }) =>
    step > 0
      ? css`
          header {
            background: ${({ theme }) => theme.colors.white};
            border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
            padding: 32px;
            h5 {
              color: ${({ theme }) => theme.colors.graytheme6};
            }
          }

          ${ModalContainer} {
            padding: 0px;
          }

          ${ChildrenWrapper} {
            padding: 0px;
            overflow-y: hidden;

            ::-webkit-scrollbar-track {
              margin-top: 32px;
              margin-bottom: 32px;
            }
          }
        `
      : css`
          ${ChildrenWrapper} {
            overflow: hidden;
          }

          ${ModalContainer} {
            padding: 0px;
          }
        `}
`

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
  text-align: right;

  &:hover {
    fill: ${(props) => props.theme.colors.black};
  }
`

export const TokenFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  justify-content: start;
  padding-right: 32px;
  overflow: auto;
  margin-right: 4px;
  height: calc(100% - 100px);
  @media screen and (min-height: 1000px) and (orientation: landscape) {
    padding-bottom: 20vh;
  }
  > div > * {
    margin-bottom: 24px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 32px;
  }
`

export const SectionLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
  margin-top: 32px;

  :first-child {
    margin-top: 0px;
  }
`

export const InputText = styled(InputTextCmp)``

export const Select = styled(SelectCmp)`
  div > div > p {
    line-height: 21px;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.graytheme6};
    font-weight: normal;
  }
  > div > span > p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500px;
    color: ${({ theme }) => theme.colors.cancelledStatusTextColor};
  }
`

export const ListBoxOutline = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  padding: 12px;
  text-align: justify;
`
export const List = styled.ul`
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.graytheme6};
`

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.graytheme6};
`

export const DestinationAccountWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
  padding-right: 16px;
`

export const SearchSelect = styled(SearchSelectCmp)`
  width: 280px;
`

export const Align = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
`

export const CompanyFormWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
  padding-right: 16px;
`

export const LegalRepresentativeFormWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
  padding-right: 16px;
`

export const AnyBankSubHeaderText = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;

  > svg {
    margin-right: 8px;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  padding-left: 8px;
  gap: 16px;
`

export const Div = styled.div``

export const SuccessButtonWrapper = styled.div<{ margin?: string }>`
  min-height: 72px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: stretch;
  align-content: space-between;
  justify-content: space-between;
  gap: 16px;
  ::before {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
  > div {
    margin-top: 28px;
    width: 100%;
  }
`

export const InfoSucessBox = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  padding: 8px;
  border-radius: 8px;
  margin: 32px 0;
`

export const SuccessWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
  padding-right: 16px;
`

export const SuccessIconBox = styled.div`
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SuccessPixText = styled(TextCmp)`
  b {
    color: ${({ theme }) => theme.colors.maincolor};
    text-decoration: underline;
    cursor: pointer;
  }
`
