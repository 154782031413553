import useWalletsSettings from 'hooks/useWalletsSettings'
import React from 'react'
import CoinPaymentsConfigIPNWallet from '../ConfigWalletModal/ConfigWalletModalPerProvider/CoinPaymentsConfigIPNWallet'
import EditAmeWallet from './EditAmeWallet'
import EditCieloWallet from './EditCieloWallet'
import EditMercadoPagoWallet from './EditMercadoPagoWallet'
import EditPagseguroWallet from './EditPagseguroWallet'
import EditPicpayWallet from './EditPicpayWallet'
import EditCoinpaymentsWallet from './EditCoinPaymentsWallet'
import EditTecBanWallet from './EditTecBanWallet'

import * as s from './styles'
import EditNinetyninepayWallet from './EditNinetyninepayWallet'
import EditFoxBitWallet from './EditFoxBitWallet'

interface IProps {
  isModalOpen: boolean
  handleClose: () => void
  detailsId: string
  offset?: string
}

const EditWalletModal: React.FC<IProps> = ({
  isModalOpen,
  handleClose,
  detailsId,
  offset,
}) => {
  const {
    walletSetting,
    walletSettingIsLoading,
    walletSettingHasError,
    loadWalletSetting,
  } = useWalletsSettings()

  const renderForm = () => {
    if (walletSettingIsLoading) {
      return (
        <s.LoadingWrapper>
          <s.Text
            data-testid="loading-text-stores"
            color="maincolor"
            type="heading3"
          >
            Carregando...
          </s.Text>
          <s.Loading
            type="spinner"
            data-testid="loading-spinner1"
            color="maincolor"
          />
        </s.LoadingWrapper>
      )
    } else if (walletSettingHasError) {
      return (
        <s.Text color="maincolor" data-testid="error-text" type="heading3">
          Erro na API, tente novamente mais tarde
        </s.Text>
      )
    } else {
      switch (walletSetting?.wallet_name) {
        case 'ame':
          return (
            <EditAmeWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
        case 'mercadopago':
          return (
            <EditMercadoPagoWallet
              handleClose={handleClose}
              data={walletSetting}
              offset={offset}
            />
          )
        case 'cielo':
          return (
            <EditCieloWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
        case 'picpay':
          return (
            <EditPicpayWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
        case 'pagseguro':
          return (
            <EditPagseguroWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
        case 'coinpayments':
          if (walletSetting.status === 'waiting_ipn_secret') {
            return (
              <CoinPaymentsConfigIPNWallet
                isModalOpen={isModalOpen}
                handleClose={handleClose}
                ipn={walletSetting.id}
              />
            )
          }
          return (
            <EditCoinpaymentsWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
        case 'foxbit':
          return (
            <EditFoxBitWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
        case 'tecban':
          return (
            <EditTecBanWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
        case 'ninetyninepay':
          return (
            <EditNinetyninepayWallet
              offset={offset}
              handleClose={handleClose}
              data={walletSetting}
            />
          )
      }
    }
  }
  React.useEffect(() => {
    if (detailsId && isModalOpen === true) {
      loadWalletSetting(detailsId)
    }
  }, [detailsId, isModalOpen, loadWalletSetting])

  return (
    <s.Modal
      modalSubtitle="Detalhes da Carteira"
      isOpen={isModalOpen}
      handleClose={handleClose}
    >
      {renderForm()}
    </s.Modal>
  )
}

export default EditWalletModal
