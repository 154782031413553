import {
  isValidCnpj,
  isValidCpf,
  isValidEmail,
  isValidPhone,
} from 'helpers/validators'
import {
  applyCpfCnpjMask,
  applyCnpjMask,
  applyPhoneMask,
  applyPhoneMaskWithoutSlice,
  applyPhoneMaskWithoutSliceForItau,
} from 'helpers/masks'

const PIX_KEY_CPF = 'cpf'
const PIX_KEY_CNPJ = 'cnpj'
const PIX_KEY_RANDOM = 'random' || 'random_key'
const PIX_KEY_EMAIL = 'email'
const PIX_KEY_PHONE = 'phone'

export function validate(pixKey) {
  const keyTypes = []
  pixKey = pixKey?.trim()

  if (isValidCpf(pixKey)) keyTypes.push(PIX_KEY_CPF)

  if (isValidCnpj(pixKey)) keyTypes.push(PIX_KEY_CNPJ)

  if (isValidPhone(pixKey)) keyTypes.push(PIX_KEY_PHONE)

  if (isValidEmail(pixKey)) keyTypes.push(PIX_KEY_EMAIL)

  if (pixKey?.length > 16) keyTypes.push(PIX_KEY_RANDOM)

  return keyTypes
}

export function normalize(pixKey, as = null) {
  pixKey = pixKey?.trim()
  let useAs = validate(pixKey)

  switch (useAs[0]) {
    case PIX_KEY_CPF:
    case PIX_KEY_CNPJ:
      return pixKey.replace(/[^0-9]/g, '')

    case PIX_KEY_PHONE:
      return pixKey.replace(/[()-/\s+/]+/g, '')

    case PIX_KEY_RANDOM:
    case PIX_KEY_EMAIL:
      return pixKey
  }
}

export function normalizeForItau(pixKey, as = null) {
  pixKey = pixKey.trim()
  let useAs = validate(pixKey)

  switch (useAs[0]) {
    case PIX_KEY_CPF:
    case PIX_KEY_CNPJ:
      return pixKey.replace(/[^0-9]/g, '')

    case PIX_KEY_PHONE:
      return pixKey.replace(/[-()/\s/]/g, '')

    case PIX_KEY_RANDOM:
    case PIX_KEY_EMAIL:
      return pixKey
  }
}

export function formatPixKey(pixKey, as = null) {
  let useAs = validate(pixKey)

  pixKey = normalize(pixKey)
  switch (useAs[0]) {
    case PIX_KEY_CPF:
      return applyCpfCnpjMask(pixKey)

    case PIX_KEY_CNPJ:
      return applyCnpjMask(pixKey)

    case PIX_KEY_PHONE:
      return applyPhoneMaskWithoutSlice(pixKey)

    case PIX_KEY_RANDOM:
    case PIX_KEY_EMAIL:
      return pixKey
  }
}

export function formatPixKeyForItau(pixKey, as = null) {
  let useAs = validate(pixKey)

  pixKey = normalize(pixKey)
  switch (useAs[0]) {
    case PIX_KEY_CPF:
      return applyCpfCnpjMask(pixKey)

    case PIX_KEY_CNPJ:
      return applyCnpjMask(pixKey)

    case PIX_KEY_PHONE:
      return applyPhoneMaskWithoutSliceForItau(pixKey)

    case PIX_KEY_RANDOM:
    case PIX_KEY_EMAIL:
      return pixKey
  }
}

export function getPixKeyType(pixKey) {
  let useAs = validate(pixKey)

  pixKey = normalize(pixKey)
  switch (useAs[0]) {
    case PIX_KEY_CPF:
      return 'cpf'

    case PIX_KEY_CNPJ:
      return 'cnpj'

    case PIX_KEY_PHONE:
      return 'phone'

    case PIX_KEY_EMAIL:
      return 'email'
    case PIX_KEY_RANDOM:
      return 'random_key'
  }
}

export function maskPixKey(pixKey) {
  const pixKeyType = getPixKeyType(pixKey)

  switch (pixKeyType) {
    case PIX_KEY_CPF:
    case PIX_KEY_CNPJ:
      return applyCpfCnpjMask(pixKey)

    case PIX_KEY_PHONE:
      return applyPhoneMask(pixKey)

    case PIX_KEY_EMAIL:
    case PIX_KEY_RANDOM:
      return pixKey

    default:
      return pixKey
  }
}
