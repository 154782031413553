import { useState, useEffect } from 'react'

import * as s from './styles'
import {
  isNotEmpty,
  isValidEmail,
  isValidPhone,
  isValidCpf,
} from 'helpers/validators'

import { applyPhoneMask, applyCpfCnpjMask } from 'helpers/masks'

import { usePostUser, usePatchUser } from 'hooks/user'
import { useNonInitialEffect } from 'hooks/utils'
import useGetUser from 'hooks/useGetUser'
import { showToast } from 'components/Toast'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  mode: string
  roles: any[]
  targetId: string
  level: string
}

const UserForm: React.FC<IProps> = ({
  isOpen,
  handleClose,
  mode,
  roles,
  targetId,
  level,
}) => {
  const formDefault = {
    first_name: { value: '', validator: isNotEmpty, touched: false },
    last_name: { value: '', validator: isNotEmpty, touched: false },
    phone: { value: '', validator: isValidPhone, touched: false },
    email: { value: '', validator: isValidEmail, touched: false },
    cpf: { value: '', validator: isValidCpf, touched: false },
    generate_password: { value: true, validator: null, touched: false },
    password: { value: '', validator: isNotEmpty, touched: false },
    user_role_id: { value: '', validator: null, touched: false },
    external_crm_id: { value: '', validator: null, touched: false },
  }
  const { user, loadUser } = useGetUser()
  const [selectedRole, setSelectedRole] = useState<any>({})
  const [roleOptionsList, setRoleOptionsList] = useState([])
  const [userForm, setUserForm] = useState(formDefault)
  const [userFormErrors, setUserFormErrors] = useState<any>(
    Object.keys(formDefault).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
  )
  const { patchResponse, patchError, editUser } = usePatchUser()
  const { postResponse, postError, createUser } = usePostUser()

  const showExternalCrmId = user && Object.hasOwn(user, 'external_crm_id')

  useNonInitialEffect(() => {
    _validateForm()
  }, [userForm])

  useNonInitialEffect(() => {
    setUserForm({
      ...userForm,
      password: { value: '', validator: isNotEmpty, touched: false },
    })
  }, [userForm.generate_password.value])

  useNonInitialEffect(() => {
    _setUserForm({ user_role_id: selectedRole.id })
  }, [selectedRole])

  useNonInitialEffect(() => {
    if (patchResponse.code !== -1) {
      _clearForm()
      handleClose()
      showToast({
        type: 'success',
        message: 'Usuário editado com sucesso',
      })
    }
  }, [patchResponse])

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      _clearForm()
      handleClose()
      showToast({
        type: 'success',
        message: 'Usuario criado com sucesso',
      })
    }
  }, [postResponse])

  useNonInitialEffect(() => {
    if (patchError) {
      showToast({
        type: 'error',
        message: 'Problema ao editar usuário',
      })
    }
  }, [patchError])

  useNonInitialEffect(() => {
    if (postError) {
      showToast({
        type: 'error',
        message: 'Problema ao criar usuário',
      })
    }
  }, [postError])

  useEffect(() => {
    if (window.hj) {
      window.hj(
        'stateChange',
        `modal_${mode === 'create' ? 'create' : 'edit'}_user`
      )
    }

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_${mode === 'create' ? 'create' : 'edit'}_user`,
      })
    }
    if (mode !== 'create' && mode) {
      loadUser(mode)
    }
  }, [mode])

  useEffect(() => {
    if (mode !== 'create' && user && Object.values(user).length > 0) {
      const userRole = roleOptionsList.find((x) => x.id === user.user_role_id)
      if (userRole) {
        _setUserForm({
          first_name: user.first_name,
          last_name: user.last_name,
          phone: applyPhoneMask(user.phone),
          email: user.email,
          cpf: applyCpfCnpjMask(user.cpf),
          generate_password: true,
          user_role_id: userRole.id,
          external_crm_id: user.external_crm_id,
        })
        setSelectedRole({ id: userRole.id, name: userRole.text })
      }
    }
  }, [user])

  useEffect(() => {
    if (roles && roles.length > 0) {
      const selectList = roles.map((x) => {
        return {
          id: x.uuid,
          text: x.name,
          onClick: () => {
            setSelectedRole({ id: x.uuid, name: x.name })
          },
        }
      })
      const firstOption = selectList[0]
      _setUserForm({ user_role_id: firstOption.id })
      setSelectedRole({ id: firstOption.id, name: firstOption.text })
      setRoleOptionsList(selectList)
    }
  }, [roles])

  const _setUserForm = (obj) => {
    const formObj = Object.entries(obj).reduce((acc, cur) => {
      const [key, value] = cur
      return {
        ...acc,
        [key]: {
          value: value,
          validator: userForm[key].validator,
          touched: true,
        },
      }
    }, {})
    setUserForm({ ...userForm, ...formObj })
  }

  const _validateForm = () => {
    const invalidFields = Object.entries(userForm).reduce((acc, cur) => {
      const [key, value] = cur
      if (value.touched && value.validator && !value.validator(value.value)) {
        //CPF is not required in edit mode
        if (key === 'cpf' && mode !== 'create') {
          return {
            ...acc,
            [key]: '',
          }
        }
        //external_crm_id is not required in edit mode or create mode
        if (key === 'external_crm_id') {
          return {
            ...acc,
            [key]: '',
          }
        }
        return {
          ...acc,
          [key]: 'Campo obrigatório',
        }
      }
      return {
        ...acc,
        [key]: '',
      }
    }, {})

    setUserFormErrors({ ...userFormErrors, ...invalidFields })
    if (invalidFields && Object.values(invalidFields).every((x) => !x))
      return true
    return false
  }

  const _clearForm = () => {
    setUserForm(formDefault)
    setUserFormErrors(
      Object.keys(formDefault).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
    )
  }

  const _handleClose = () => {
    _clearForm()
    handleClose()
  }

  const onFormSubmit = () => {
    if (_validateForm()) {
      const payload = Object.entries(userForm).reduce((acc, cur) => {
        const [key, value] = cur
        if (key === 'cpf' && value.value === '') return { ...acc }
        if (key === 'external_crm_id' && value.value === '') return { ...acc }
        return {
          ...acc,
          [key]: value.value,
        }
      }, {} as any)

      if (level === 'retail-chain') {
        payload.retail_chain_id = targetId
      }
      if (level === 'customer') {
        payload.customer_id = targetId
      }

      if (mode === 'create') {
        createUser(payload)
      } else {
        editUser(user.id, payload)
      }
    }
  }

  return (
    <s.Modal
      handleClose={_handleClose}
      isOpen={isOpen}
      modalSubtitle={mode === 'create' ? 'Criar Usuário' : 'Editar Usuário'}
    >
      <s.ModalContent>
        <s.Grid2>
          <s.InputGroup>
            <s.InputText
              label="Nome"
              maxLength={100}
              value={userForm.first_name.value}
              onChange={(e) => _setUserForm({ first_name: e.target.value })}
              error={
                userFormErrors.first_name !== ''
                  ? { message: userFormErrors.first_name }
                  : false
              }
            />
          </s.InputGroup>
          <s.InputGroup>
            <s.InputText
              label="Sobrenome"
              maxLength={100}
              value={userForm.last_name.value}
              onChange={(e) => _setUserForm({ last_name: e.target.value })}
              error={
                userFormErrors.last_name !== ''
                  ? { message: userFormErrors.last_name }
                  : false
              }
            />
          </s.InputGroup>
        </s.Grid2>
        <s.Grid3>
          <s.InputGroup>
            <s.InputText
              label="Telefone"
              maxLength={100}
              value={userForm.phone.value}
              onChange={(e) =>
                _setUserForm({ phone: applyPhoneMask(e.target.value) })
              }
              error={
                userFormErrors.phone !== ''
                  ? { message: userFormErrors.phone }
                  : false
              }
            />
          </s.InputGroup>
          <s.InputGroup>
            <s.InputText
              label="E-mail"
              maxLength={100}
              value={userForm.email.value}
              onChange={(e) => _setUserForm({ email: e.target.value })}
              error={
                userFormErrors.email !== ''
                  ? { message: userFormErrors.email }
                  : false
              }
            />
          </s.InputGroup>
          <s.InputGroup>
            <s.Text bold color="maincolor" type="headline">
              Role
            </s.Text>
            <s.Select options={roleOptionsList} selected={selectedRole} />
          </s.InputGroup>
        </s.Grid3>
        <s.Grid3>
          <s.InputGroup>
            <s.InputText
              label="CPF"
              maxLength={14}
              value={userForm.cpf.value}
              onChange={(e) =>
                _setUserForm({ cpf: applyCpfCnpjMask(e.target.value) })
              }
              error={
                userFormErrors.cpf !== '' && mode === 'create'
                  ? { message: userFormErrors.cpf }
                  : false
              }
            />
          </s.InputGroup>
          <s.InputGroup>
            <s.Text bold color="maincolor" type="headline">
              Senha automática
            </s.Text>
            <s.SwitchWrapper>
              <s.Switch
                data={{ children: userForm.generate_password.value, index: 1 }}
                handleChange={(e) => {
                  _setUserForm({
                    generate_password: !userForm.generate_password.value,
                  })
                }}
              ></s.Switch>
            </s.SwitchWrapper>
          </s.InputGroup>
          <s.InputGroup>
            <s.InputPassword
              maxLength={100}
              disabled={userForm.generate_password.value}
              label="Senha"
              value={userForm.password.value}
              onChange={(e) => _setUserForm({ password: e.target.value })}
              error={
                userFormErrors.password !== '' &&
                !userForm.generate_password.value
                  ? { message: '123' }
                  : false
              }
            />
          </s.InputGroup>
        </s.Grid3>
        {showExternalCrmId && (
          <s.Grid3>
            <s.InputGroup>
              <s.InputText
                label="ID do CRM"
                value={userForm.external_crm_id?.value}
                onChange={(e) =>
                  _setUserForm({ external_crm_id: e.target.value })
                }
                error={
                  userFormErrors.external_crm_id !== ''
                    ? { message: userFormErrors.external_crm_id }
                    : false
                }
              />
            </s.InputGroup>
          </s.Grid3>
        )}

        <s.ButtonCenter>
          <s.Button color="maincolor" onClick={() => onFormSubmit()}>
            <s.Text bold color="whiteshipay">
              {mode === 'create' ? 'Criar' : 'Editar'}
            </s.Text>
          </s.Button>
        </s.ButtonCenter>
      </s.ModalContent>
    </s.Modal>
  )
}

export default UserForm
