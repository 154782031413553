import React, { useEffect } from 'react'
import * as s from '../../styles'
import { get } from 'services/api'
import { applyCpfCnpjMask } from 'helpers/masks'
import { maskPixKey } from 'helpers/pix'
import { initialSteps, InitialSteps } from '..'

interface IdentityValidationProps {
  registrationId: string
  setStep: (step: InitialSteps) => void
  handleClose: () => void
}

interface LegalRepresentativeData {
  legalRepresentative: {
    fullName: string
    documentNumber: string
    email: string
  }
  destinationAccount: {
    documentNumber: string
    dictKey: string
  }
}

const AnyBankIdentityValidationModal: React.FC<IdentityValidationProps> = ({
  registrationId,
  setStep,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [legalRepresentativeData, setLegalRepresentativeData] =
    React.useState<LegalRepresentativeData>({
      legalRepresentative: {
        fullName: '',
        documentNumber: '',
        email: '',
      },
      destinationAccount: {
        documentNumber: '',
        dictKey: '',
      },
    })

  const getLegalRepresentativeInfo = async () => {
    setIsLoading(true)
    await get(`/v1/pix-any-bank/transitory-account/${registrationId}/summary`)
      .then((res) => {
        setLegalRepresentativeData({
          legalRepresentative: {
            fullName: res.data.legal_representative.name,
            documentNumber: res.data.legal_representative.document_number,
            email: res.data.legal_representative.email,
          },
          destinationAccount: {
            documentNumber: res.data.destination_account.document_number,
            dictKey: res.data.destination_account.pix_dict_key,
          },
        })
      })
      .catch((e) => {
        setStep({ ...initialSteps, error: true })
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_anybank_itau_anybank_v2_creation_Identity_validation`,
      })
    }
    getLegalRepresentativeInfo()
  }, [])

  return (
    <>
      <s.Sections>
        <s.AnyBankItauIdentityValidationWrapper>
          <s.Section>
            <s.Text margin="0 0 16px 0" bold type="heading4" color="graytheme6">
              Validação de identidade
            </s.Text>
            <s.Text margin="0 0 16px 0" type="paragraph" color="graytheme6">
              É necessário validar a identidade do representante legal indicado
              por <u>reconhecimento facial</u> e <u>análise de documento</u>:
            </s.Text>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                Nome Completo
              </s.Text>
              <s.InputText
                testId="legal-representative-full-name"
                width={260}
                prefix={
                  isLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : null
                }
                disabled
                value={legalRepresentativeData.legalRepresentative.fullName}
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                CPF do representante
              </s.Text>
              <s.InputText
                testId="legal-representative-document-number"
                width={260}
                prefix={
                  isLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : null
                }
                disabled
                value={applyCpfCnpjMask(
                  legalRepresentativeData.legalRepresentative.documentNumber
                )}
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                E-mail
              </s.Text>
              <s.InputText
                testId="legal-representative-email"
                width={260}
                prefix={
                  isLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : null
                }
                disabled
                value={legalRepresentativeData.legalRepresentative.email}
              />
            </s.SectionLine>
            <s.AnyBankPixText type="paragraph" fontWeight={400}>
              Essa validação é necessária para a abertura de uma conta bancária
              transitória Itaú que receberá pagamentos via Pix e repassará para
              a seguinte conta:
            </s.AnyBankPixText>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                Chave Pix
              </s.Text>
              <s.InputText
                testId="destination-account-pix-dict-key"
                width={260}
                prefix={
                  isLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : null
                }
                disabled
                value={maskPixKey(
                  legalRepresentativeData.destinationAccount.dictKey
                )}
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline" color="graytheme6">
                CNPJ da conta destino
              </s.Text>
              <s.InputText
                testId="destination-account-document-number"
                width={260}
                prefix={
                  isLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : null
                }
                disabled
                value={applyCpfCnpjMask(
                  legalRepresentativeData.destinationAccount.documentNumber
                )}
              />
            </s.SectionLine>
            <s.AnyBankPixText type="paragraph" fontWeight={400}>
              Caso o representante legal não esteja presente neste momento, é
              possível continuar posteriomente pela aba{' '}
              <b onClick={() => handleClose()}>Pix↗</b> do Painel, na seção
              <u> Contas pendentes.</u>
            </s.AnyBankPixText>
          </s.Section>
        </s.AnyBankItauIdentityValidationWrapper>
      </s.Sections>
    </>
  )
}

export default AnyBankIdentityValidationModal
