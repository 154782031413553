import styled from 'styled-components'

import ButtonCmp from 'components/Button'
import IconExporter, { ThemeColorNames } from 'components/IconExporter'
import RadioComponent from 'components/Inputs/RadioButton'

export { Text, Icon, Spacer, SectionHeader, SectionContentWrapper } from '../styles'

export const FilesTableWrapper = styled.div`
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export interface MessageBoxOutlineProps {
  color?: ThemeColorNames
  headerColor?: ThemeColorNames
  borderColor?: ThemeColorNames
  bgColor?: ThemeColorNames
  padding?: string
  margin?: string
}

export const MessageBoxOutline = styled.div<MessageBoxOutlineProps>`
  border-radius: 8px;
  font-size: 14px;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '12px'};
  text-align: justify;
  ${({ theme, borderColor }) => borderColor && `border: 1px solid ${theme.colors[borderColor]} ;`}
  position: relative;
  color: ${({ theme, color }) => theme.colors[color || 'gray1']};
  &:after {
    content: " ";
    border-radius: 8px;
    ${({ theme, bgColor }) => bgColor && `background-color: ${theme.colors[bgColor]} ;`}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 5%;
    z-index: 0;
  }
  & b {
    color: ${({ theme, headerColor }) => theme.colors[headerColor || 'gray1']};
    font-weight: 700;
    display: block;
  }
  & span {
    display: block;
    font-weight: 500;
  }
`
export const MessageBoxOutlineTitle = styled.b``
export const MessageBoxOutlineContent = styled.span``


interface DateTextProps {
  error?: boolean
}

export const IntervalTextWrapper = styled.div`
margin-block: 1.5em`

export const IntervalText = styled.div<DateTextProps>`
  color: ${({ theme, error }) => theme.colors[error ? 'redshipay' : 'green1']};
  display: block;
  font-size: 16px;
  font-weight: 700;
  &:before {
    content: '${({ error }) => error ? '✘' : '✔'}';
    padding-right: .3em;
  }
`

export const RequestButton = styled(ButtonCmp)`
  flex-grow: 1;
  margin-inline-start: 30px;
  > span {
    text-transform: none;
  }
`

export const RequestButtonIcon = styled(IconExporter)`
  margin-right: 8px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  height: 35px;
`

export const RadioBoxContainer = styled.div`
  display: flex;
  > * {
    margin-inline-end: 10px;
  }
`

export const RadioBox = styled(RadioComponent)`
  padding-inline: 10px;
  width: 80px;
  border: 2px solid ${(props) => props.theme.colors.maincolor};
  border-radius: 8px;

  > input {
    &:before {
      border: 2px solid ${(props) => props.theme.colors.maincolor};
    }
  }
`
