import styled from 'styled-components'

export const IFrameWrapper = styled.div`
  display: flex;
  justify-content: center;

  #idwall-sdk-iframe {
    max-width: 100%;

    @media (max-height: 600px) {
      height: 350px;
    }

    @media (min-height: 601px) and (max-height: 800px) {
      height: 530px;
    }

    @media (min-height: 801px) and (max-height: 940px) {
      height: 620px;
    }

    @media (min-height: 941px) and (max-height: 1920px) {
      height: 690px;
    }
  }
`
