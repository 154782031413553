import styled from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import WalletCardCmp from 'components/Card/WalletDisplayCard'
import TextCmp from 'components/Text'
import TableCmp from 'components/Table'
import ToggleSwitchCmp from 'components/Switch'
import IconCmp from 'components/IconExporter'
import LoadingCmp from 'components/Loading'
import ModalCmp from 'components/Modal'
import PaginationCmp from 'components/Pagination'

export const Container = styled(ContainerCmp)`
  max-width: 1500px;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;

  > div {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  > p {
    margin: ${({ theme }) => theme.spacing(4)}px 0
      ${({ theme }) => theme.spacing(3)}px 0;
  }
`

export const WalletsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 32px;
`

export const Text = styled(TextCmp)``

export const WalletCard = styled(WalletCardCmp)``

export const Table = styled(TableCmp)`
  * > th {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    :first-child {
      width: 500px;
    }
    :nth-child(2) {
      width: 500px;
    }
    :nth-child(4) {
      width: 120px;
    }
  }
`

export const ToggleSwitch = styled(ToggleSwitchCmp)``

export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Icon = styled(IconCmp)``

export const Loading = styled(LoadingCmp)``

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TableWrapper = styled.div``

export const Modal = styled(ModalCmp)``

export const Pagination = styled(PaginationCmp)``

export const PaginationWrapper = styled.div`
  width: 100%;
`
