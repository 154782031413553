import React from 'react'
import { useLocation } from 'react-router-dom'

export function useGA4PageTracking() {
  const location = useLocation()

  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
      })
    }
  }, [location])
}
