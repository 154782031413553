import React, { useState } from 'react'

import CompleteRegistration from './CompleteRegistration'
import CustomerRegistration from './CustomerRegistration'

import * as s from './styles'

interface IProps {
  isOpen: boolean
  handleClose: () => any
}

const RegistrationModal: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const [currentModal, setCurrentModal] = useState('cadastro-completo')

  React.useEffect(() => {
    if (window.hj) {
      window.hj('stateChange', 'modal_create_registration_admin')
    }

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_create_registration_admin`,
      })
    }
  }, [])

  return (
    <s.Modal
      noChildrenPadding
      isOpen={isOpen}
      handleClose={() => {
        handleClose()
      }}
    >
      <s.ModalContent>
        <s.ModalHeader>
          <s.PillItem
            onClick={() => setCurrentModal('cadastro-completo')}
            isSelected={currentModal === 'cadastro-completo' ? true : false}
          >
            <s.Text type="headline" color="graytheme6" bold>
              Cadastro Completo
            </s.Text>
          </s.PillItem>
          <s.PillItem
            onClick={() => setCurrentModal('cadastro-conta')}
            isSelected={currentModal === 'cadastro-conta' ? true : false}
          >
            <s.Text type="headline" color="graytheme6" bold>
              Cadastro de Conta
            </s.Text>
          </s.PillItem>
        </s.ModalHeader>
        {currentModal === 'cadastro-completo' ? (
          <CompleteRegistration
            onSuccess={() => handleClose()}
            onClose={isOpen}
          />
        ) : (
          <CustomerRegistration
            onSuccess={() => handleClose()}
            onClose={isOpen}
          />
        )}
      </s.ModalContent>
    </s.Modal>
  )
}

export default RegistrationModal
