import React, { SetStateAction, useMemo } from 'react'

import * as s from './styles'

interface RecordDisplayProps {
  data: Record<string, any> | Record<string, any>[]
}

const RecordDisplay: React.FC<RecordDisplayProps> = ({
  data
}) => {
  const data_list: Record<string, any>[] = useMemo(() => {
    return [data].flat()
  }, [data])
  return <s.RecordDisplayList className='record-display-container'>
    {
      data_list.map((_group, i) =>
        <s.RecordDisplayListGroup key={`group_${i}`}>
          {
            Object.entries(_group).map(([key, value], i) => <s.RecordDisplayListLine key={`group_${i}_${key}`}>
              <s.RecordDisplayListTitle>
                <s.Text bold color="graytheme6" fontWeight={600}>
                  {key}
                </s.Text>
              </s.RecordDisplayListTitle>
              <s.RecordDisplayListDescription>
                <s.Text color="graytheme6">
                  {value}
                </s.Text>
              </s.RecordDisplayListDescription>
            </s.RecordDisplayListLine>)
          }
        </s.RecordDisplayListGroup>
      )
    }
  </s.RecordDisplayList>

}

export default RecordDisplay