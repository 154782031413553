import React, { createRef, useEffect, useState } from 'react'
import {
  get,
  remove,
  baseURLRegistrationSummaryPosProductWlComponents,
  baseURLPosProductComponentsWlCustomImages,
  sendFormData,
  PostPatchOrPut,
} from 'services/api'
import { showToast } from 'components/Toast'
import { v4 as uuid } from 'uuid'

import * as s from './styles'

export type FileType = 'logo' | 'icon' | 'doc'

const allowedImageFormats = ['png', 'jpeg', 'jpg', 'ico']
const allowDocFormats = ['pdf']

type AllowedFormatsType =
  | (typeof allowedImageFormats)[number]
  | (typeof allowDocFormats)[number]

interface IProps {
  title?: string
  type?: FileType
  componentId?: string
  required?: boolean
  value: File
  hasFileAlready?: boolean
  maxFileSize?: number
  allowedFormats?: Array<AllowedFormatsType>
  handleChange: (e: File) => void
  clear: () => void
}

const UploadFileButtonArea: React.FC<IProps> = ({
  title,
  type,
  hasFileAlready = false,
  componentId,
  required,
  value,
  maxFileSize = 1500000, // 1.5MB
  allowedFormats = ['png', 'jpeg', 'jpg'],
  handleChange,
  clear,
  ...rest
}) => {
  const inputFile = createRef<HTMLInputElement | null>()
  const drop = React.useRef(null)

  const [userFile, setUserFile] = useState<File>()
  const [hasFile, setHasFile] = useState<boolean>(hasFileAlready)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [errorIsEmpty, setErrorIsEmpty] = useState<boolean>(required)

  const invalidFileUploadRules =
    !allowedFormats.includes(userFile?.type.split('/')[1]) ||
    userFile?.size > maxFileSize

  const checkFormatAndSize = (file: File) => {
    if (!file) setErrorMessages([])

    const _errorMessages: string[] = []
    const errorMapper = {
      format: `Formato de arquivo não compatível, utilize uma extensão ${formatAllowedFormats(
        allowedFormats
      )}`,
      size: `Arquivo maior que o tamanho máximo de ${(
        maxFileSize / 1000000
      ).toFixed(1)} MB`,
    }

    if (invalidFileUploadRules) {
      if (!allowedFormats.includes(file.type.split('/')[1])) {
        _errorMessages.push(errorMapper['format'])
      }
      if (file.size > maxFileSize) {
        _errorMessages.push(errorMapper['size'])
      }
    }

    if (_errorMessages.length > 0) {
      setErrorMessages(_errorMessages)
    } else {
      setUserFile(file)
      handleChange(file)
    }
  }

  function arrayToFileExtensionString(extensions: string[]): string {
    return extensions.map((ext) => `.${ext}`).join(', ')
  }

  useEffect(() => {
    if (userFile) {
      checkFormatAndSize(userFile)
      setErrorIsEmpty(false)

      if (type == 'logo' && invalidFileUploadRules) {
        setHasFile(false)
        setUserFile(null)
      } else {
        setHasFile(true)
      }
    }
  }, [userFile])

  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const file = event.target.files[0]
      checkFormatAndSize(file)
      setUserFile(file)
      event.target.value = ''
    }
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  useEffect(() => {
    setErrorIsEmpty(required)
  }, [required])

  useEffect(() => {
    setHasFile(hasFileAlready)
  }, [hasFileAlready])

  React.useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener('dragover', handleDragOver)
      drop.current.addEventListener('drop', handleDrop)
    }

    return () => {
      if (drop.current) {
        drop.current.removeEventListener('dragover', handleDragOver)
        drop.current.removeEventListener('drop', handleDrop)
      }
    }
  }, [])

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    if (files && files.length) {
      if (e) {
        const file = files[0]
        setUserFile(file)
        handleChange(file)
        e.target.value = ''
      }
    }
  }

  function formatAllowedFormats(allowedFormats: string[]): string {
    return allowedFormats.map((format) => `[.${format}]`).join(' ')
  }

  return (
    <div {...rest}>
      {title ? (
        <s.Title color={'maincolor'} bold>
          {title}
        </s.Title>
      ) : null}
      {hasFile && value ? (
        <>
          <s.UploadContainer ref={drop}>
            <s.InputUploadedImage
              data-testid={`input-uploaded-image-${type}`}
              ref={inputFile}
              type="file"
              accept={arrayToFileExtensionString(allowedFormats)}
              title="Clique para fazer Upload."
              onChange={onFileSelect}
            />
            <s.UploadedImageButton
              data-testid={`button-uploaded-image-${type}`}
              width="520px"
              height="64px"
              outline
              title="Clique para fazer upload."
              color="hollow"
              disabled={false}
              onClick={onButtonClick}
            >
              <s.Icon fill="maincolor" name="paper" />
              <s.Text type="headline" color="graytheme6" fontWeight={600}>
                {value.name}
              </s.Text>
              <s.Icon
                width={20}
                height={20}
                name="closecircle"
                data-testid={`icon-close-button`}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setHasFile(false)
                  setUserFile(null)
                  setErrorIsEmpty(false)
                  clear()
                }}
              />
            </s.UploadedImageButton>
          </s.UploadContainer>
        </>
      ) : (
        <>
          <s.UploadContainer ref={drop}>
            <s.InputUploadImage
              data-testid={`input-upload-image-${type}`}
              ref={inputFile}
              type="file"
              accept={arrayToFileExtensionString(allowedFormats)}
              onChange={onFileSelect}
            />
            <s.Button
              width="520px"
              height="64px"
              outline
              title="Clique para fazer upload."
              color="white"
              disabled={false}
              onClick={onButtonClick}
            >
              <s.Icon
                width={20}
                height={20}
                name="uploadimage"
                fill={'maincolor'}
              />
              <s.Text color={'maincolor'} bold>
                Selecionar Arquivo
              </s.Text>
            </s.Button>
          </s.UploadContainer>
        </>
      )}
      <s.UploadFormatErrors>
        {errorMessages.length > 0 ? (
          errorMessages.map((errorMsgs) => (
            <s.TextError data-testid={`invalid-file-type-${type}`} key={uuid()}>
              {errorMsgs}
            </s.TextError>
          ))
        ) : (
          <></>
        )}
        {errorIsEmpty ? <s.TextError>Campo obrigatório</s.TextError> : <></>}
        {errorMessages.length > 0 || errorIsEmpty ? <s.Space /> : <></>}
      </s.UploadFormatErrors>
      <s.UploadFormatRules>
        <s.Text type="headline" color="graytheme6">
          Formatos de arquivo:
          <strong> {formatAllowedFormats(allowedFormats)}</strong>
        </s.Text>
        <s.Text type="headline" color="graytheme6">
          Tamanho máximo: <strong>{maxFileSize / 1000000} MB</strong>
        </s.Text>
      </s.UploadFormatRules>
    </div>
  )
}

export default UploadFileButtonArea
