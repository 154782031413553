import { showToast } from 'components/Toast'

import * as s from './styles'
import React from 'react'
import useGetWalletSettingsModalEditConfig from 'hooks/useGetWalletSettingModalEditConfig'
import Loading from './loading'
import {
  applyBankAccountItauMask,
  applyCnpjMask,
  applyCpfCnpjMask,
  applyNumberOnlyMask,
  applyPhoneMask,
} from 'helpers/masks'
import { NewPixKeyInput, PixKeyType } from './components/PixKeyInput'
import { isNotEmpty } from 'helpers/validators'
import { bffv2URLBankSlipSettings, patch } from 'services/api'
import { PixWSInterface } from 'services/pixWalletSettings'
import { getPixKeyType, normalize } from 'helpers/pix'
import { dict as dictIcons } from 'components/IconExporter'
import AddBankSlipDefault from './AddBankSlipDefault'

interface FormError extends FormData {}

interface FormInfoError {
  title: string
  message: React.ReactElement
}

const INITIAL_FORM_ERROR_DATA: FormData = {
  name: '',
  dict_key: '',
  bank_branch: '',
  bank_account: '',
  bank_wallet_code: '',
}
interface IProps {
  isOpen: boolean
  handleClose: () => void
  editFormInfo: {
    id: string
    pspProvider: string
    pixProviderName: string
  }
}

interface FormData {
  name?: string
  dict_key?: string
  pix_dict_key_type?: string
  bank_branch?: string
  bank_account?: string
  bank_wallet_code?: string
  tls_certificate?: string
  document_number?: string
}

const defaultFields = {
  name: undefined,
}

const defaultFriendlyNames = {
  name: 'Apelido',
}

const INITIAL_INFO_ERROR_DATA: FormInfoError = { title: '', message: <></> }

const InfoBox: React.FC<{
  error?: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  if (error?.title) {
    return (
      <s.InfoBox error>
        <s.Text bold type="paragraph" color="redshipay">
          {error.title}
        </s.Text>
        <s.Text type="paragraph" color="cancelledStatusTextColor">
          {error.message}
        </s.Text>
      </s.InfoBox>
    )
  }

  return <></>
}

const SettingsWSFormDefault: React.FC<IProps> = ({
  isOpen,
  handleClose,
  editFormInfo,
}) => {
  const [hasBankSlip, setHasBankSlip] = React.useState(true)
  const [enableCreateBankSlip, setEnableCreateBankSlip] = React.useState(false)
  const [infoError, setInfoError] = React.useState<FormInfoError>(
    INITIAL_INFO_ERROR_DATA
  )

  const [form, setForm] = React.useState<FormData>({})
  const [formDataLoaded, setFormDataLoaded] = React.useState<FormData>(
    INITIAL_FORM_ERROR_DATA
  )
  const [formErrors, setFormErrors] = React.useState<FormError>({})
  const [enableEditNickName, setEnableEditNickName] = React.useState(false)
  const [patchIsLoading, setPatchIsLoading] = React.useState(false)
  const [bankSlipSettingsFields, setBankSlipSettingsFields] = React.useState([])

  const {
    modalConfig,
    setModalConfig,
    getEditModalConfig,
    hasError,
    hasSuccess,
    isLoading,
  } = useGetWalletSettingsModalEditConfig()

  const selectedType = React.useRef<PixKeyType>({
    id: 5,
    type: 'random_key',
    name: 'Aleatória',
  })

  React.useEffect(() => {
    getEditModalConfig(editFormInfo.id)

    if (window.hj) {
      window.hj('stateChange', `modal_edit_pix_${editFormInfo.pspProvider}`)
    }

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_edit_pix_${editFormInfo.pspProvider}`,
      })
    }
  }, [])

  React.useEffect(() => {
    if (modalConfig.sections.length > 0 && isLoading === false) {
      const bankSlipSettingsSection = modalConfig.sections.find(
        (section) => section.key === 'bank_slip_settings'
      )
      if (!bankSlipSettingsSection?.id) {
        setHasBankSlip(false)
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    let bffForm = {}
    let bffFormErrors = {}

    if (!isLoading) {
      modalConfig.sections?.forEach((section) => {
        section.fields.forEach((field) => {
          switch (field.valueType) {
            case 'string':
              bffForm[field.key] = field.value ?? ''
              break

            case 'number':
              bffForm[field.key] = field.value ?? null
              break

            case 'select':
              bffForm[field.key] = field.value ?? ''
              break

            case 'boolean':
              bffForm[field.key] = field.value ?? undefined
              break
          }
        })
      })
    }

    setForm({ ...bffForm, name: modalConfig.name })
    setFormDataLoaded({ ...bffForm, name: modalConfig.name })
    setFormErrors({ ...bffFormErrors, name: '' })
    setInfoError({ title: '', message: <></> })
  }, [isLoading])

  const toggleSection = (key: string): void => {
    const newConfig = modalConfig
    newConfig.sections.forEach((section) => {
      if (section.key === key) {
        section.toggled = !section.toggled
      }
    })
    setModalConfig({ ...newConfig })
  }

  const applyFieldMask = (field, value) => {
    if (field.validators) {
      if (field.validators.includes('isValidCpfCnpj'))
        return applyCpfCnpjMask(value)
      if (field.validators.includes('isValidCnpj')) return applyCnpjMask(value)
      if (field.validators.includes('isValidPhone'))
        return applyPhoneMask(value)
      if (field.validators.includes('isNumberOnly'))
        return applyNumberOnlyMask(value)
      if (field.validators.includes('isBankAccountItau'))
        return applyBankAccountItauMask(value)
    }
    return value
  }

  const infoErrorHandler = (type, errors, section) => {
    let filteredErrors = {}
    let friendlyNames = {}

    if (!section) {
      Object.keys(defaultFields).forEach((key) => {
        filteredErrors[key] = errors[key]
        friendlyNames[key] = defaultFriendlyNames[key]
      })
    }

    let toggledSection = modalConfig.sections.find((s) => s.key === section)
    toggledSection?.fields.forEach((field) => {
      filteredErrors[field.key] = errors[field.key]
      friendlyNames[field.key] = field.friendlyName
    })

    setFormErrors(filteredErrors)
    const fieldsTranslated = Object.entries(filteredErrors)
      .map((error) => (error[1] !== '' ? friendlyNames[error[0]] : undefined))
      .filter((error) => error)
    if (type === 'emptyFields') {
      let requiredFields = ''
      if (fieldsTranslated.length > 2) {
        requiredFields = `${fieldsTranslated
          .slice(0, -1)
          .join(', ')} e ${fieldsTranslated.slice(-1)}`
      } else if (fieldsTranslated.length === 2) {
        requiredFields = `${fieldsTranslated.join(' e ')}`
      } else {
        requiredFields = fieldsTranslated[0]
      }
      setInfoError({
        title: 'Campo(s) obrigatório(s) não preenchido(s)',
        message: (
          <>
            Preencha o(s) campo(s) de <u>{requiredFields}</u> para prosseguir.
          </>
        ),
      })
    }
    setPatchIsLoading(false)
  }

  const handlePatchBankSlip = async (section: string, data: FormData) => {
    setPatchIsLoading(true)

    const errors = { ...formErrors }
    for (const [key, value] of Object.entries(data)) {
      if (!errors[key])
        errors[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    const errList = Object.values(errors).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      setInfoError({ title: '', message: <></> })

      const payload = {
        psp_provider: editFormInfo.pspProvider,
        bank_slip_settings: {
          ...data,
          psp_provider: editFormInfo.pspProvider,
        },
      }

      const bankSlipSettingsId = modalConfig.sections.find(
        (x) => x.key === 'bank_slip_settings'
      ).id
      await patch(`${bffv2URLBankSlipSettings}/${bankSlipSettingsId}`, payload)
        .then((response) => {
          setForm(form)
          setFormDataLoaded(form)
          showToast({
            type: 'success',
            message: 'Informações atualizadas com sucesso ',
          })
        })
        .catch((e) => {
          setForm(formDataLoaded)
          showToast({
            type: 'error',
            message:
              e.response?.data?.detail ||
              e.response?.data?.message ||
              'Erro na API, tente novamente mais tarde',
          })
        })
        .finally(() => {
          setPatchIsLoading(false)
        })
    } else {
      infoErrorHandler('emptyFields', errors, section)
      setPatchIsLoading(false)
    }
  }

  const handlePatchWalletSettings = async (section: string, data: FormData) => {
    setPatchIsLoading(true)
    const errors = { ...formErrors }
    for (const [key, value] of Object.entries(data)) {
      if (!errors[key])
        errors[key] = isNotEmpty(value) ? '' : 'Campo Obrigatório'
    }
    const errList = Object.values(errors).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      setInfoError({ title: '', message: <></> })
      const payload = Object.entries(data).reduce((acc, cur) => {
        const [key, value] = cur
        if (key === 'dict_key') {
          const pixKey = normalize(data.dict_key)
          const pixKeyType = getPixKeyType(pixKey)
          const type = selectedType.current.type
          if (type === 'cpf' || type === 'cnpj' || type === 'phone') {
            data[key] =
              type === 'phone'
                ? '+55' + data.dict_key.replace(/[^a-zA-Z0-9]/g, '')
                : data.dict_key.replace(/[^a-zA-Z0-9]/g, '')
          }
          return {
            ...acc,
            dict_key: data.dict_key,
            pix_dict_key_type: pixKeyType,
          }
        }
        return {
          ...acc,
          [key]: value,
        }
      }, {}) as Partial<PixWSInterface>

      const walletSettingsId = modalConfig.sections.find(
        (x) => x.key === 'cashin'
      ).id

      await patch(`/v2/bff/wallet-settings/${walletSettingsId}`, payload)
        .then((response) => {
          setForm(form)
          setFormDataLoaded(form)
          showToast({
            type: 'success',
            message: 'Informações atualizadas com sucesso ',
          })
        })
        .catch((e) => {
          setForm(formDataLoaded)
          if (e.response)
            showToast({
              type: 'error',
              message: e.response.data?.detail || e.response.data?.message,
            })
        })
        .finally(() => {
          setPatchIsLoading(false)
        })
    } else {
      infoErrorHandler('emptyFields', errors, section)
    }
  }

  const InputElement: React.FC<{
    key: string
    friendlyName: string
    valueType: string
    value: string
    options: Array<string> | Array<{ visible: string; selectable: string }>
    inputType: string
    placeholder: string
    maxLength: number
    toggled: boolean
    validators: string[]
  }> = (field) => {
    switch (field.inputType) {
      case 'text':
        return (
          <s.SectionLine>
            <s.Text
              fontWeight={600}
              type="headline"
              color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
            >
              {field.friendlyName}
            </s.Text>
            <s.InputText
              disabled={isLoading || !field.toggled || patchIsLoading}
              placeholder={field.placeholder}
              maxLength={field.maxLength ?? undefined}
              testId={`${field.key}-test`}
              width={260}
              value={form[field.key] || ''}
              onChange={(e) => {
                setForm({
                  ...form,
                  [field.key]: applyFieldMask(field, e.target.value),
                })
                if (formErrors[field.key]) {
                  setFormErrors({ ...formErrors, [field.key]: '' })
                }
              }}
              error={
                formErrors[field.key]
                  ? { message: formErrors[field.key] }
                  : false
              }
              suffix={
                formErrors[field.key] ? (
                  <s.Icon name="alertcirclefilled" fill="lightred2" />
                ) : (
                  <></>
                )
              }
            />
          </s.SectionLine>
        )

      case 'select':
        let list = []
        let _selectedWalletCode = { id: '', name: '' }
        let options = []

        if (
          typeof field.options[0] === 'object' &&
          'visible' in field.options[0] &&
          'selectable' in field.options[0]
        ) {
          options = field.options.map((option, index) => {
            return { id: option.selectable, text: option.visible }
          })
        } else {
          options = field.options.map((value, index) => {
            return {
              id: String(index + 1),
              text: value,
            }
          })
        }

        if (options.length > 0) {
          list = options.map((x) => {
            return {
              ...x,
              onClick: () => {
                return { id: x.id, name: x.text }
              },
            }
          })

          if (form[field.key]) {
            const walletCode = options.find(
              (x) => x.text === form[field.key] || x.id === form[field.key]
            )
            _selectedWalletCode = {
              id: walletCode.id,
              name: walletCode.text,
            }
          }
        }
        return (
          <>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={formErrors[field.key] ? 'redshipay' : 'graytheme6'}
              >
                {field.friendlyName}
              </s.Text>
              <s.Select
                optionBoxSize={150}
                disabled={isLoading || !field.toggled || patchIsLoading}
                selected={_selectedWalletCode}
                width={260}
                testId={`${field.key}-test`}
                optionBoxDirection="top"
                options={list}
                error={
                  formErrors[field.key]
                    ? { message: formErrors[field.key] }
                    : false
                }
                onSelectedOption={(selectedOption) => {
                  if (selectedOption.id) {
                    if (field.key === 'tls_certificate') {
                      setForm({
                        ...form,
                        [field.key]: applyFieldMask(field, selectedOption.id),
                      })
                    } else {
                      setForm({
                        ...form,
                        [field.key]: applyFieldMask(field, selectedOption.text),
                      })
                    }
                    if (formErrors[field.key]) {
                      setFormErrors({ ...formErrors, [field.key]: '' })
                    }
                  }
                }}
              />
            </s.SectionLine>
          </>
        )

      case 'dictKey':
        return (
          <NewPixKeyInput
            disabled={isLoading || !field.toggled || patchIsLoading}
            selectSize={190}
            testId={`${field.key}-test`}
            value={form[field.key] || ''}
            selectedType={selectedType}
            pixKeyErr={formErrors[field.key]}
            onChange={(value) => {
              setForm({
                ...form,
                [field.key]: value,
                pix_dict_key_type: selectedType.current.type,
              })
            }}
            onPixKeyErr={(err) => {
              setFormErrors({
                ...formErrors,
                [field.key]: err,
              })
            }}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <s.Modal
        handleClose={() => handleClose()}
        isOpen={isOpen}
        noChildrenPadding
        modalSubtitle={
          enableCreateBankSlip ? 'Adicionar funcionalidade' : 'Configurações'
        }
        data-testid={`edit-form-${editFormInfo.pspProvider}`}
      >
        <s.ModalContentDefault>
          {isLoading ? (
            <Loading />
          ) : enableCreateBankSlip ? (
            <AddBankSlipDefault
              walletSettingsId={editFormInfo.id}
              pspProvider={editFormInfo.pspProvider}
              name={form.name}
              walletFriendlyName={modalConfig.wallet_friendly_name.replace(
                'Pix ',
                ''
              )}
              sectionHelper={
                modalConfig.sections.find((x) => x.key === 'bank_slip_settings')
                  .sectionHelper
              }
              walletIcon={modalConfig.wallet_icon}
              bankSlipSettingsField={bankSlipSettingsFields}
              setHasBankSlip={setHasBankSlip}
              handleClose={handleClose}
              goBack={(isFromSuccess) => {
                setEnableCreateBankSlip(false)
                if (isFromSuccess) {
                  getEditModalConfig(editFormInfo.id)
                }
              }}
            />
          ) : (
            <s.Sections>
              <s.SectionsEditFormWrapper showScroll={true}>
                <s.Section data-testid="provider-section">
                  <InfoBox error={infoError} />
                  <s.ProviderContainer>
                    <s.Text
                      fontWeight={600}
                      type="headline"
                      margin="0 152px 0 0"
                      color="graytheme8"
                    >
                      Provedor Pix
                    </s.Text>
                    <img width={34} src={modalConfig.wallet_icon ?? ''} />
                    <s.Text
                      bold
                      type="headline"
                      margin="0 0 0 8px"
                      color="graytheme8"
                    >
                      {modalConfig?.wallet_friendly_name?.replace('Pix ', '')}
                    </s.Text>
                  </s.ProviderContainer>
                </s.Section>
                <s.Line />
                <s.Section data-testid="name-section">
                  <s.SectionHeader>
                    <s.TitleWrapper>
                      <s.Icon name="accountwallet" fill="gray1" />
                      <s.Text type="headline" color="graytheme6">
                        Detalhes da conta Pix
                      </s.Text>
                    </s.TitleWrapper>
                    {!enableEditNickName ? (
                      <s.Button
                        data-testid="edit-name-button"
                        onClick={() =>
                          setEnableEditNickName(!enableEditNickName)
                        }
                        width="98px"
                        color="white"
                        outline={false}
                      >
                        <s.Icon name="pencil" fill="maincolor" />
                        <s.Text
                          color="maincolor"
                          type="headline"
                          fontWeight={600}
                        >
                          Editar
                        </s.Text>
                      </s.Button>
                    ) : (
                      <></>
                    )}
                  </s.SectionHeader>
                  <s.SectionLine>
                    <s.Text
                      fontWeight={600}
                      type="headline"
                      color={formErrors?.name ? 'redshipay' : 'graytheme6'}
                    >
                      Apelido
                    </s.Text>
                    <s.InputText
                      testId="name-input"
                      width={260}
                      value={form?.name}
                      disabled={!enableEditNickName || patchIsLoading}
                      onChange={(e) => {
                        setForm({ ...form, name: e.target.value })
                        if (formErrors?.name) {
                          setFormErrors({ ...formErrors, name: '' })
                        }
                      }}
                      error={
                        formErrors?.name ? { message: formErrors?.name } : false
                      }
                      suffix={
                        formErrors?.name ? (
                          <s.Icon name="alertcirclefilled" fill="lightred2" />
                        ) : (
                          <></>
                        )
                      }
                    />
                  </s.SectionLine>
                  {enableEditNickName ? (
                    <s.SectionLine>
                      <div></div>
                      <s.ButtonWrapper>
                        <s.CancelButton
                          color="white"
                          data-testid="cancel-button-name-section"
                          disabled={patchIsLoading}
                          onClick={() => {
                            setEnableEditNickName(false)
                            setForm({
                              ...formDataLoaded,
                            })
                            setFormErrors({ ...formErrors, name: '' })
                          }}
                        >
                          <s.Text
                            type="headline"
                            fontWeight={600}
                            color="maincolor"
                          >
                            Cancelar
                          </s.Text>
                        </s.CancelButton>
                        <s.Button
                          data-testid="save-button-name-section"
                          disabled={
                            patchIsLoading || formDataLoaded.name === form.name
                          }
                          onClick={() => {
                            if (formDataLoaded.name !== form.name) {
                              handlePatchWalletSettings('', {
                                name: form.name,
                              })
                            }
                          }}
                        >
                          {patchIsLoading ? (
                            <s.Loading type="spinner" color="white" />
                          ) : (
                            <s.Icon name="pencilUnderline" fill="white" />
                          )}
                          <s.Text
                            type="headline"
                            fontWeight={600}
                            color="white"
                          >
                            Salvar edição
                          </s.Text>
                        </s.Button>
                      </s.ButtonWrapper>
                    </s.SectionLine>
                  ) : (
                    <></>
                  )}
                </s.Section>
                <s.Line />
                {modalConfig.sections?.map((section) => {
                  if (section.key === 'bank_slip_settings' && !hasBankSlip) {
                    return (
                      <React.Fragment key={`${section.key}`}>
                        <s.Section data-testid={section.key}>
                          <s.SectionHeader>
                            <s.TitleWrapper>
                              <s.Icon
                                name={section.icon as keyof typeof dictIcons}
                                fill="gray1"
                              />
                              <s.Text type="headline" color="graytheme6">
                                {section.friendlyName}
                              </s.Text>
                            </s.TitleWrapper>
                            {section.toggleButton ? (
                              <s.AddBankSlipButtonEdit
                                onClick={() => {
                                  if (section.fields.length > 0) {
                                    setBankSlipSettingsFields(section.fields)
                                  }
                                  setEnableCreateBankSlip(true)
                                }}
                                data-testid="add-bank-slip-button"
                              >
                                <s.Icon name="pluscircle" fill="maincolor" />
                                <s.Text
                                  margin="0 0 0 8px"
                                  type="headline"
                                  fontWeight={500}
                                  color="maincolor"
                                >
                                  Adicionar
                                </s.Text>
                              </s.AddBankSlipButtonEdit>
                            ) : (
                              <></>
                            )}
                          </s.SectionHeader>
                        </s.Section>
                        <s.Line />
                      </React.Fragment>
                    )
                  }

                  return (
                    <React.Fragment key={section.key}>
                      <s.Section data-testid={section.key}>
                        <s.SectionHeader>
                          <s.TitleWrapper>
                            <s.Icon
                              name={section.icon as keyof typeof dictIcons}
                              fill="gray1"
                            />
                            <s.Text type="headline" color="graytheme6">
                              {section.friendlyName}
                            </s.Text>
                          </s.TitleWrapper>
                          {!section.toggled ? (
                            <s.Button
                              data-testid={`${section.key}-edit-button`}
                              onClick={() => toggleSection(section.key)}
                              width="98px"
                              color="white"
                              outline={false}
                            >
                              <s.Icon name="pencil" fill="maincolor" />
                              <s.Text
                                color="maincolor"
                                type="headline"
                                fontWeight={600}
                              >
                                Editar
                              </s.Text>
                            </s.Button>
                          ) : (
                            <></>
                          )}
                        </s.SectionHeader>
                        {section.fields.map((field) => {
                          return (
                            <React.Fragment key={field.key}>
                              {InputElement({
                                friendlyName: field.friendlyName,
                                inputType: field.inputType,
                                key: field.key,
                                value: field.value,
                                options: field.options,
                                maxLength: field.maxLength,
                                placeholder: field.placeholder,
                                valueType: field.valueType,
                                validators: field.validators,
                                toggled: section.toggled,
                              })}
                            </React.Fragment>
                          )
                        })}

                        {section.toggled ? (
                          <s.SectionLine>
                            <div></div>
                            <s.ButtonWrapper>
                              <s.CancelButton
                                color="white"
                                data-testid={`${section.key}-cancel-button`}
                                disabled={patchIsLoading}
                                onClick={() => {
                                  toggleSection(section.key)
                                  let sectionFields = {}
                                  let sectionErrors = {}

                                  for (const e of section.fields) {
                                    sectionFields[e.key] = formDataLoaded[e.key]
                                    sectionErrors[e.key] = ''
                                  }
                                  setForm({
                                    ...form,
                                    ...sectionFields,
                                  })
                                  setFormErrors({
                                    ...formErrors,
                                    ...sectionErrors,
                                  })
                                }}
                              >
                                <s.Text
                                  type="headline"
                                  fontWeight={600}
                                  color="maincolor"
                                >
                                  Cancelar
                                </s.Text>
                              </s.CancelButton>
                              <s.Button
                                data-testid={`${section.key}-save-button`}
                                disabled={
                                  patchIsLoading ||
                                  section.fields.reduce(
                                    (acc, cur) =>
                                      acc &&
                                      formDataLoaded[cur.key] === form[cur.key],
                                    true
                                  )
                                }
                                onClick={() => {
                                  let fields2Patch: FormData = {}
                                  for (const field of section.fields) {
                                    if (
                                      form[field.key] !==
                                      formDataLoaded[field.key]
                                    ) {
                                      fields2Patch[field.key] = form[field.key]
                                    }
                                  }
                                  if (editFormInfo.pspProvider === 'itau') {
                                    if (
                                      fields2Patch.bank_account &&
                                      !fields2Patch.bank_branch
                                    ) {
                                      fields2Patch.bank_branch =
                                        formDataLoaded.bank_branch
                                    } else if (
                                      fields2Patch.bank_branch &&
                                      !fields2Patch.bank_account
                                    ) {
                                      fields2Patch.bank_account =
                                        formDataLoaded.bank_account
                                    }
                                  }
                                  section.key === 'bank_slip_settings'
                                    ? handlePatchBankSlip(
                                        section.key,
                                        fields2Patch
                                      )
                                    : handlePatchWalletSettings(
                                        section.key,
                                        fields2Patch
                                      )
                                }}
                              >
                                {patchIsLoading ? (
                                  <s.Loading type="spinner" color="white" />
                                ) : (
                                  <s.Icon name="pencilUnderline" fill="white" />
                                )}
                                <s.Text
                                  type="headline"
                                  fontWeight={600}
                                  color="white"
                                >
                                  Salvar edição
                                </s.Text>
                              </s.Button>
                            </s.ButtonWrapper>
                          </s.SectionLine>
                        ) : (
                          <></>
                        )}
                      </s.Section>
                      <s.Line />
                    </React.Fragment>
                  )
                })}
              </s.SectionsEditFormWrapper>
            </s.Sections>
          )}
        </s.ModalContentDefault>
      </s.Modal>
    </>
  )
}

export default SettingsWSFormDefault
