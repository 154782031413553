import Modal from 'components/Modal'
import { showToast } from 'components/Toast'
import {
  getValueFromLocalStorage,
  setValueToLocalStorage,
} from 'helpers/localStorage'
import { applyCpfCnpjMask, applyPhoneMask } from 'helpers/masks'
import {
  isValidCnpj,
  isValidCpf,
  isValidEmail,
  isValidPhone,
} from 'helpers/validators'
import useGetUser from 'hooks/useGetUser'
import { usePatchUser } from 'hooks/user'
import { useNonInitialEffect } from 'hooks/utils'
import React from 'react'
import * as s from './styles'
import { UserSessionInfo } from 'store/modules/auth/types'

interface IProps {
  isOpen: string
  handleClose: () => any
}

const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
const capitals = /[A-Z]/

export const EditUserModal: React.FC<IProps> = ({ isOpen, handleClose }) => {
  const [cpfBlocked, setCpfBlocked] = React.useState(false)
  const {
    user,
    isLoading: isUserLoading,
    hasError: hasUserError,
    loadUser,
  } = useGetUser()

  const { patchResponse, patchError, editUser } = usePatchUser()

  const [userInfoForm, setUserInfoForm] = React.useState({
    first_name: '',
    last_name: '',
    phone: '',
    cpf: '',
    email: '',
    password: '',
    confirmPassword: '',
  })

  React.useEffect(() => {
    if (isOpen) {
      const currentUserSession: UserSessionInfo = JSON.parse(
        getValueFromLocalStorage('currentUserSession')
      )

      loadUser(currentUserSession.id)

      if (window.hj) {
        window.hj('stateChange', 'modal_edit_user_from_navbar')
      }

      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_path: 'modal_edit_user_from_navbar',
        })
      }
    }
  }, [isOpen])

  React.useEffect(() => {
    if (user.first_name) {
      setUserInfoForm({
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        cpf: user.cpf,
        email: user.email,
        password: '',
        confirmPassword: '',
      })
    }
    if (user.cpf && user.cpf.length) setCpfBlocked(true)
  }, [user])

  const handlePost = () => {
    let payload = userInfoForm
    delete payload.confirmPassword
    if (cpfBlocked) delete payload.cpf
    if (!payload.password) delete payload.password
    editUser(user.id, userInfoForm)
  }

  const _setUserInfoForm = (obj) => {
    setUserInfoForm({ ...userInfoForm, ...obj })
  }

  const [inputPressedValidator, setInputPressedValidator] = React.useState<any>(
    {
      ...Object.keys({
        ...userInfoForm,
      }).reduce((acc, key) => {
        acc[key] = false
        return acc
      }, {}),
    }
  )

  const cpfCnpjErrorHandler = () => {
    if (cpfBlocked) return false

    if (userInfoForm.cpf === '' && inputPressedValidator.cnpj) {
      return { message: 'Campo obrigatório' }
    } else if (
      !isValidCpf(userInfoForm.cpf) &&
      !isValidCnpj(userInfoForm.cpf)
    ) {
      return { message: 'esse CPF/CNPJ não existe' }
    } else {
      return false
    }
  }

  const isPasswordValid = () => {
    if (userInfoForm.password) {
      if (!userInfoForm.password.length) return false
      if (
        userInfoForm.password.length >= 8 &&
        inputPressedValidator.password &&
        format.test(userInfoForm.password) &&
        capitals.test(userInfoForm.password) &&
        userInfoForm.password === userInfoForm.confirmPassword
      ) {
        return true
      }
      return false
    }
    return true
  }

  const passwordErrorHandler = (idx: number) => {
    if (userInfoForm.password) {
      if (!userInfoForm.password.length) return 'maincolor'
      switch (idx) {
        case 1:
          if (
            userInfoForm.password.length < 8 &&
            inputPressedValidator.password
          ) {
            return 'redshipay'
          }
          return 'maincolor'
        case 2:
          if (!format.test(userInfoForm.password)) {
            return 'redshipay'
          }
          return 'maincolor'
        case 3:
          if (!capitals.test(userInfoForm.password)) {
            return 'redshipay'
          }
          return 'maincolor'
        default:
          return 'maincolor'
      }
    }
  }

  const emailErrorHandler = () => {
    if (userInfoForm.email === '' && inputPressedValidator.email) {
      return { message: 'Campo obrigatório' }
    } else if (!isValidEmail(userInfoForm.email)) {
      return { message: 'Email Inválido' }
    } else {
      return false
    }
  }

  const phoneErrorHandler = () => {
    if (userInfoForm.phone === '' && inputPressedValidator.phone) {
      return { message: 'Campo obrigatório' }
    } else if (!isValidPhone) {
      return { message: 'Telefone Inválido' }
    } else {
      return false
    }
  }

  const confirmPasswordErrorHandler = () => {
    if (
      userInfoForm.confirmPassword !== userInfoForm.password &&
      inputPressedValidator.confirmPassword
    ) {
      return { message: 'Senhas devem ser iguais' }
    } else {
      return false
    }
  }

  useNonInitialEffect(() => {
    if (patchResponse.code !== -1) {
      const currentUserSession: UserSessionInfo = JSON.parse(
        getValueFromLocalStorage('currentUserSession')
      )
      currentUserSession.name = `${userInfoForm.first_name} ${userInfoForm.last_name}`
      setValueToLocalStorage(
        'currentUserSession',
        JSON.stringify(currentUserSession)
      )
      showToast({
        type: 'success',
        message: 'Usuário editado com sucesso',
      })
      handleClose()
    }
  }, [patchResponse])

  useNonInitialEffect(() => {
    if (patchError) {
      showToast({
        type: 'error',
        message: 'Problema ao editar usuário',
      })
    }
  }, [patchError])

  if (isUserLoading) {
    return (
      <Modal
        handleClose={handleClose}
        isOpen={!!isOpen}
        modalSubtitle={'Perfil do usuário'}
      >
        <s.ModalContent>...Carregando</s.ModalContent>
      </Modal>
    )
  } else {
    return (
      <Modal
        handleClose={handleClose}
        isOpen={!!isOpen}
        modalSubtitle={'Perfil do usuário'}
      >
        <s.ModalContent>
          <s.InputGroup>
            <s.InputText
              maxLength={100}
              label="Nome"
              value={userInfoForm.first_name}
              onChange={(e) => _setUserInfoForm({ first_name: e.target.value })}
            />
          </s.InputGroup>

          <s.InputGroup>
            <s.InputText
              maxLength={100}
              label="Sobrenome completo"
              value={userInfoForm.last_name}
              onChange={(e) => _setUserInfoForm({ last_name: e.target.value })}
            />
          </s.InputGroup>

          <s.InputGroup>
            <s.InputText
              maxLength={100}
              label="Telefone"
              value={applyPhoneMask(userInfoForm.phone)}
              onChange={(e) => {
                _setUserInfoForm({ phone: e.target.value })
                setInputPressedValidator({
                  ...inputPressedValidator,
                  phone: true,
                })
              }}
              error={phoneErrorHandler()}
            />
          </s.InputGroup>

          <s.InputGroup>
            <s.InputText
              disabled={cpfBlocked}
              maxLength={100}
              label="CPF"
              value={applyCpfCnpjMask(userInfoForm.cpf)}
              onChange={(e) => {
                _setUserInfoForm({ cpf: e.target.value })
                setInputPressedValidator({
                  ...inputPressedValidator,
                  cpf: true,
                })
              }}
              error={cpfCnpjErrorHandler()}
            />
          </s.InputGroup>

          <s.InputGroup>
            <s.InputText
              maxLength={100}
              label="E-mail"
              value={userInfoForm.email}
              onChange={(e) => {
                _setUserInfoForm({ email: e.target.value })
                setInputPressedValidator({
                  ...inputPressedValidator,
                  email: true,
                })
              }}
              error={emailErrorHandler()}
            />
          </s.InputGroup>

          <s.InputGroup>
            <s.InputTextPassword
              maxLength={100}
              label="Nova senha"
              value={userInfoForm.password}
              onChange={(e) => {
                _setUserInfoForm({ password: e.target.value })
                setInputPressedValidator({
                  ...inputPressedValidator,
                  password: true,
                })
              }}
            />
          </s.InputGroup>

          <s.List>
            <s.Item color={passwordErrorHandler(1)}>
              mínimo 8 caracteres totais
            </s.Item>
            <s.Item color={passwordErrorHandler(2)}>
              ao menos 1 caractere especial
            </s.Item>
            <s.Item color={passwordErrorHandler(3)}>
              ao menos 1 caractere maiúsculo
            </s.Item>
          </s.List>
          {!!userInfoForm.password ? (
            <s.InputGroup>
              <s.InputTextPassword
                maxLength={100}
                label="Confirmar nova senha"
                value={userInfoForm.confirmPassword}
                onChange={(e) => {
                  _setUserInfoForm({ confirmPassword: e.target.value })
                  setInputPressedValidator({
                    ...inputPressedValidator,
                    confirmPassword: true,
                  })
                }}
                error={confirmPasswordErrorHandler()}
              />
            </s.InputGroup>
          ) : null}

          <s.ButtonGroup>
            <s.Button
              disabled={!isPasswordValid()}
              onClick={() => handlePost()}
            >
              Salvar
            </s.Button>
            <s.Button color="white" outline onClick={() => handleClose()}>
              Cancelar
            </s.Button>
          </s.ButtonGroup>
        </s.ModalContent>
      </Modal>
    )
  }
}
