import React, { useEffect } from 'react'
import Modal from 'components/Modal'
import * as s from './styles'

import { usePostRetailChain } from 'hooks/retailChain'
import { useNonInitialEffect } from 'hooks/utils'
import { isNotEmpty, isValidEmail } from 'helpers/validators'
import { isConexaoItau } from 'helpers'

import { showToast } from 'components/Toast'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  posProductList: any[]
}

const CreateRetailChain: React.FC<IProps> = ({
  isOpen,
  handleClose,
  posProductList,
}) => {
  const { createRetailChain, postIsLoading, postResponse, postError } =
    usePostRetailChain()

  const [formErrors, setFormErrors] = React.useState({
    name: '',
    email: '',
    pos_product_id: '',
  })

  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    pos_product_id: '',
  })
  const [selected, setSelected] = React.useState({})
  const [posProductSelectList, setPosProductSelectList] = React.useState<any[]>(
    []
  )

  const _setFormData = (obj) => {
    setFormData({ ...formData, ...obj })
    _validateForm(obj)
  }

  const _validateForm = (obj) => {
    const { name, email } = obj
    const errObj = {}
    if (name !== undefined) {
      const err = isNotEmpty(name) ? '' : 'Campo Obrigatório'
      errObj['name'] = err
    }
    if (email !== undefined) {
      const err = isValidEmail(email) ? '' : 'Email Inválido'
      errObj['email'] = err
    }
    setFormErrors({ ...formErrors, ...errObj })
    if (Object.values(errObj).some((x) => x)) {
      return false
    }
    return true
  }

  const _clearForm = () => {
    setFormData({
      name: '',
      email: '',
      pos_product_id: '',
    })
    setFormErrors({
      name: '',
      email: '',
      pos_product_id: '',
    })
  }

  const onCreateRetailChain = () => {
    if (!_validateForm(formData)) {
      showToast({
        type: 'error',
        message: 'Por favor preencha o formulário corretamente!',
      })
      return
    }
    createRetailChain(formData)
  }

  useEffect(() => {
    if (posProductList && posProductList.length > 0) {
      _setFormData({ pos_product_id: posProductList[0].id })
      setSelected({
        id: posProductList[0].id,
        name: posProductList[0].name,
      })

      if (window.hj) {
        window.hj('stateChange', 'modal_create_retail_chain')
      }

      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_path: 'modal_create_retail_chain',
        })
      }
    }
  }, [])

  useNonInitialEffect(() => {
    if (postResponse.code !== -1) {
      _clearForm()
      handleClose()
      showToast({
        type: 'success',
        message: `${
          isConexaoItau() ? 'Grupo criado' : 'Rede criada'
        } com sucesso`,
      })
    }
  }, [postResponse])

  useNonInitialEffect(() => {
    if (postError) {
      showToast({
        type: 'error',
        message: `Problema ao criar ${isConexaoItau() ? 'grupo' : 'rede'}`,
      })
    }
  }, [postError])

  useNonInitialEffect(() => {
    setPosProductSelectList(
      posProductList.map((x) => {
        return {
          id: x.id,
          text: x.name,
          onClick: () => {
            setSelected({ id: x.id, name: x.name })
            _setFormData({ pos_product_id: x.id })
          },
        }
      })
    )

    const firstOption = posProductList[0]
    setSelected({ id: firstOption.id, name: firstOption.name })
    _setFormData({ pos_product_id: firstOption.id })
  }, [posProductList])

  return (
    <Modal
      handleClose={() => {
        _clearForm()
        handleClose()
      }}
      isOpen={isOpen}
      modalSubtitle={`Criar ${isConexaoItau() ? 'Grupo' : 'Rede'}`}
    >
      <s.ModalContent>
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label={isConexaoItau() ? 'Nome do Grupo' : 'Nome da Rede'}
            value={formData.name}
            onChange={(e) => _setFormData({ name: e.target.value })}
            error={formErrors.name ? { message: formErrors.name } : false}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputText
            maxLength={100}
            label="E-mail"
            value={formData.email}
            onChange={(e) => _setFormData({ email: e.target.value })}
            error={formErrors.email ? { message: formErrors.email } : false}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.Text bold color="maincolor" type="headline">
            Sistema de PDV
          </s.Text>
          <s.Select
            options={posProductList.map((x, index) => {
              return {
                id: x.id,
                text: x.name,
                onClick: () => {
                  setSelected({ id: x.id, name: x.name })
                  _setFormData({ pos_product_id: x.id })
                },
              }
            })}
            selected={selected}
          />
        </s.InputGroup>
        <s.ButtonGroup>
          <s.Button onClick={() => onCreateRetailChain()}>Criar</s.Button>
        </s.ButtonGroup>
      </s.ModalContent>
    </Modal>
  )
}

export default CreateRetailChain
