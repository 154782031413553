import { DependencyList, useCallback, useEffect, useState } from 'react'

import { get, post, put, remove, patch } from 'services/api'
import { useErrors } from './useErrors'
import useAbortController from './useAbortController'

type apiCall<Data> = typeof get<Data> | typeof post<Data> | typeof put<Data> | typeof remove<Data> | typeof patch<Data>

function useApiCall<Data>(fn: apiCall<Data>, path: string, params: any, dependencies: DependencyList) {
  const abortController = useAbortController()
  const { errors, push: pushErrors, dismiss, dismissAll } = useErrors()
  const [data, setData] = useState<Data>()
  const [loading, setLoading] = useState(false)
  const _fn = useCallback(() => {
    setLoading(true)
    
    fn(path, {
      ...params,
      signal: abortController.signal
    }).then(response => setData(response.data)).catch(pushErrors).finally(() => setLoading(false))
  }, [fn, params, dependencies])

  return {
    loading,
    errors,
    data,
    apiCall: _fn,
    dismiss,
    dismissAll
  }
}

function useApiGet<Data>(path: string, params: any, dependencies?: DependencyList) {
  return useApiCall(get<Data>, path, params, dependencies)
}
function useApiPost<Data>(path: string, params: any, dependencies?: DependencyList) {
  return useApiCall(post<Data>, path, params, dependencies)
}
function useApiPatch<Data>(path: string, params: any, dependencies?: DependencyList) {
  return useApiCall(patch<Data>, path, params, dependencies)
}
function useApiPut<Data>(path: string, params: any, dependencies?: DependencyList) {
  return useApiCall(put<Data>, path, params, dependencies)
}
function useApiDelete<Data>(path: string, params: any, dependencies?: DependencyList) {
  return useApiCall(remove<Data>, path, params, dependencies)
}

export {
  useApiCall,
  useApiGet,
  useApiPost,
  useApiPatch,
  useApiPut,
  useApiDelete
}
