import { useState } from 'react'
import * as s from './styles'
import { applyCpfCnpjMask } from 'helpers/masks'
import { StoreAssociationProps, Stores } from './index'

export const StoreAssociation: React.FC<StoreAssociationProps> = ({
  stores = [],
  selectedStores,
  setSelectedStores,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const uniqueStores: Array<Stores> = stores?.reduce((acc, current) => {
    const isDuplicate = acc.some((item) => item.id === current.id)
    if (!isDuplicate) {
      acc.push(current)
    }
    return acc
  }, [])

  const filteredStores = uniqueStores?.filter(
    (store) =>
      store.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      applyCpfCnpjMask(store.cnpj_cpf).includes(searchQuery)
  )

  const handleStoreClick = (storeId: string) => {
    if (selectedStores.includes(storeId)) {
      setSelectedStores(selectedStores.filter((id) => id !== storeId))
    } else {
      setSelectedStores([...selectedStores, storeId])
    }
  }

  const handleSelectAllClick = () => {
    if (selectedStores.length === filteredStores.length) {
      setSelectedStores([])
    } else {
      const allStoreIds = filteredStores.map((store) => store.id)
      setSelectedStores(allStoreIds)
    }
  }

  if (stores.length === 0) {
    return (
      <s.Sections data-testid="content-on-store-association">
        <s.StoreAssociationWrapper>
          <s.Text bold margin="32px 0 0 0" type="heading4" color="graytheme6">
            Nenhuma loja disponível para associação
          </s.Text>
        </s.StoreAssociationWrapper>
      </s.Sections>
    )
  }

  return (
    <s.Sections data-testid="content-on-store-association">
      <s.StoreAssociationWrapper>
        <s.Text bold margin="32px 0 0 0" type="heading4" color="graytheme6">
          Associe sua conta com suas lojas
        </s.Text>

        <s.SectionGrid>
          <s.InputSearchText
            placeholder="Nome ou CNPJ"
            value={searchQuery}
            suffix={<s.Icon fill="maincolor" name="search" />}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <s.ButtonSelection
            data-testid="select-all-button"
            color="whitedetailed"
            onClick={handleSelectAllClick}
          >
            <s.Icon name="checkmarksquaredoutline" fill="maincolor" />
            <s.Text fontWeight={600} type="headline" color="graytheme6">
              {selectedStores.length === filteredStores.length
                ? 'Cancelar seleção'
                : 'Selecionar todas'}
            </s.Text>
          </s.ButtonSelection>
          {filteredStores.map((store) => (
            <s.RoundedSquareStoreWrapper key={store.id}>
              <s.StoreInfoWrapper>
                <s.StoreNameText
                  bold
                  textAlign="left"
                  type="headline"
                  color="maincolor"
                >
                  {store.name}
                </s.StoreNameText>
                <s.Text bold textAlign="left" type="tag" color="maincolor">
                  {applyCpfCnpjMask(store.cnpj_cpf)}
                </s.Text>
              </s.StoreInfoWrapper>
              <s.RoundedSquareColumnContainer>
                <s.AddCheckStoreButton
                  data-testid="add-check-store-button"
                  checked={selectedStores.includes(store.id)}
                  onClick={() => handleStoreClick(store.id)}
                >
                  <s.CheckSquare>
                    <s.Icon name="checkmark" fill="white" />
                  </s.CheckSquare>
                  <s.Text fontWeight={600} type="headline" color="maincolor">
                    {selectedStores.includes(store.id)
                      ? 'Selecionado'
                      : 'Selecionar'}
                  </s.Text>
                </s.AddCheckStoreButton>
              </s.RoundedSquareColumnContainer>
            </s.RoundedSquareStoreWrapper>
          ))}
        </s.SectionGrid>
      </s.StoreAssociationWrapper>
    </s.Sections>
  )
}
