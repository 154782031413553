import React, { useEffect } from 'react'
import * as s from './styles'

declare global {
  interface Window {
    idwSDKWeb: (config: {
      token: string
      onRender: () => void
      onComplete: ({ token }: { token: string }) => void
      onError: (error: any) => void
    }) => void
  }
}

interface IdWallSdkIframeProps {
  accessToken: string
  handleOnSDKComplete: (token: string) => void
  handleOnSDKError: (error: any) => void
}

const IdWallSdkIframe: React.FC<IdWallSdkIframeProps> = ({
  accessToken,
  handleOnSDKComplete,
  handleOnSDKError,
}) => {
  useEffect(() => {
    if (window.idwSDKWeb) {
      window.idwSDKWeb({
        token: accessToken,
        onRender: () => {
          console.log('SDK IdWall has been rendered!')
        },
        onComplete: ({ token }: { token: string }) => {
          handleOnSDKComplete(token)
        },
        onError: (error: any) => {
          handleOnSDKError(error)
        },
      })
    }
  }, [accessToken])

  return (
    <s.IFrameWrapper>
      <div
        data-testid="idwall-sdk-web"
        id="idwall-sdk-iframe"
        data-idw-sdk-web
      ></div>
    </s.IFrameWrapper>
  )
}

export default IdWallSdkIframe
