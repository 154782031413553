import React from 'react'

import { IconName, ThemeColorNames } from 'components/IconExporter'
import { FontType } from 'components/Text'

import { FilesModal } from './FilesModal'
import { ApiRequestFilePayload, FileExtension } from './FileApi'

import * as s from './styles'

interface FilesModalButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<ApiRequestFilePayload, 'format'> {
  iconName?: IconName
  iconColor?: ThemeColorNames
  iconFill?: ThemeColorNames
  textType?: FontType
  textColor?: ThemeColorNames
  rowsCount?: number
  fileType: 'sales_file' | 'payments_file'
  ordersCount?: number
  initialDate: string
  finalDate: string
  maxPeriodInDays?: number
  initialFormat: FileExtension
}

export const FilesModalButton: React.FC<
  React.PropsWithChildren<FilesModalButtonProps>
> = ({
  iconColor,
  iconName,
  iconFill,
  textType,
  textColor,
  children,
  rowsCount,
  fileType,
  filters,
  orderBy,
  sort,
  ordersCount,
  initialDate,
  finalDate,
  initialFormat,
  maxPeriodInDays = 31,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const fileModal = React.useMemo(() => {
    if (isModalOpen) {
      return (
        <FilesModal
          title={String(children)}
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          fileType={fileType}
          size={rowsCount}
          filters={filters}
          initialFormat={initialFormat}
          orderBy={orderBy}
          sort={sort}
          ordersCount={ordersCount}
          initialDate={initialDate}
          finalDate={finalDate}
          maxPeriodInDays={maxPeriodInDays}
        />
      )
    } else {
      return null
    }
  }, [isModalOpen, rowsCount])
  return (
    <>
      {fileModal}
      <s.SalesFilesButton {...props} onClick={() => setIsModalOpen(true)}>
        <s.ButtonIcon name={iconName} color={iconColor} fill={iconFill} />
        <s.Text type={textType} color={textColor}>
          {children}
        </s.Text>
      </s.SalesFilesButton>
    </>
  )
}
