import styled from 'styled-components'
import IconCmp from 'components/IconExporter'
import SelectCmp from 'components/Inputs/Select'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'

export const NewSelect = styled(SelectCmp)`
  width: 260px;
  p {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`

export const Select = styled(SelectCmp)`
  width: 304px;
`

export const Icon = styled(IconCmp)``

export const InputToggleGroup = styled.div`
  display: flex;
`

export const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const SelectToggleGroup = styled.div`
  display: flex;
`

export const NewInputGroupPixKey = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 32px;
`

export const SectionLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`

export const Text = styled(TextCmp)`
  > li {
    margin-bottom: 8px;
  }
`

export const InputText = styled(InputTextCmp)`
  margin-top: 8px;
  margin-bottom: 0px;
  svg {
    margin: 0px 0px 2px 0px;
  }
`

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.maincolor};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
`

export const AccordionBox = styled.div<{ isExpanded: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.graytheme3};
  border-radius: 8px;
  padding: 12px 16px;

  transition: height 1s ease;
  height: ${({ isExpanded }) => (isExpanded ? 'auto' : 'auto')};
`

export const AccordionTitle = styled.div`
  display: flex;
  width: inherit;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`

export const DisplayText = styled.div<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
`

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  margin: 12px 0;
`
