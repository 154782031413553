import styled, { css, keyframes } from 'styled-components'
import TextCmp from 'components/Text'
import InputTextCmp from 'components/Inputs/Text'
import SelectCmp from 'components/Inputs/Select'
import ButtonCmp from 'components/Button'
import ModalCmp from 'components/Modal'
import { ModalContainer, ChildrenWrapper } from 'components/Modal/styles'
import ButtonLinkCmp from 'components/Button/ButtonLink'
import CheckboxCmp from 'components/Inputs/Checkbox'
import RadioCmp from 'components/Inputs/RadioButton'
import LoadingCmp from 'components/Loading'
import IconExporter from 'components/IconExporter'
import SearchSelectCmp from 'components/Inputs/SearchSelect'
import CloseIcon from 'components/IconExporter'
import { MessageBoxOutline } from './components/styles'
import ModalButtonFooterCmp from 'components/Button/ModalButtonFooter'
import { isSpacePay } from 'helpers'
import DatepickerCmp from 'components/Datepicker'
import { themeColors } from 'styles/theme'
import UploadFileCmp from 'components/UploadFile/UploadFileButtonArea'
import { InitialSteps } from './WalletSettingsFormPixSimples'

export const ModalTitle = styled(TextCmp)`
  background: linear-gradient(
    ${({ theme }) => theme.colors.lightmaincolor4},
    ${({ theme }) => theme.colors.maincolor}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`

export const ModalContent = styled.div`
  width: 450px;
`

// export const ItauModalContent = styled.div`
//   width: 500px;

//   * > ol {
//     margin-bottom: 32px;
//     padding-left: 24px;
//     list-style-position: inside decimal;
//     ${({ theme }) => theme.fontTypes.headline};
//     color: ${({ theme }) => theme.colors.graytheme7};
//     > li {
//       margin-bottom: 8px;
//       white-space: pre-line;
//     }
//   }
// `

interface ItauModalContentProps {
  isIdWallStep?: boolean
}
export const ItauModalContent = styled.div<ItauModalContentProps>`
  position: relative;
  width: 520px;
  height: ${({ isIdWallStep }) => (isIdWallStep ? '700px' : '600px')};
  margin: 0px 32px 32px 32px;
  height: 520px;

  @media (max-height: 600px) {
    height: 350px;
  }

  @media (min-height: 601px) and (max-height: 800px) {
    height: ${({ isIdWallStep }) => (isIdWallStep ? '530px' : '430px')};
  }

  @media (min-height: 801px) and (max-height: 940px) {
    height: ${({ isIdWallStep }) => (isIdWallStep ? '620px' : '520px')};
  }

  @media (min-height: 941px) and (max-height: 1920px) {
    height: 690px;
  }
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-width: 532px;
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 42px;
  }

  height: calc(100% - 75px);
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
`

export const SectionsDestinationAccountFormWrapper = styled.div`
  padding-right: 32px;
`

export const Div = styled.div``

export const DocumentContainer = styled.div`
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  padding: 8px;

  @media screen and (${({ theme }) => theme.mediaQueries.xlUp}) {
    height: 300px;
  }
`

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`

interface ButtonWrapperProps {
  margin?: string
  isIdWallStep?: boolean
}

export const PreviousNextButtonContainer = styled.div<ButtonWrapperProps>`
  position: absolute;
  width: 100%;
  bottom: 0;
  background: white;

  div:first-child {
    width: calc(100%+32px);
    height: 1px;
    margin: 0px -32px 32px -32px;
    border-bottom: ${({ isIdWallStep }) =>
      isIdWallStep
        ? css`none`
        : css`1px solid ${({ theme }) => theme.colors.graytheme3}`};
  }
`
export const PreviousNextButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: ${({ margin }) => margin};

  :has(:only-child) {
    justify-content: flex-end;
  }
`

export const Text = styled(TextCmp)`
  white-space: pre-wrap;
`

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.maincolor};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
`

export const BanklineText = styled(TextCmp)`
  white-space: pre-line;
`

export const LinkableBold = styled.b`
  color: ${({ theme }) => theme.colors.maincolor};
  text-decoration: underline;
  cursor: pointer;
`

export const BanklineWrapper = styled.div`
  p {
    :first-child {
      margin-bottom: 32px;
    }
  }

  h5 {
    margin-bottom: 32px;
  }
`

export const SubText = styled(TextCmp)`
  width: 90%;
`

export const InputText = styled(InputTextCmp)``

export const InputGroup = styled.div`
  margin-bottom: 30px;
`

export const Select = styled(SelectCmp)`
  width: 273px;
`
export const InputSelectDestinationAccount = styled(SelectCmp)`
  div > div > p {
    line-height: 21px;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.graytheme6};
    font-weight: normal;
  }
  > div > span > p {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500px;
    color: ${({ theme }) => theme.colors.cancelledStatusTextColor};
  }
`

interface TitleWrapperProps {
  margin?: string
}
export const TitleWrapper = styled.div<TitleWrapperProps>`
  margin: ${({ margin }) => (margin ? margin : '32px 0px 0px 0px')};
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SelectPhoneType = styled(SelectCmp)`
  width: 300px;
`

export const SelectTLS = styled(SelectCmp)`
  margin-top: 8px;
  width: 450px;
`

export const SearchSelect = styled(SearchSelectCmp)`
  width: 273px;
`

export const ModalWrapper = styled.div`
  position: relative;
  justify-content: space-around;
`

export const Button = styled(ButtonCmp)`
  > span {
    text-transform: none;
  }
`

export const ButtonAnybank = styled(ButtonCmp)`
  position: absolute;
  height: 54px;
  width: 250px;

  > span {
    text-transform: none;
    font-size: 14px;
  }
`

export const LeftButtonAnybank = styled(ButtonCmp)`
  position: absolute;
  height: 54px;
  width: 250px;
  left: 10px;

  > span {
    text-transform: none;
    font-size: 14px;
  }
`

export const RightButtonAnybank = styled(ButtonCmp)`
  position: absolute;
  height: 54px;
  width: 250px;
  right: 10px;

  > span {
    text-transform: none;
    font-size: 14px;
  }
`

export const WelcomeButtonItauAnybank = styled(ButtonCmp)`
  position: absolute;
  right: 10px;
  svg {
    margin-left: 4px;
  }
`

export const TbanksButtonWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const LeftButtonTbanksAnybank = styled(ButtonCmp)`
  position: absolute;
  height: 54px;
  width: 154px;
  left: 0px;
  top: 36px;

  > span {
    text-transform: none;
    font-size: 14px;
  }
`

export const RightButtonTbanksAnybank = styled(ButtonCmp)`
  position: absolute;
  height: 54px;
  width: 154px;
  right: 0px;
  top: 36px;
  > span {
    text-transform: none;
    font-size: 14px;
  }
`

export const ButtonGroupAnybank = styled.div`
  position: sticky;
  width: 100%;
  bottom: 10px;

  Button {
    :nth-child(1) {
      left: 16px;
      box-shadow: 0px 1px 8px 2px #a3bacc;
    }
    :nth-child(2) {
      left: auto;
      right: 16px;
    }
  }
`

export const ButtonGroup = styled.div`
  text-align: center;
  margin-top: 20px;
  Button {
    margin-right: 20px;
    :last-child() {
      margin-right: 0px;
    }
  }
`

export const Modal = styled(ModalCmp)<{ isFromConexaoItau?: boolean }>`
  ${({ isFromConexaoItau }) =>
    isFromConexaoItau
      ? css`
          header {
            background: ${({ theme }) => theme.colors.white};
            border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
            padding: 32px;
            h5 {
              color: ${({ theme }) => theme.colors.graytheme6};
            }
          }

          ${ModalContainer} {
            padding: 0px;
          }

          ${ChildrenWrapper} {
            padding: 0px;
            overflow-y: hidden;

            ::-webkit-scrollbar-track {
              margin-top: 32px;
              margin-bottom: 32px;
            }
          }
        `
      : css`
          ${ChildrenWrapper} {
            overflow: hidden;
          }

          ${ModalContainer} {
            padding: 0px;
          }
        `}
`

export const SuccessModal = styled(ModalCmp)`
  z-index: 10;
  ${ModalContainer} {
    padding: 0px;
  }
`

interface PixSimplesModalContainerProps {
  provider?: string
  step?: InitialSteps
}

function handleModalContainerSize(
  provider: string,
  step: InitialSteps
): number {
  if (step.tbanksRepresentantForm) {
    return 622
  }
  return 890
}

export const PixSimplesModalContainer = styled.div<PixSimplesModalContainerProps>`
  width: ${({ provider, step }) =>
    `${handleModalContainerSize(provider, step)}px`};
  height: 1000px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 48px 80px 64px;
  width: auto;
  height: calc(100vh - 220px);
  margin: 16px 16px 0 0;
  overflow-y: auto;
  > * {
    margin-bottom: 16px;
  }
  > * > ul {
    margin-left: 32px;
    > li {
      margin-bottom: 8px;
    }
  }
  > div {
    :last-child {
      margin-top: 0px;
    }
  }
`

export const ButtonLink = styled(ButtonLinkCmp)`
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 19px;
  /* margin-left: 32px; */
`

export const Checkbox = styled(CheckboxCmp)`
  width: 24px;
`

export const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
  text-align: right;

  &:hover {
    fill: ${(props) =>
      !isSpacePay() ? props.theme.colors.maincolor : props.theme.colors.black};
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
`

export const HeaderWrapper = styled.div`
  margin-left: 16px;
`

export const CompanyFormWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 36px;
  display: flex;
  justify-content: space-around;

  > div > * {
    margin-bottom: 24px;
  }
`

export const RepresentantFormWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  overflow-y: auto;

  > div > * {
    margin-bottom: 24px;
  }
`

export const AnyBankItauRepresentantFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: auto;
  padding: 32px 32px 0 0;
  @media screen and (min-height: 1000px) and (orientation: landscape) {
    padding-bottom: 20vh;
  }
  > div > * {
    margin-bottom: 24px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 32px;
  }
`

export const AnyBankItauIdentityValidationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: auto;
  padding: 0px 16px 0px 0;
  @media screen and (min-height: 1000px) and (orientation: landscape) {
    padding-bottom: 20vh;
  }
  > div > * {
    margin-bottom: 24px;
  }

  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 38px;
  }
`

export const AnyBankItauDocumentsValidationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: auto;
  padding: 0 32px 0 0;

  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`

interface UploadDocumentButtonAreaProps {
  alignFileRules?: 'row' | 'column'
}

export const UploadDocumentButtonArea = styled(
  UploadFileCmp
)<UploadDocumentButtonAreaProps>`
  button {
    width: 100%;
    height: 118px;
  }

  & > div:last-child {
    ${({ alignFileRules }) => {
      if (alignFileRules === 'row') {
        return css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          h5 {
            font-weight: 400;
            font-size: 14px;
          }
        `
      }
      return css`
        h5 {
          font-weight: 400;
          font-size: 14px;
        }
      `
    }}
  }
`

export const AnyBankTbanksRepresentantFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  border-radius: 10px;
  padding: 36px;
  margin-bottom: 48px;
  margin-top: 32px;

  > div > * {
    margin-bottom: 24px;
  }
`

export const AnyBankItauCompanyFormWrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const AnyBankItauCompanyFormHeader = styled.div`
  padding: 15px 0px 15px 0px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.whiteshipay};

  p > svg {
    margin-left: 25px;
    margin-right: 8px;
  }
`

export const AnyBankItauCompanyFormCard = styled.div`
  border-radius: 8px;
  margin-top: 25px;
  padding: 30px 20px 30px 20px;
  background: ${({ theme }) => theme.colors.whiteshipay};
`

export const AnyBankItauExpandibleDiv = styled.div`
  margin: 15px 0px 0px 0px;
  padding: 25px 5px 0px 5px;
  border-top: 1px solid ${(props) => props.theme.colors.graytheme2};

  > p {
    font-size: 16px;
    margin: 15px 0px 15px 0px;
  }
`

export const scaleAnimationIn = keyframes`
  0% {
    transform: scale(1, 0);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1, 1);
    animation-timing-function: ease-out;
  }
`

export const CollapseTextRender = styled.div<isOpen>`
  height: 0px;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      height: 100%;
      visibility: visible;
      opacity: 1;

      animation: ${scaleAnimationIn} 0.5s;
    `}
`

interface isOpen {
  isOpen: boolean
}

export const AnyBankItauBooleanInputWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 28%;
  justify-content: space-between;
  gap: 32px;
  margin-top: 24px;
`

export const RadioBox = styled(RadioCmp)`
  padding: 0px 10px 0px 10px;
  max-width: 80px;
  border: 2px solid ${(props) => props.theme.colors.maincolor};
  border-radius: 8px;

  > input {
    &:before {
      border: 2px solid ${(props) => props.theme.colors.maincolor};
    }
  }
  > label {
    font-weight: 600;
    font-size: 15px;
  }
`

export const AnyBankItauInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  padding-left: 8px;
  gap: 16px;
`

export const AnyBankItauDestinationAccountInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  padding-left: 8px;
  gap: 32px;
  margin-top: 32px;
`

export const AnyBankTbanksRepresentantInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const AnyBankSubHeaderText = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  padding-left: 8px;
  margin-bottom: 16px;
  margin-top: -16px;
  > svg {
    margin-right: 8px;
  }
`

export const AnyBankSuccessPixText = styled(TextCmp)`
  b {
    color: ${({ theme }) => theme.colors.maincolor};
    text-decoration: underline;
    cursor: pointer;
  }
`

export const AnyBankPixText = styled(TextCmp)`
  b {
    color: ${({ theme }) => theme.colors.maincolor};
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Company = styled.div`
  width: 45%;
  height: 48%;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  border-radius: 10px;
  padding-left: 32px;
  padding-top: 16px;
`

export const BankAccount = styled.div`
  width: 45%;
  height: 98%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  padding-left: 32px;
  padding-right: 12px;
  padding-top: 16px;
`

export const CompanyTbanks = styled.div`
  width: 45%;
  height: 98%;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  border-radius: 10px;
  padding-left: 32px;
  padding-top: 16px;
`

export const BankAccountTbanks = styled.div`
  width: 45%;
  height: 60%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  padding-left: 32px;
  padding-right: 12px;
  padding-top: 16px;
`

export const InputWrapper = styled.div`
  width: 55%;
  padding-left: 32px;
  padding-top: 16px;
  height: fit-content;
`

export const Loading = styled(LoadingCmp)``

export const SelectState = styled(SelectCmp)`
  width: 273px;

  > div > div > p {
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`

export const MessageBox = styled.div`
  background-color: ${({ theme }) => theme.colors.graytheme1};
  max-height: calc(90vh - 150px);
  width: 100%;
  padding: 32px 0;
  > p {
    margin-bottom: 8px;
    margin-left: 16px;
    > ul {
      margin-left: 32px;
    }
  }
`

export const HeaderMessageBoxOutline = styled(MessageBoxOutline)`
  display: flex;

  b {
    color: ${({ theme }) => theme.colors.maincolor};
    cursor: pointer;
  }
`

export const Icon = styled(IconExporter)``

export const TokenFormData = styled.div``

export const TokenFormCheck = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const TokenFormCpf = styled.div``

export const TokenFormName = styled.div``

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const IconRound = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 16px;
  color: white;
  text-align: center;
  line-height: 30px;
  background-color: #28835c;
`

export const SuccessModalWrapper = styled.div`
  width: 500px;
  padding: 16px;
`

interface IPixAnyBankHeader {
  provider: string
}

const handleHeaderColor = (provider: string) => {
  switch (provider) {
    case 'original':
      return css`
        background: linear-gradient(180deg, #3cdd6f 0%, #18ad47 100%);
        > img {
          margin-top: 35px;
        }
      `
    case 'itau_anybank_v2':
      return css`
        background: linear-gradient(180deg, #f98f53 0%, #e55f13 100%);
        > img {
          margin-top: 0px;
        }
      `
    case 'tbanks':
      return css`
        background: linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
        > img {
          margin-top: 45px;
        }
      `
  }
}

export const PixAnyBankHeader = styled.div<IPixAnyBankHeader>`
  height: 128px;
  ${({ provider }) => handleHeaderColor(provider)}
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > h5 {
    margin-bottom: 24px;
  }
`

export const PixAnyBankHeaderRede = styled.div<IPixAnyBankHeader>`
  height: 128px;
  ${({ provider }) =>
    provider === 'original' ||
    provider === 'itau_anybank_v2' ||
    provider === 'tbanks'
      ? css`
          background: linear-gradient(180deg, #595959 0%, #282828 100%);
        `
      : css`
          background: linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
        `};
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > img {
    margin-top: 25px;
  }
  > h5 {
    margin-bottom: 24px;
  }
`

export const InfoBox = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  padding: 8px;
  border-radius: 8px;
  margin: 8px 0;
`

export const AnybankItauSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 75px);
  gap: 16px;
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`

export const AnybankItauErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 75px);
  gap: 16px;
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`

export const IconBox = styled.div`
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 0;
  > ul {
    color: ${({ theme }) => theme.colors.graytheme6};
    margin: 16px 0 0 24px;

    > li {
      margin-bottom: 16px;
    }
  }
`

export const Header = styled.div`
  position: absolute;
  width: 676px;
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 750px;
  height: 100px;
  background: ${({ theme }) =>
    `linear-gradient(180deg, rgba(251, 252, 253, 0) 0%, ${theme.colors.white} 10%)`};
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  align-items: center;
  /* background: ${({ theme }) => theme.colors.white}; */

  ${CheckboxWrapper} {
    margin-left: 10px;
    align-self: center;
  }
`

export const LoadingWrapper = styled.div`
  margin-top: 32px;
`

export const LoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 114px;
  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media screen and (max-width: 1440px) {
    margin-top: 84px;
  }
`

export const NewFooter = styled.div``

export const SectionLine = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;

  :first-child {
    margin-top: 0px;
  }
`

export const SectionLineCenter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  :first-child {
    margin-top: 0px;
  }
`

export const ListBoxOutline = styled.div`
  border: 1px solid ${themeColors.gray3};
  border-radius: 8px;
  padding: 12px;
  text-align: justify;
`
export const List = styled.ul`
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.graytheme6};
`

export const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.graytheme6};
`

export const ModalButtonFooter = styled(ModalButtonFooterCmp)``

export const Datepicker = styled(DatepickerCmp)`
  margin-bottom: 24px;
`

interface ImageWrapperProps {
  width?: number
  height?: number
}
export const ImageWrapper = styled.div<ImageWrapperProps>`
  margin: 54px auto 0px auto;

  @media screen and (max-width: 1440px) {
    margin: 16px auto 0px auto;
  }
  img {
    width: ${({ width }) => (width ? `${width}px` : '491px')};
    height: ${({ height }) => (height ? `${height}px` : '248px')};
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px 0px 16px 0px;
`

export const ImageErrorWrapper = styled.div<ImageWrapperProps>`
  margin: auto auto 16px auto;

  img {
    width: ${({ width }) => (width ? `${width}px` : '491px')};
    height: ${({ height }) => (height ? `${height}px` : '248px')};
  }
`
