import { DependencyList, useCallback, useEffect, useState } from 'react'

import { graphqlPost, GraphQLPostPayload } from 'services/api'
import { useErrors } from './useErrors'
import useAbortController from './useAbortController'


export function useGraphQLCall<Data extends object = any, Variables extends object = any>(
  payload: GraphQLPostPayload<Variables> ,
  dependencies: DependencyList
) {
  const abortController = useAbortController()
  const { errors, push: pushErrors, dismiss, dismissAll } = useErrors()
  const [data, setData] = useState<Data>()
  const [loading, setLoading] = useState(false)
  const _fn = useCallback(() => {
    setLoading(true)
    return graphqlPost<Data, Variables>({
      ...payload
    }, {
      signal: abortController.signal,
    })
      .then(response => {
        if ('data' in response.data) {
          setData(response.data.data)
          return response.data
        } else {
          pushErrors(response.data['errors'])
        }
      })
      .catch(pushErrors).finally(() => setLoading(false))
  }, [dependencies])

  return {
    loading,
    errors,
    data,
    apiCall: _fn,
    dismiss,
    dismissAll
  }
}
