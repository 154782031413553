import IdWallSdkIframe from 'components/IdWallSdkIframe'
import { IDWall_SDK_TOKEN } from '../../../../environment/env'
import { initialSteps, InitialSteps } from '..'
import {
  post,
  baseURLVerifyUsersLegalRepresentativesExternalProfile,
} from 'services/api'
import React from 'react'

interface AnybankItauIdWallSdkProps {
  registrationId: string
  setStep: (step: InitialSteps) => void
  setIsLoadingAnyBank?: React.Dispatch<React.SetStateAction<boolean>>
}

const AnyBankItauIdWallSdkRender: React.FC<AnybankItauIdWallSdkProps> = ({
  registrationId,
  setStep,
  setIsLoadingAnyBank,
}) => {
  const token: string = IDWall_SDK_TOKEN

  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_anybank_itau_anybank_v2_creation_kyc_render`,
      })
    }
  }, [])

  const handleCreateUserProfile = async (
    sdktToken: string,
    registrationId: string
  ) => {
    await post(`${baseURLVerifyUsersLegalRepresentativesExternalProfile}`, {
      external_token: sdktToken.replace('sdkweb-', ''),
      registration_id: registrationId,
    })
  }

  const handleOnSDKComplete = (token: string) => {
    setIsLoadingAnyBank(true)
    handleCreateUserProfile(token, registrationId)
      .then((res) => {
        setStep({ ...initialSteps, itauTermsAndConditions: true })
        if (window.gtag) {
          window.gtag('event', `modal_anybank_itau_anybank_v2_create`, {
            modal_action: 'modal_anybank_creation_next_step',
            modal_name: `create_pix_anybank_itau_anybank_v2`,
            pspName: 'itau_anybank_v2',
            step: 'itauTermsAndConditions',
          })
        }
      })
      .catch(() => {
        setStep({ ...initialSteps, error: true })
      })
      .finally(() => setIsLoadingAnyBank(false))
  }

  const handleOnSDKError = (error: any) => {
    console.log(`Error: ${error}`)
    setStep({ ...initialSteps, error: true })
  }

  return (
    <>
      <IdWallSdkIframe
        accessToken={token}
        handleOnSDKComplete={handleOnSDKComplete}
        handleOnSDKError={handleOnSDKError}
      />
    </>
  )
}

export default AnyBankItauIdWallSdkRender
