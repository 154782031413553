import styled, { css } from 'styled-components'

import TextCmp from 'components/Text'

export const InfoBox = styled.div<{
  error: boolean
}>`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme2};

  ${({ error, theme }) =>
    error
      ? css`
          background-color: ${theme.colors.cancelledStatusBackground};
          border: 0;
          flex-direction: column;
          margin-bottom: 8px;
        `
      : css``}
`

export const Text = styled(TextCmp)``
