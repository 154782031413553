export interface GetSystemWalletVariables {
  first?: number
  after?: string
  last?: number
  before?: string
  uuid?: string[]
  walletName?: string[]
  active?: boolean
  timezone?: string
  withReconciliationSchedule?: boolean
  withBrand?: boolean
}
export interface GetSystemWalletDataItem {
  uuid?: string
  active?: boolean
  walletFriendlyName?: string
  walletName?: string
  nextReconciliationExcecution?: string
  icon?: string
  walletLogo?: string
}

export interface GetSystemWalletData {
  systemWallets: GetSystemWalletDataItem[]
}

export const GET_SYSTEM_WALLET = `query SystemWalletsQuery(
$first: Int, $after: String, $last: Int, $before: String, $uuid: [String!], $walletName: [String!],
$active: Boolean = true, $timezone: String = "-03:00", $withReconciliationSchedule: Boolean = false,
$withBrand: Boolean = false) {
  systemWallets(
    first: $first
    after: $after
    last: $last
    before: $before
    active: $active
    uuid: $uuid
    walletName: $walletName
  ) {
    uuid
    active
    walletFriendlyName
    walletName
    icon @include(if: $withBrand)
    logo: walletLogo  @include(if: $withBrand)
    nextReconciliationExcecution(asTimezone: $timezone) @include(if: $withReconciliationSchedule)
  }
}`;
