import React from 'react'

import * as s from './styles'
import { parseDMY } from 'helpers/date'

import { MessageBox, MessageBoxProps } from './MessageBox'
import { FileExtension } from '../FileApi'

interface FilesModalFormProps {
  requestFile?(): void
  availableExtensions?: FileExtension[]
  ordersCount?: number
  initialDate: string
  finalDate: string
  validInterval?: boolean
  maxIntervalInDays?: number
  format: FileExtension
  setFormat?(newFormat: FileExtension): void
}

interface FilesModalFormWarningsProps {
  level: MessageBoxProps["level"];
  title: string;
  content: string;
}

const FilesModalFormWarnings: Record<string, FilesModalFormWarningsProps> = {
  requesting: {
    level: 'info',
    title: 'Gerando Arquivo',
    content: 'Seu relatório será gerado em formato de excel e estará disponível na listagem abaixo em alguns segundos. Caso não seja possível exportar em um arquivo só, serão gerados outros arquivos com a informação restante do intervalo selecionado.',
  },
  invalidInterval: {
    level: 'error',
    title: 'Intervalo maior do que 31 dias.',
    content: 'Configure os filtros aplicados na página de Pagamentos para um intervalo de datas de até 31 dias e tente novamente.',
  },
  noOrders: {
    level: 'error',
    title: 'Nenhum pedido encontrado para os filtros.',
    content: 'Configure os filtros aplicados na página de Pagamentos.',
  }
}

const FilesModalForm: React.FC<FilesModalFormProps> = ({
  requestFile, ordersCount, initialDate, finalDate, validInterval: validIntervalProp, maxIntervalInDays: maxPeriodInDays = 31,
  format, setFormat, availableExtensions = ['csv', 'xlsx']
}) => {
  const [requesting, setRequesting] = React.useState<Boolean>(false)

  const handleButtonClick = React.useCallback(() => {
    setRequesting(true)
    requestFile && requestFile()
  }, [requestFile])

  const isValidInterval = React.useMemo(function () {
    if (validIntervalProp !== undefined) {
      return validIntervalProp
    }
    const initialDateParsed = new Date(parseDMY(initialDate))
    const finalDateParsed = new Date(parseDMY(finalDate))
    const differenceInTime =
      finalDateParsed.getTime() - initialDateParsed.getTime()
    const differenceInDays = differenceInTime / (1000 * 3600 * 24)
    return !(differenceInDays > maxPeriodInDays)
  }, [validIntervalProp, initialDate, finalDate])

  const showButton = React.useMemo(() => {
    return ((!requesting) && ordersCount > 0 && isValidInterval)
  }, [ordersCount, requesting, isValidInterval])

  const warningContent = React.useMemo(() => {
    if (showButton) { return }
    if (requesting) { return FilesModalFormWarnings["requesting"] }
    if (!isValidInterval) { return FilesModalFormWarnings["invalidInterval"] }
    if (ordersCount < 1) { return FilesModalFormWarnings["noOrders"] }
  }, [showButton, ordersCount, requesting, isValidInterval])

  return (<s.FilesTableWrapper>
    <s.SectionHeader>
      <s.Icon name='addpaper' fill='gray1' />
      <s.Text type='paragraph' bold color='gray1'>
        Solicitar Arquivo
      </s.Text>
    </s.SectionHeader>
    <s.SectionContentWrapper>
      <s.Text>A geração do arquivo é feita com base nos <u>filtros aplicados na página de Pagamentos</u>. Com intervalo máximo de <u>31 dias</u>.</s.Text>
      <s.IntervalTextWrapper>
        <s.Text bold color='gray1'>Intervalo selecionado: </s.Text>
        <s.IntervalText error={!isValidInterval}>{initialDate} até {finalDate}</s.IntervalText>
      </s.IntervalTextWrapper>
      {showButton ? <s.ButtonsWrapper>
        <s.RadioBoxContainer>
          {
            availableExtensions.map((v: FileExtension) =>
              <s.RadioBox
                label={v}
                value={v}
                id={v}
                name={v}
                checked={format == v}
                handleChange={(e) => setFormat(v)}
              />
            )
          }
          </s.RadioBoxContainer>
          <s.RequestButton color='primary' onClick={handleButtonClick} data-testid='request-button'>
            <s.RequestButtonIcon name='file' fill='white' />
            <s.Text bold color='white'>
              Gerar Arquivo
            </s.Text>
          </s.RequestButton>
        </s.ButtonsWrapper> :
          (warningContent ?
        <MessageBox level={warningContent.level} marginBottom='1rem' title={warningContent.title}>
          {warningContent.content}
            </MessageBox> :
            <></>)
      }
    </s.SectionContentWrapper>
  </s.FilesTableWrapper >
  )
}

export default FilesModalForm
