import React from 'react'

const useAbortController = () => {
  const abortController = React.useMemo(() => new AbortController(), [])

  React.useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return abortController
}

export default useAbortController
