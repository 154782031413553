import { showToast } from 'components/Toast'
import React from 'react'
import { bffv2URLGetWalletSettingConfig, get } from 'services/api'

export interface BanksInstituitions {
  code: string
  name: string
}

export interface Store {
  active: boolean
  cnpj_cpf: string
  id: string
  name: string
  destination_account_id?: string
}

interface Field {
  key: string
  friendlyName: string
  fieldHelper?: string
  valueType: string
  inputType: string
  value?: string
  options?:
    | Array<string>
    | Array<BanksInstituitions>
    | Array<Store>
    | Array<{ selectable: string; visible: string }>
  placeholder?: string
  validators?: string[]
  maxLength?: number
  info?: string
}

interface SectionHelperContent {
  text: string
  url?: string
}

interface Section {
  key: string
  friendlyName: string
  sectionHelper?: SectionHelperContent

  icon?: string
  toggled?: boolean
  toggleButton?: any
  fields: Field[]
}

export interface ModalConfig {
  helper_link?: string
  sections: Section[]
}

interface IUseGetWalletSettingsModalConfig {
  modalConfig: ModalConfig
  setModalConfig: React.Dispatch<React.SetStateAction<any>>
  isLoading: boolean
  hasError: boolean
  hasSuccess: boolean
  getModalConfig: (pspProvider, sections) => void
}

const useGetWalletSettingsModalConfig =
  (): IUseGetWalletSettingsModalConfig => {
    const [modalConfig, setModalConfig] = React.useState<ModalConfig>({
      sections: [],
    })
    const [isLoading, setIsLoading] = React.useState(false)
    const [hasError, setHasError] = React.useState(false)
    const [hasSuccess, setHasSuccess] = React.useState(false)

    const getModalConfig = async (pspProvider: string, sections: string[]) => {
      setIsLoading(true)
      const params = new URLSearchParams()
      sections.map((section) => {
        params.append('section', section)
      })
      await get(`${bffv2URLGetWalletSettingConfig}/${pspProvider}`, {
        params: params,
      })
        .then((response) => {
          setHasError(false)
          let modalConfig = {
            ...response.data,
            sections: response.data.sections.filter((section) => section),
          }
          setModalConfig(modalConfig)
          setHasSuccess(true)
        })
        .catch((e) => {
          showToast({
            type: 'error',
            message: JSON.parse(e.request?.response).message,
          })
          setHasError(true)
          setModalConfig({ sections: [] })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    return {
      modalConfig,
      setModalConfig,
      hasError,
      isLoading,
      getModalConfig,
      hasSuccess,
    }
  }

export default useGetWalletSettingsModalConfig
