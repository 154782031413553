import styled, { css, keyframes } from 'styled-components'
import { Container as ContainerCmp } from 'styled-bootstrap-grid'

import { themeColors } from 'styles/theme'

import TextCmp from 'components/Text'
import LoadingCmp from 'components/Loading'
import TableCmp from 'components/Table'
import ToggleSwitchCmp from 'components/Switch'
import WalletCardCmp from 'components/Card/WalletDisplayCard'
import ButtonCmp from 'components/Button'
import IconCmp from 'components/IconExporter'
import PaginationCmp from 'components/Pagination'
import InputTextCmp from 'components/Inputs/Text'

import * as a from 'animations'

export const FooterPadding = styled.div`
  height: 80px;
`

export const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Icon = styled(IconCmp)``

export const SeeMore = styled.div`
  margin: auto;
  text-align: center;
`

export const Button = styled(ButtonCmp)``

export const ManageCertificatesButton = styled(ButtonCmp)`
  margin-left: auto;
`

export const EditButton = styled(ButtonCmp)``

export const EditButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`
export const SettingsButton = styled(ButtonCmp)``

export const EnableAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EnableAccountButton = styled(ButtonCmp)`
  box-shadow: 0px 1px 4px #b3b3b3;
  border-radius: 8px;
  padding: 8px 10px;

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`

export const CertificatesContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing(3)}px 0px;
`

export const ToggleSwitch = styled(ToggleSwitchCmp)``

export const Container = styled(ContainerCmp)`
  height: 100vh;
  max-width: 1500px;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  > table {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`
export const Table = styled(TableCmp)`
  * > th {
    :first-child {
      width: 35%;
    }
    :nth-child(2) {
      width: 15%;
    }
    :nth-child(3) {
      width: 10%;
    }
    :nth-child(4) {
      width: 15%;
    }
    :nth-child(5) {
      width: 5%;
    }
    :nth-child(6) {
      width: 15%;
    }
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const TableInAnalysis = styled(TableCmp)`
  * > tr {
    height: 60px;
    animation: ${fadeIn} 0.5s normal;
  }
  * > th {
    :first-child {
      width: 35%;
    }
    :nth-child(2) {
      width: 15%;
    }
    :nth-child(3) {
      width: 20%;
    }
    :nth-child(4) {
      width: 22%;
    }
  }
`

export const Loading = styled(LoadingCmp)`
  margin-left: 16px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const PixHeader = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  margin-bottom: 32px;

  & > * {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  align-items: left;
  gap: 16px;
  margin-top: 32px;
`

export const InputText = styled(InputTextCmp)``

export const FilterCardsContainer = styled.div`
  display: flex;
  gap: 32px;
`

interface IText {
  withGradient?: boolean
}

export const Text = styled(TextCmp)<IText>`
  ${(props) =>
    props.withGradient
      ? css`
          background: linear-gradient(
            ${({ theme }) => theme.colors.lightmaincolor4},
            ${({ theme }) => theme.colors.maincolor}
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : css``};
`

export const FooterWrapper = styled.div`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: absolute;
  margin: 10px 0px;
  padding: 4px 0px;
  width: 90%;
  bottom: 0px;
`
export const TextCardFooter = styled(TextCmp)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 149%;
  text-align: center;
  color: #ffffff;

  & > img {
    display: inline;
  }
`

export const TextTableTitle = styled(TextCmp)`
  margin-bottom: 16px;
`

export const IdWrapper = styled.div`
  width: 75%;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const IdContainer = styled.div`
  ${({ theme }) => theme.assets.orders.columns.uuidContainer};
`

export const Content = styled.div`
  display: flex;
  max-width: 1483px;
  flex-wrap: wrap;
  gap: 32px;

  & > div {
    max-width: 271px;
    max-height: 128px;
  }
`

const gradient = keyframes`
  0% {background-position: 0%}
  100% {background-position: 100%}
`

interface WalletCard {
  disabled?: boolean
}

export const TBanksCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 22px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`
export const TBanksCardCashout = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #bc5def 0%, #5e217f 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 32px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const OriginalCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #3cdd6f 0%, #18ad47 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 22px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const ItauCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px #a3bacc;

  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #f98f53 0%, #e55f13 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 12px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const RedeCard = styled.div<WalletCard>`
  position: relative;
  height: 128px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.gray3
      : css`
        linear-gradient(180deg, #595959 0%, #282828 100%);
  `};
  background-size: 200% 100%;
  ${({ disabled }) =>
    disabled
      ? css``
      : css`
          animation: ${gradient} 16s linear infinite;
        `}
  animation-direction: alternate;

  & > img {
    position: absolute;
    top: 0;
    margin: 8px 0px;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) =>
      disabled
        ? css``
        : css`
            animation: ${gradient} 16s linear infinite,
              ${a.cardAnimationShipayIn} 0.5s infinite alternate;
          `}
  }
`

export const PixSimplesCard = styled.div`
  height: 200px;
  width: 320px;
  border-radius: 8px;
  display: flex;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 4px 2px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    45deg,
    #f17c58,
    #e94584,
    #24aadb,
    #27dbb1,
    #ffdc18,
    #ff3706
  );
  background-size: 600% 100%;
  animation: ${gradient} 16s linear infinite;
  animation-direction: alternate;

  &:hover {
    cursor: pointer;
    animation: ${gradient} 16s linear infinite,
      ${a.cardAnimationShipayIn} 0.5s infinite alternate;
  }
`

interface IPixCard {
  color: keyof typeof themeColors
  pixType?: string
  disabled: boolean
}

export const PixCard = styled.div<IPixCard>`
  height: 200px;
  width: 320px;
  ${(props) =>
    props.pixType === 'empty'
      ? css`
          box-shadow: inset 0px 1px 4px ${props.theme.colors.graytheme4};
        `
      : css`
          box-shadow: 0px 1px 4px ${props.theme.colors.graytheme4};
        `};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors[props.color]};
  ${({ pixType }) => a.handleWalletCardAnimation(pixType)};

  & > img {
    animation-name: ${a.iconAnimationDown};
    animation-duration: 0.5s;
  }

  & > p {
    animation-name: ${a.titleAnimationOut};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    position: relative;
    line-height: 0;
  }

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    & > img {
      animation-name: ${a.iconAnimationUp};
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }

    & > p {
      animation-name: ${a.titleAnimationIn};
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
    }
  }
`

export const WalletCard = styled(WalletCardCmp)``

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Pagination = styled(PaginationCmp)``

export const PaginationWrapper = styled.div`
  width: 100%;
`
