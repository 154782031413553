import React from 'react'
import { v4 as uuid } from 'uuid'
import { baseURL } from 'services/apiConfig'
import { get, post } from 'services/api'
import { Document, Page } from 'react-pdf'

import { pdfjs } from 'react-pdf'
import { initialSteps, InitialSteps } from '..'

import * as s from '../../styles'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

interface IProps {
  pspProvider: string
  registrationId: string
  callSignContractState: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ]
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  setStep: (step: InitialSteps) => void
  setAllowedToGoToNextStep: React.Dispatch<React.SetStateAction<boolean>>
}

const AnybankItauTermsAndConditions: React.FC<IProps> = ({
  pspProvider,
  registrationId,
  callSignContractState,
  setStep,
  setIsLoading,
  setAllowedToGoToNextStep,
}) => {
  const [checkboxesStatus, setCheckboxesStatus] = React.useState([false, false])
  const [file, setFile] = React.useState(null)
  const [numPages, setNumPages] = React.useState(null)
  const [callSignContract, setCallSignContract] = callSignContractState
  const [isContractLoading, setIsContractLoading] = React.useState(false)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages)
  }

  const getContract = async () => {
    setIsContractLoading(true)
    await get(`/v1/contracts/system-wallets`, {
      params: { system_wallet_name: pspProvider, contract_type: 'anybank' },
    })
      .then((res) => {
        const byteCharacters = atob(res.data.contract)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'application/octet-stream' })
        const url = URL.createObjectURL(blob)
        setFile(url)
      })
      .catch((e) => {})
      .finally(() => setIsContractLoading(false))
  }

  React.useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: `modal_anybank_itau_anybank_v2_creation_terms_and_conditions`,
      })
    }
    getContract()
  }, [])

  React.useEffect(() => {
    if (callSignContract) {
      signContract()
      setCallSignContract(false)
    }
  }, [callSignContract])

  React.useEffect(() => {
    setAllowedToGoToNextStep(
      checkboxesStatus.every((checkbox) => checkbox === true)
    )
  }, [checkboxesStatus])

  const signContract = async () => {
    setIsLoading(true)
    await post(`${baseURL}/contract/sign`, {
      type: 'anybank',
      registration_id: registrationId,
      psp_provider: 'itau_anybank_v2',
    })
      .then((res) => {
        setStep({ ...initialSteps, itauSuccess: true })
      })
      .catch((e) => {
        if (e.request.status === 422) {
          setStep({ ...initialSteps, errorOnProofOfLife: true })
        } else {
          setStep({ ...initialSteps, error: true })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <s.AnybankItauSuccessWrapper data-testid="content-on-terms-of-acceptance">
      <s.Text margin="32px 0 0 0" type="heading4" bold color="graytheme6">
        Termos e condições de uso
      </s.Text>
      <s.DocumentContainer>
        {isContractLoading ? (
          <s.Loading type="spinner" />
        ) : (
          <Document
            loading={<s.Loading type="spinner" />}
            file={file}
            noData={
              <s.Text>
                Houve um problema em carregar o PDF, acesse o arquivo no link
                abaixo
              </s.Text>
            }
            error={
              <s.Text>
                Houve um problema em carregar o PDF, acesse o arquivo no link
                abaixo
              </s.Text>
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                width={500}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        )}
      </s.DocumentContainer>
      <s.CheckBoxContainer>
        <s.CheckboxWrapper>
          <s.Checkbox
            id={uuid()}
            checked={checkboxesStatus[0]}
            onChange={(e) => {
              setCheckboxesStatus([!checkboxesStatus[0], checkboxesStatus[1]])
            }}
          />
          <s.Text>
            Declaro estar ciente dos{' '}
            <s.ExternalLink
              href="https://www.itau.com.br/media/dam/m/38679805344a5c6b/original/itau_empresas_contrato_qrcodelaranja.pdf"
              target="_blank"
            >
              Termos e condições de uso ↗
            </s.ExternalLink>{' '}
            do Itaú
          </s.Text>
        </s.CheckboxWrapper>
        <s.CheckboxWrapper>
          <s.Checkbox
            id={uuid()}
            checked={checkboxesStatus[1]}
            onChange={(e) => {
              setCheckboxesStatus([checkboxesStatus[0], !checkboxesStatus[1]])
            }}
          />
          <s.Text>
            Declaro que todos os sócios estão cientes e concordam com minha
            decisão.
          </s.Text>
        </s.CheckboxWrapper>
      </s.CheckBoxContainer>
    </s.AnybankItauSuccessWrapper>
  )
}

export default AnybankItauTermsAndConditions
