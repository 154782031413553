import React from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import useStore from 'hooks/useStore'
import useStorePos from 'hooks/useStorePos'
import useWalletsSettings from 'hooks/useWalletsSettings'
import useStoreWallets from 'hooks/useStoreWallets'
import useGetProfile from 'hooks/useGetProfile'
import useDebounce from 'hooks/useDebounce'

import { applyCpfCnpjMask, applyCpfCnpjWithXXMask } from 'helpers/masks'

import { baseURLStorePosTypes, get } from 'services/api'

import Toast, { showToast } from 'components/Toast'

import ImpersonateWalletModal from './ImpersonateWalletModal'
import CreateStorePosModal from './CreateStorePosModal'

import dict from 'components/IconExporter'

import * as s from './styles'
import { themeColors } from 'styles/theme'

const StoreDetail: React.FC = () => {
  const { store, isLoading, hasError, loadStore } = useStore()
  const {
    walletSettings,
    isLoading: isLoadingWalletSettings,
    hasError: hasErrorWalletSettings,
    loadWalletsSettings,
  } = useWalletsSettings()
  const {
    storePos,
    storePosIsLoading,
    storePosHasError,
    loadStorePos,
    storePosPerStoreId,
    storePosPerStoreIdHasError,
    storePosPerStoreIdIsLoading,
    loadStorePosPerStoreId,
    postStorePosIsLoading,
    postStorePosHasError,
    postStorePos,
  } = useStorePos()
  const {
    storeWallets,
    isLoading: storesWalletsIsLoading,
    hasError: storesWalletsHasError,
    deleteIsLoading,
    deleteHasError,
    loadStoreWallets,
    deleteStoreWallet,
    patchHasError,
    patchIsLoading,
    patchStoreWallet,
  } = useStoreWallets()
  const {
    profile,
    isLoading: isProfileLoading,
    hasError: hasProfileError,
    loadProfile,
  } = useGetProfile()

  const { id }: { id: string } = useParams()
  const [storePosTypes, setStorePosTypes] = React.useState<
    { id: number; text: string }[]
  >([])
  const [storePosTypesOptionsEditModal, setStorePosTypesOptionsEditModal] =
    React.useState([])
  const [displayStorePosPerStoreId, setDisplayStorePosPerStoreId] =
    React.useState([])
  const [
    selectedStorePosPerStoreIdStatusModal,
    setSelectedStorePosPerStoreIdStatusModal,
  ] = React.useState<{
    id: string
    name: string
    description: string
    active: boolean
  }>({
    id: '',
    name: '',
    description: '',
    active: false,
  })
  const [
    selectedStorePosPerStoreIdEditModal,
    setSelectedStorePosPerStoreIdEditModal,
  ] = React.useState<{
    active: boolean
    category: string
    description: string
    id: string
    name: string
    store_id: string
  }>({
    id: '',
    name: '',
    description: 'update',
    active: false,
    category: '',
    store_id: '',
  })
  const [displayStoreWallets, setDisplayStoreWallets] = React.useState([])
  const [offset, setOffset] = React.useState('0')
  const [offsetWalletList, setOffsetWalletList] = React.useState('0')
  const [searchText, setSearchText] = React.useState('')
  const [displaySearchText, setDisplaySearchText] = React.useState('')
  const debouncedSearchText = useDebounce(setSearchText, 1000)
  const [isImpersonateWalletModalOpen, setIsImpersonateWalletModalOpen] =
    React.useState(false)
  const [isCreateStorePosModalOpen, setIsCreateStorePosModalOpen] =
    React.useState(false)

  const closeStorePosDesimpersonateModal = () => {
    setDisplayStorePosPerStoreId(
      displayStorePosPerStoreId.map((displayStore) =>
        displayStore.id === selectedStorePosPerStoreIdStatusModal.id
          ? {
              ...displayStore,
              active: true,
            }
          : displayStore
      )
    )
    setSelectedStorePosPerStoreIdStatusModal({
      id: '',
      name: '',
      description: '',
      active: false,
    })
  }

  const closeStorePosEditModal = () => {
    setSelectedStorePosPerStoreIdEditModal({
      id: '',
      name: '',
      description: '',
      active: false,
      category: '',
      store_id: '',
    })
  }

  const getStorePosTypes = async () => {
    await get(`${baseURLStorePosTypes}`)
      .then((response) => {
        setStorePosTypes(
          response.data.map((storePosType, index) => {
            return { id: index, text: storePosType }
          })
        )
      })
      .catch(() => {
        showToast({ type: 'error', message: 'Falha na API' })
      })
  }

  const copyToClipboard = async (storePosSelected) => {
    if (storePosSelected.client_id) {
      navigator.clipboard.writeText(storePosSelected.client_id)
      showToast({ type: 'success', message: 'ID Copiado!' })
    } else {
      await loadStorePos(storePosSelected.id, true).then(() => {
        showToast({ type: 'success', message: 'ID Copiado!' })
      })
    }
  }

  React.useEffect(() => {
    loadStore(id)
    loadWalletsSettings({ limit: '500', offset: '0' })
    getStorePosTypes()
    loadProfile()
  }, [])

  React.useEffect(() => {
    loadStorePosPerStoreId(id, {
      limit: 10,
      offset: offset,
      column_name: 'store_pos_name',
      filterby: searchText,
    })
  }, [offset, searchText])

  React.useEffect(() => {
    loadStoreWallets(id, {
      limit: 10,
      offset: offsetWalletList,
    })
  }, [offsetWalletList])

  React.useEffect(() => {
    let storePosPerStoreIdWithoutClientId = storePosPerStoreId.data.map(
      (storePos) => {
        return {
          ...storePos,
          isVisible: false,
          isLoading: false,
          client_id: '',
        }
      }
    )
    setDisplayStorePosPerStoreId([...storePosPerStoreIdWithoutClientId])
  }, [storePosPerStoreId])

  React.useEffect(() => {
    if (storePos.client_id) {
      let storePosPerStoreIdWithClientId = displayStorePosPerStoreId
      let index = displayStorePosPerStoreId.findIndex(
        (store) => store.id === storePos.id
      )
      if (storePosPerStoreIdWithClientId[index] !== undefined) {
        storePosPerStoreIdWithClientId[index].client_id = storePos.client_id
        storePosPerStoreIdWithClientId[index].isLoading = false
        setDisplayStorePosPerStoreId([...storePosPerStoreIdWithClientId])
      }
    }
  }, [storePos])

  React.useEffect(() => {
    if (storePosTypes.length > 0) {
      setStorePosTypesOptionsEditModal(
        storePosTypes.map((storePosType) => {
          return {
            ...storePosType,
            onClick: () => {
              setSelectedStorePosPerStoreIdEditModal({
                ...selectedStorePosPerStoreIdEditModal,
                category: storePosType.text,
              })
            },
          }
        })
      )
    }
  }, [storePosTypes, selectedStorePosPerStoreIdEditModal])

  React.useEffect(() => {
    setDisplayStoreWallets(
      storeWallets.data
        .map((wallet) => {
          let walletName = wallet.psp_provider || wallet.wallet_name
          if (walletName === 'itau_anybank_v2') {
            walletName = 'QR Code Multibancos'
          }
          return {
            ...wallet,
            wallet_name: walletName,
            isPix: !!wallet.psp_provider,
          }
        })
        .sort((a, b) => {
          if (a.wallet_setting_name > b.wallet_setting_name) {
            return 1
          }
          if (a.wallet_setting_name < b.wallet_setting_name) {
            return -1
          }
          return 0
        })
    )
  }, [storeWallets, walletSettings])

  const removeWalletButtonRender = ({ children, index }) => {
    const selectedStoreWallet = displayStoreWallets[index] || null
    return (
      <s.Button
        onClick={() => {
          deleteStoreWallet(
            selectedStoreWallet.store_id,
            selectedStoreWallet.wallet_setting_id
          ).then(() => loadStoreWallets(selectedStoreWallet.store_id))
        }}
        color="graytheme4"
      >
        <s.Text color="white">Desassociar</s.Text>
      </s.Button>
    )
  }

  const editStorePosButtonRender = ({ children, index }) => {
    const selectedStorePos = displayStorePosPerStoreId[index] || null
    return (
      <s.Button
        onClick={() => {
          setSelectedStorePosPerStoreIdEditModal({
            active: selectedStorePos.active,
            category: selectedStorePos.category.toLowerCase().replace(/-/g, ''),
            description: 'update',
            id: selectedStorePos.id,
            name: selectedStorePos.name,
            store_id: id,
          })
        }}
        color="graytheme4"
      >
        <s.Text color="white">Editar</s.Text>
        <s.Icon name="pencil" fill="white" />
      </s.Button>
    )
  }

  const renderStore = () => {
    if (isLoading) {
      return (
        <>
          <s.Loading
            type="spinner"
            data-testid="loading-spinner3"
            color="maincolor"
          />
        </>
      )
    }
    if (store) {
      return (
        <>
          <s.Text bold color="maincolor" type="heading3">
            {store.name}
          </s.Text>
          <s.StoreHeaderSubtitle>
            <s.Text color="maincolor" type="headline">
              {applyCpfCnpjWithXXMask(store.cnpj_cpf)}
            </s.Text>
          </s.StoreHeaderSubtitle>
          <s.StoreAddress>
            <s.Icon name="pinoutline" width={24} height={24} />
            <s.Text color="graytheme6" type="headline">
              {`${store.street_name}${
                store.street_number ? ', '.concat(store.street_number) : ''
              }${
                store.reference?.length > 2 ? ', '.concat(store.reference) : ''
              }${store.neighborhood ? ', '.concat(store.neighborhood) : ''}${
                store.city_name ? ', '.concat(store.city_name) : ''
              }${store.state_name ? ' — '.concat(store.state_name) : ''}${
                store.zip_code ? ', '.concat(store.zip_code) : ''
              }`}
            </s.Text>
          </s.StoreAddress>
        </>
      )
    }
  }

  const defaultPixRender = ({ children, index }) => {
    const selectedStoreWallet = displayStoreWallets[index] || null
    if (selectedStoreWallet?.isPix) {
      return (
        <s.ToggleSwitch
          handleChange={() => {
            if (selectedStoreWallet.is_default) {
              showToast({
                type: 'error',
                message:
                  'Não é possível remover a associação de uma conta PIX Padrão, selecione outra conta PIX para ser a conta PIX Padrão e tente novamente',
              })
              setDisplayStoreWallets([...displayStoreWallets])
            } else {
              patchStoreWallet(
                selectedStoreWallet.store_id,
                selectedStoreWallet.wallet_setting_id
              ).then(() => loadStoreWallets(selectedStoreWallet.store_id))
            }
          }}
          data={{ children: children, index: uuid() }}
        />
      )
    } else {
      return null
    }
  }

  const clientIdRender = ({ children, index }) => {
    const singleStorePos = displayStorePosPerStoreId[index]
    return (
      <s.CliendIdContainer>
        {storePosIsLoading && singleStorePos.isLoading ? (
          <>
            <s.ButtonText color="graytheme6">Carregando...</s.ButtonText>
            <s.Loading type="spinner" color="maincolor" />
          </>
        ) : (
          <s.ButtonText truncate color="graytheme6">
            {singleStorePos.isVisible
              ? singleStorePos.client_id
              : '*****************'}
          </s.ButtonText>
        )}
        <s.Icon
          onClick={() => {
            if (singleStorePos.isVisible) {
              setDisplayStorePosPerStoreId(
                displayStorePosPerStoreId.map((displayStore) =>
                  displayStore.id === singleStorePos.id
                    ? { ...displayStore, isVisible: false }
                    : displayStore
                )
              )
            } else if (!singleStorePos.isVisible && singleStorePos.client_id) {
              setDisplayStorePosPerStoreId(
                displayStorePosPerStoreId.map((displayStore) =>
                  displayStore.id === singleStorePos.id
                    ? { ...displayStore, isVisible: true }
                    : displayStore
                )
              )
            } else if (!singleStorePos.isVisible && !singleStorePos.client_id) {
              loadStorePos(singleStorePos.id)
              setDisplayStorePosPerStoreId(
                displayStorePosPerStoreId.map((displayStore) =>
                  displayStore.id === singleStorePos.id
                    ? { ...displayStore, isVisible: true, isLoading: true }
                    : displayStore
                )
              )
            }
          }}
          width={24}
          height={24}
          fill="maincolor"
          name={singleStorePos.isVisible ? 'eyeoff' : 'eyeon'}
        />
        <s.Icon
          onClick={() => {
            copyToClipboard(singleStorePos)
          }}
          width={24}
          height={24}
          fill="maincolor"
          name="clipboard"
        />
      </s.CliendIdContainer>
    )
  }

  const walletRender = ({ children, index }) => {
    const selectedStoreWallet = displayStoreWallets[index] || null
    return (
      <s.WalletWrapper>
        <s.Icon
          name={
            `${selectedStoreWallet.isPix ? `pix` : ''}${
              selectedStoreWallet.wallet_name
            }` as keyof typeof dict
          }
          width={36}
          height={36}
          fill="maincolor"
        />
        <s.Text
          margin="0px 0px 0px 8px"
          bold
          color={selectedStoreWallet.wallet_name as keyof typeof themeColors}
        >
          {children}
        </s.Text>
      </s.WalletWrapper>
    )
  }

  const storePosNameRender = ({ children }) => {
    return (
      <s.WalletWrapper>
        <s.Icon name="register" width={20} height={20} fill="maincolor" />
        <s.Text margin="0px 0px 0px 8px" bold>
          {children}
        </s.Text>
      </s.WalletWrapper>
    )
  }

  const statusRender = ({ children, index }) => {
    const singleStorePos = displayStorePosPerStoreId[index]
    return (
      <s.ToggleSwitch
        handleChange={() => {
          const { id, name, description, active } = singleStorePos
          if (active) {
            setSelectedStorePosPerStoreIdStatusModal({
              id,
              name,
              description,
              active,
            })
          } else {
            {
              postStorePos(singleStorePos.id, {
                active: true,
                description: 'Caixa Reativado',
                name,
              }).then((statusCode) => {
                showToast({
                  type: statusCode !== 200 ? 'error' : 'success',
                  message:
                    statusCode !== 200
                      ? 'Falha na API, tente novamente'
                      : 'Caixa ativado com sucesso',
                })
                setDisplayStorePosPerStoreId(
                  displayStorePosPerStoreId.map((displayStore) =>
                    displayStore.id === singleStorePos.id
                      ? {
                          ...displayStore,
                          active:
                            statusCode !== 200
                              ? displayStore.active
                              : !displayStore.active,
                        }
                      : displayStore
                  )
                )
              })
            }
          }
        }}
        data={{ children: singleStorePos.active, index: uuid() }}
      />
    )
  }

  const renderStoreWallets = React.useMemo(
    () => (
      <s.StoreWalletsTable
        isLoading={storesWalletsIsLoading || isLoadingWalletSettings}
        columns={[
          {
            headerName: 'Apelido da Conta',
            objectName: 'wallet_setting_name',
            AltRender: walletRender,
          },
          { headerName: 'Provedor', objectName: 'wallet_name' },
          {
            headerName: 'Pix Padrao',
            objectName: 'is_default',
            AltRender: defaultPixRender,
          },
          {
            headerName: ' ',
            objectName: '',
            AltRender: removeWalletButtonRender,
          },
        ]}
        data={displayStoreWallets}
        hasError={storesWalletsHasError}
      />
    ),
    [
      storesWalletsIsLoading,
      isLoadingWalletSettings,
      displayStoreWallets,
      storesWalletsHasError,
    ]
  )

  const renderStorePosPerStoreIdTable = React.useMemo(
    () => (
      <s.StorePosTable
        isLoading={storePosPerStoreIdIsLoading}
        columns={[
          {
            headerName: 'Apelido do Caixa',
            objectName: 'name',
            AltRender: storePosNameRender,
          },
          { headerName: 'Categoria', objectName: 'category' },
          {
            headerName: 'Client ID',
            objectName: 'client_id',
            AltRender: clientIdRender,
          },
          {
            headerName: 'Status',
            objectName: 'active',
            AltRender: statusRender,
          },
          {
            headerName: ' ',
            objectName: '',
            AltRender: editStorePosButtonRender,
          },
        ]}
        hasError={storePosPerStoreIdHasError}
        data={displayStorePosPerStoreId}
      />
    ),
    [
      storePosPerStoreIdIsLoading,
      storePosPerStoreIdHasError,
      storePosTypes,
      displayStorePosPerStoreId,
    ]
  )

  return (
    <s.Container>
      {/* Modal de desimpersonar caixa */}
      <s.Modal
        placeModalOnCenter
        noChildrenPadding
        handleClose={() => {
          closeStorePosDesimpersonateModal()
        }}
        isOpen={!!selectedStorePosPerStoreIdStatusModal.name}
      >
        <s.ModalContainer>
          <s.Text bold color="maincolor">
            Tem certeza que deseja desativar este caixa? Por quê?
          </s.Text>
          <textarea
            value={selectedStorePosPerStoreIdStatusModal.description}
            onChange={(e) =>
              setSelectedStorePosPerStoreIdStatusModal({
                ...selectedStorePosPerStoreIdStatusModal,
                description: e.target.value,
              })
            }
          ></textarea>
          <s.ButtonModalContainer>
            <s.Button
              outline
              color="white"
              onClick={() => closeStorePosDesimpersonateModal()}
            >
              Cancelar
            </s.Button>
            <s.Button
              onClick={() => {
                const { id, name, description } =
                  selectedStorePosPerStoreIdStatusModal
                postStorePos(id, {
                  active: false,
                  description,
                  name,
                }).then((statusCode) => {
                  showToast({
                    type: statusCode !== 200 ? 'error' : 'success',
                    message:
                      statusCode !== 200
                        ? 'Falha na API, tente novamente'
                        : 'Caixa desativado com sucesso',
                  })
                  setDisplayStorePosPerStoreId(
                    displayStorePosPerStoreId.map((displayStore) =>
                      displayStore.id === id
                        ? {
                            ...displayStore,
                            active:
                              statusCode !== 200
                                ? displayStore.active
                                : !displayStore.active,
                          }
                        : displayStore
                    )
                  )
                  setSelectedStorePosPerStoreIdStatusModal({
                    id: '',
                    name: '',
                    description: '',
                    active: false,
                  })
                })
              }}
            >
              Desativar caixa
            </s.Button>
          </s.ButtonModalContainer>
        </s.ModalContainer>
      </s.Modal>
      {/* Modal de editar caixa */}
      <s.Modal
        placeModalOnCenter
        noChildrenPadding
        modalSubtitle="Editar Caixa"
        handleClose={() => {
          closeStorePosEditModal()
        }}
        isOpen={!!selectedStorePosPerStoreIdEditModal.name}
      >
        <s.ModalContainer>
          <s.InputText
            label="Nome"
            maxLength={20}
            value={selectedStorePosPerStoreIdEditModal.name}
            onChange={(e) => {
              setSelectedStorePosPerStoreIdEditModal({
                ...selectedStorePosPerStoreIdEditModal,
                name: e.target.value,
              })
            }}
          />
          <s.Select
            label="Categoria"
            optionBoxSize={100}
            selected={{
              name: selectedStorePosPerStoreIdEditModal.category,
            }}
            options={storePosTypesOptionsEditModal}
          />

          <s.ButtonModalContainer>
            <s.Button
              outline
              color="white"
              onClick={() => closeStorePosEditModal()}
            >
              Cancelar
            </s.Button>
            <s.Button
              onClick={() => {
                postStorePos(
                  selectedStorePosPerStoreIdEditModal.id,
                  selectedStorePosPerStoreIdEditModal
                ).then((statusCode) => {
                  showToast({
                    type: statusCode !== 200 ? 'error' : 'success',
                    message:
                      statusCode !== 200
                        ? 'Falha na API, tente novamente'
                        : 'Caixa editado com sucesso',
                  })
                  closeStorePosEditModal()
                  loadStorePosPerStoreId(id, {
                    limit: 10,
                    offset: offset,
                    column_name: 'store_pos_name',
                    filterby: searchText,
                  })
                })
              }}
            >
              Salvar
            </s.Button>
          </s.ButtonModalContainer>
        </s.ModalContainer>
      </s.Modal>
      {/* Modal de associar conta */}
      <ImpersonateWalletModal
        isOpen={isImpersonateWalletModalOpen}
        handleClose={() => setIsImpersonateWalletModalOpen(false)}
        walletSettings={walletSettings.data}
        storeId={id}
        displayStoreWallets={displayStoreWallets}
      />
      {/* Modal de criar caixa */}
      <CreateStorePosModal
        isOpen={isCreateStorePosModalOpen}
        handleClose={() => setIsCreateStorePosModalOpen(false)}
        storeId={id}
        offset={offset}
        searchText={searchText}
        storePosTypes={storePosTypes}
      />
      <Toast />
      {renderStore()}
      <s.StoreWalletsHeader>
        <s.Text bold color="maincolor" type="heading3">
          Contas Digitais Associadas
        </s.Text>
        <s.Button
          outline
          color="white"
          onClick={() => setIsImpersonateWalletModalOpen(true)}
        >
          <s.Icon name="addcard" fill="maincolor" />
          Associar Conta
        </s.Button>
      </s.StoreWalletsHeader>
      {renderStoreWallets}

      {storeWallets.total > 0 && !storesWalletsHasError ? (
        <s.Pagination
          count={10}
          offset="0"
          total={storeWallets.total}
          handleChange={(offset) => {
            setOffsetWalletList(offset)
          }}
        />
      ) : null}

      <s.StorePosHeader>
        <s.Text bold color="maincolor" type="heading3">
          Caixas
        </s.Text>
        <s.Button
          outline
          color="white"
          onClick={() => setIsCreateStorePosModalOpen(true)}
        >
          <s.Icon name="addregister" fill="maincolor" />
          Novo Caixa
        </s.Button>
      </s.StorePosHeader>
      <s.InputSearchWrapper>
        <s.InputTextSearch
          prefix={<s.Icon name="search" fill="maincolor" />}
          value={displaySearchText}
          onChange={(e) => {
            setDisplaySearchText(e.target.value)
            debouncedSearchText(e.target.value)
          }}
        />
      </s.InputSearchWrapper>
      {renderStorePosPerStoreIdTable}
      {storePosPerStoreId.total > 0 && !storePosPerStoreIdHasError ? (
        <s.Pagination
          count={10}
          offset="0"
          total={storePosPerStoreId.total}
          handleChange={(offset) => {
            setOffset(offset)
          }}
        />
      ) : null}

      <s.Footer>
        <s.Text bold color="graytheme6">
          Códigos da Conta
        </s.Text>
        <s.Text color="graytheme6">
          Insira ambos os códigos abaixo ao cadastrar cada caixa em seu sistema.
          (Eles são sempre os mesmos em todos os Caixas)
        </s.Text>
        <s.KeyWrapper>
          <s.AccessKeyWrapper>
            <div>
              <s.Text bold color="maincolor">
                Access Key
              </s.Text>
              <s.Text color="maincolor">(Chave de Acesso)</s.Text>
            </div>
            <div>
              <s.InputText
                suffix={
                  isProfileLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : null
                }
                disabled
                width={300}
                value={profile.access_key}
              />
              {!isProfileLoading && !hasProfileError && profile.access_key ? (
                <s.CopyKeyButton
                  onClick={() => {
                    navigator.clipboard.writeText(profile.access_key)
                    showToast({ type: 'success', message: 'Chave Copiada!' })
                  }}
                >
                  Copiar
                  <s.Icon
                    width={24}
                    height={24}
                    fill="maincolor"
                    name="clipboard"
                  />
                </s.CopyKeyButton>
              ) : null}
            </div>
          </s.AccessKeyWrapper>
          <s.SecretKeyWrapper>
            <div>
              <s.Text bold color="maincolor">
                Secret Key
              </s.Text>
              <s.Text color="maincolor">(Chave Secreta)</s.Text>
            </div>
            <div>
              <s.InputText
                suffix={
                  isProfileLoading ? (
                    <s.Loading type="spinner" color="maincolor" />
                  ) : null
                }
                disabled
                width={300}
                value={profile.secret_key}
              />
              {!isProfileLoading && !hasProfileError && profile.secret_key ? (
                <s.CopyKeyButton
                  onClick={() => {
                    navigator.clipboard.writeText(profile.secret_key)
                    showToast({ type: 'success', message: 'Chave Copiada!' })
                  }}
                >
                  Copiar
                  <s.Icon
                    width={24}
                    height={24}
                    fill="maincolor"
                    name="clipboard"
                  />
                </s.CopyKeyButton>
              ) : null}
            </div>
          </s.SecretKeyWrapper>
        </s.KeyWrapper>
      </s.Footer>
    </s.Container>
  )
}

export default StoreDetail
