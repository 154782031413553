import React from 'react'

import paitausuccess from 'assets/images/pa-itau-success2.png'

import * as s from './styles'

const StoresAssociationError: React.FC<{
  pspProvider: string
}> = ({ pspProvider }) => {
  return (
    <s.Sections data-testid="content-on-success">
      <s.SucessWrapper>
        <s.Text color="graytheme6" bold type="heading4">
          Não foi possível associar às lojas
        </s.Text>
        <s.Text margin="32px 0 0 0" color="graytheme6" type="headline">
          Sua conta foi cadastrada com sucesso!
        </s.Text>
        <s.Text color="graytheme6" type="headline">
          Porém não foi possível associá-la às suas lojas no momento.
          {`\nAssocie sua conta Pix ${pspProvider} pela aba `}
          <u>Lojas</u> para poder receber pagamentos via Pix!
        </s.Text>
        <img src={paitausuccess} />
      </s.SucessWrapper>
    </s.Sections>
  )
}

export default StoresAssociationError
