import React from 'react'
import * as s from './styles'

interface IProps {}

export const AccordionBox: React.FC<IProps> = () => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <>
      <s.AccordionBox data-testid="accordion-box" isExpanded={isExpanded}>
        <s.AccordionTitle
          data-testid="accordion-box-title"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <s.Text color="maincolor" fontWeight={600}>
            Deseja alterar sua conta destino?
          </s.Text>
          <s.Icon
            name={isExpanded ? 'arrowup' : 'arrowdown'}
            fill="maincolor"
          />
        </s.AccordionTitle>
        <s.DisplayText data-testid="display-text" isExpanded={isExpanded}>
          <s.Line />
          <s.Text margin="0 0 8px 0">
            Para alterar sua conta destino entre em contato pelo e-mail:
          </s.Text>
          <s.Text bold margin="0 0 8px 0">
            parceiropixconexao@shipay.com.br
          </s.Text>
          <s.Text margin="0 0 8px 0">Seu contato precisa:</s.Text>
          <ul>
            <s.Text margin="0 0 8px 16px">
              <li>Ser enviado pelo e-mail administrador da conta do painel</li>
              <li>Informar qual a nova chave Pix da conta</li>
              <li>
                Anexar imagem tipo print que comprove que a nova chave Pix está
                vinculada a uma conta com o mesmo CNPJ
              </li>
            </s.Text>
          </ul>
          <s.Text>
            Não é possível alterar o CNPJ desta conta. Caso deseje utilizar uma
            conta destino de outro CNPJ é necessário fazer um novo cadastro de
            conta Pix neste painel.
          </s.Text>
        </s.DisplayText>
      </s.AccordionBox>
    </>
  )
}
