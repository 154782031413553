import React, { ErrorInfo } from 'react'

interface ErrorBoundaryProps extends React.PropsWithChildren {
  fallback: React.ReactNode
  errorCallback?: (error: Error, info: ErrorInfo) => void
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError?: Error }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    (this.props.errorCallback || console.error)(error, info)
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary