import React from 'react'

import { themeColors } from 'styles/theme'
import { getValueFromLocalStorage } from 'helpers/localStorage'
import { checkUserClaim } from 'helpers/claims'
import pixSantander from 'assets/pix/santander-icon.svg'
import pixMercadoPago from 'assets/pix/mercadopago-icon.svg'
import pixItau from 'assets/pix/itau-icon.svg'
import pixBradesco from 'assets/pix/bradesco-icon.svg'
import pixBB from 'assets/pix/bb-icon.svg'
import pixPagSeguro from 'assets/pix/pagseguro-icon.svg'
import pixPortoBank from 'assets/pix/portobank-icon.svg'
import pixSicredi from 'assets/pix/sicredi-icon.svg'
import pixBnb from 'assets/pix/bnb-icon.svg'
import pixBs2 from 'assets/pix/bs2-card-icon.svg'
import pixSicoob from 'assets/pix/sicoob-card-icon.svg'
import eCielo from 'assets/e-wallets/e-cielo-icon.svg'
import eMercadoPago from 'assets/e-wallets/e-mp-icon.svg'
import ePagBank from 'assets/e-wallets/e-pagbank-icon.svg'
import ePicPay from 'assets/e-wallets/e-picpay-icon.svg'
import eTecban from 'assets/e-wallets/e-tecban-icon.svg'
import eCoinPayments from 'assets/e-wallets/e-coinpayments-icon.svg'
import eFoxbit from 'assets/e-wallets/e-foxbit-icon.svg'
import * as s from './styles'
import { useTheme } from 'styled-components'
import { UserSessionInfo } from 'store/modules/auth/types'

interface IProps {
  disabled: boolean
  color: keyof typeof themeColors
  name: string
  type: string
  height?: number
  width?: number
  check?: boolean
  clickCallBack?: () => void
  children?: React.ReactNode | React.ReactNode[]
}

const WalletDisplayCard: React.FC<IProps> = ({
  disabled,
  color,
  name,
  check = false,
  type,
  height = 150,
  width = 185,
  children,
  clickCallBack,
}) => {
  const theme = useTheme()
  const renderPixCard = () => {
    const pixCardClaim = `panel_pages_pix@card_${name}`
    console.debug(`%c${pixCardClaim}`, 'background: #f00; color: white;')

    const currentUserSession: UserSessionInfo = JSON.parse(
      getValueFromLocalStorage('currentUserSession')
    )
    if (checkUserClaim(currentUserSession?.user_claims, pixCardClaim)) return

    switch (name) {
      case 'santander':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            data-testid={`walletcard-${type}-${name}`}
            name={name}
            type={type}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer>
              <img alt="imagem pix Santander" src={pixSantander} />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )
      case 'mercadopago':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            data-testid={`walletcard-${type}-${name}`}
            name={name}
            type={type}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer>
              <img alt="imagem pix MercadoPago" src={pixMercadoPago} />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )
      case 'itau':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            data-testid={`walletcard-${type}-${name}`}
            type={type}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer>
              <img src={pixItau} alt="imagem pix Itaú" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )
      case 'bradesco':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            data-testid={`walletcard-${type}-${name}`}
            type={type}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer>
              <img src={pixBradesco} alt="imagem pix Bradesco" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )
      case 'bb':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            height={height}
            data-testid={`walletcard-${type}-${name}`}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer>
              <img src={pixBB} alt="imagem pix Banco do Brasil" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )
      case 'pagseguro':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            height={height}
            data-testid={`walletcard-${type}-${name}`}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer>
              <img src={pixPagSeguro} alt="imagem pix PagSeguro" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )

      case 'portobank':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            height={height}
            data-testid={`walletcard-${type}-${name}`}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer>
              <img src={pixPortoBank} alt="imagem pix PortoBank" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )

      case 'sicredi':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            height={height}
            data-testid={`walletcard-${type}-${name}`}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer margin="4px 0px 0px 0px">
              <img src={pixSicredi} width={160} alt="imagem pix Sicredi" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )

      case 'bnb':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            height={height}
            data-testid={`walletcard-${type}-${name}`}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer margin="8px 0px 0px 0px">
              <img
                src={pixBnb}
                width={160}
                alt="imagem pix Banco do Nordeste"
              />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )

      case 'bs2':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            height={height}
            data-testid={`walletcard-${type}-${name}`}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer margin="8px 0px 0px 0px">
              <img src={pixBs2} width={100} alt="imagem pix Bs2" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )

      case 'sicoob':
        return (
          <s.PixWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            height={height}
            data-testid={`walletcard-${type}-${name}`}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <s.ImageContainer margin="8px 0px 0px 0px">
              <img src={pixSicoob} width={124} alt="imagem pix Bs2" />
            </s.ImageContainer>
            <s.FooterWrapper>
              <s.TextCardFooter>
                <s.Icon name="cashinarrowdown" fill="white" />
                cobrança
              </s.TextCardFooter>
            </s.FooterWrapper>
          </s.PixWalletCard>
        )
    }
  }

  const renderEWalletCard = () => {
    switch (name) {
      case 'ame':
      case 'ninetyninepay':
        return <></>
      case 'tecban':
        return (
          <s.EWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            data-testid={`walletcard-${type}-${name}`}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img alt="imagem carteira tecban" src={eTecban} />
          </s.EWalletCard>
        )
      case 'coinpayments':
        return theme.domain === 'ConexãoItaú' ? (
          <></>
        ) : (
          <s.EWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            data-testid={`walletcard-${type}-${name}`}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img
              width={120}
              height={100}
              alt="imagem carteira coinpayments"
              src={eCoinPayments}
            />
          </s.EWalletCard>
        )

      case 'foxbit':
        return theme.domain === 'ConexãoItaú' ? (
          <></>
        ) : (
          <s.EWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            data-testid={`walletcard-${type}-${name}`}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img
              width={120}
              height={100}
              alt="imagem carteira foxbit"
              src={eFoxbit}
            />
          </s.EWalletCard>
        )

      case 'mercadopago':
        return (
          <s.EWalletCard
            disabled={disabled}
            color={name}
            name={name}
            data-testid={`walletcard-${type}-${name}`}
            type={type}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img alt="imagem carteira mercadopago" src={eMercadoPago} />
          </s.EWalletCard>
        )
      case 'picpay':
        return (
          <s.EWalletCard
            disabled={disabled}
            color={name}
            name={name}
            data-testid={`walletcard-${type}-${name}`}
            type={type}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img src={ePicPay} alt="imagem carteira picpay" />
          </s.EWalletCard>
        )
      case 'pagseguro':
        return (
          <s.EWalletCard
            disabled={disabled}
            color={name}
            name={name}
            data-testid={`walletcard-${type}-${name}`}
            type={type}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img src={ePagBank} alt="imagem carteira pagbank" />
          </s.EWalletCard>
        )
      case 'cielo':
        return (
          <s.EWalletCard
            disabled={disabled}
            color={name}
            name={name}
            type={type}
            data-testid={`walletcard-${type}-${name}`}
            height={height}
            width={width}
            onClick={() => {
              if (clickCallBack && !disabled) clickCallBack()
            }}
          >
            {check ? (
              <s.Icon name="checkmarkcirclefilled" fill="white" />
            ) : null}
            <img src={eCielo} alt="imagem carteira cielo" />
          </s.EWalletCard>
        )
    }
  }

  return <>{type === 'pix' ? renderPixCard() : renderEWalletCard()}</>
}

export default React.memo(WalletDisplayCard)
