export const Types = {
  GET_REGISTRATION_STATUS: 'GET_REGISTRATION_STATUS',
  GET_REGISTRATION_STATUS_LOADING: 'GET_REGISTRATION_STATUS_LOADING',
  GET_REGISTRATION_STATUS_ERROR: 'GET_REGISTRATION_STATUS_ERROR',
}

export interface RegistrationStatusInterface {
  count: number
  offset: number
  total: number
  data: RegistrationStatus[]
}

interface RegistrationStatus {
  error_message: string
  id: number
  registration_id: string
  status: string
  store_cnpj_cpf: string
  store_id: number
  psp_provider: string
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: RegistrationStatusInterface
}

export interface RegistrationStatusState {
  registrationStatus: {
    count: number
    offset: number
    total: number
    data: RegistrationStatus[]
  }
  isLoading: boolean
  hasError: boolean
}
