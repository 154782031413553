import { useEffect } from 'react'

const useSdkIdWall = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://sdkweb-lib.idwall.co/index.js'
    script.async = true
    script.onload = () => {
      console.log('SDK IdWall script loaded')
    }
    script.onerror = () => {
      console.error('Failed to load the SDK IdWall script')
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
}

export default useSdkIdWall
